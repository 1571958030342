import {Editor} from "react-draft-wysiwyg";
import {EditorState, convertToRaw, ContentState} from 'draft-js'
import './react-draft-wysiwyg.css';
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import toolbar from "./toolbar";
import LineHeight from "./LineHeight";
import LetterSpacing from "./LetterSpacing";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const TextEditor = forwardRef((props, ref) =>{

    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const elementRef = useRef(null)

    useImperativeHandle(ref, () => ({getContent: () => {return draftToHtml(convertToRaw(editorState.getCurrentContent()))}}), [editorState]);

    useEffect(()=>{
        //const html = '<p>Hey this <strong>editor</strong> rocks 😀</p>'; //rops.html;
        const contentBlock = htmlToDraft(props.html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
        }
    },[])

    useEffect(()=>{
        const html = props.html;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
            // props.updateEditorState(draftToHtml(convertToRaw(editorState.getCurrentContent())))

        }
    },[props.html])

    useEffect(()=>{
        if(props.activeElement !== props.id){
            elementRef.current.children[0].children[0].style.display = 'none'
        }else{
            elementRef.current.children[0].children[0].style.display = 'flex'
        }
    },[props.activeElement])

    /*props.getContent = () =>{
        return draftToHtml(convertToRaw(editorState.getCurrentContent()))
    }*/

    return(
        <div id={props.id}
             ref={elementRef}
             style={{
                 width: '100%',
                 height: '100%',
                 boxShadow: props.activeElement === props.id ? '0px 0px 10px 7px rgba(0,0,0,0.1)' : '',
                 borderRadius: '.5rem',
                 backgroundColor: props.activeElement !== props.id ? 'whitesmoke' : 'white'
             }}
        >
            <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                toolbar={toolbar}
                localization={{locale: 'en'}}
                toolbarCustomButtons={[
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', minWidth: '50%'}}>
                        <LineHeight lineHeight={props.lineHeight}/>
                        <LetterSpacing letterSpacing={props.letterSpacing}/>
                    </div>
                ]}
                onEditorStateChange={(state)=>{
                    setEditorState(state)
                    console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
                    //props.updateEditorState(draftToHtml(convertToRaw(editorState.getCurrentContent())))
                }}
            />
        </div>
    )
})

export default TextEditor;