import React, {useContext, useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import LoginMobile from "./mobile/LoginMobile";
import LoginDesktop from './desktop/LoginDesktop'
import LoggedinDesktop from "./desktop/LoggedinDesktop";
import {Context} from "../../common/context";
import {auth, Auth} from "../../firebase";
const Login =  () =>{

    const context = useContext(Context)
    const [pageText, setPageText] = useState('')
    const [user, setUser] = useState(null)

    useEffect(()=>{
        if(context.payment){
            setPageText(context)
        }
    }, [context])

    useEffect(() =>{
        const unlisten = auth.onAuthStateChanged(
            authUser => {
                authUser
                    ? setUser(true)
                    : setUser(false);
            },
        );
        return () => {
            unlisten();
        }
    }, []);
    const loggin = async (email, password) =>{
        auth.setPersistence(Auth.Persistence.SESSION)
            .then(()=>{
                auth.signInWithEmailAndPassword(email, password)
                    .then((userCredential) => {
                        // Signed in
                        //alert('us')
                        console.log('inloggad', userCredential)
                        var user = userCredential.user;
                        setUser(true)
                        // ...
                    })
                    .catch((error) => {
                        //alert(error)
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        console.log(error)
                        setUser(false)
                    });
           })
            .catch((error)=>{
                console.log(error)
            })
    }

    return(
        <React.Fragment>
            {user || user === null? (
                (user === false ? (
                            <>
                                <LoggedinDesktop pageText={pageText}/>
                                <div style={{position: 'fixed', top: 0, left: 0, zIndex: 99, backgroundColor: 'rgba(0,0,0,0.5)', width: '100%',
                                    height: '100%', }}
                                >
                                    <div style={{position: 'absolute', top: 'calc(50% - 40px)', left: 'calc(50% - 40px)', right: '50%'}}>
                                        <div className={'loader'}/>
                                    </div>
                                </div>
                            </>
                ) : (
                    <LoggedinDesktop pageText={pageText}/>
                ))
            ):(
                isMobile ? (
                    <LoginMobile/>
                ):(
                    <LoginDesktop loggin={loggin}/>
                )
            )}
        </React.Fragment>
    )
}
export default Login;