import React, {useEffect} from "react";
import {gsap, Power2} from "gsap";
import phone from "../../../assets/images/icons/call.png";
import mail from "../../../assets/images/icons/email.png";
import address from '../../../assets/images/icons/home.png'
import parse from "html-react-parser";
import PageWrapper from "../../../common/PageWrapper";

const ContactDesktop = (props) =>{

    useEffect(()=>{
        gsap.to(
            '.line',
            {
                width: '40%',
                duration: 1,
                ease: Power2.easeInOut,
            }
        )
        gsap.to(
            '.lineText',
            {
                opacity: 1,
                duration: .5,
                ease: Power2.easeInOut
            }
        )
        gsap.to(
            ".textRef",
            {
                opacity: 1,
                duration: 2,
                ease: Power2.easeInOut
            }
        )

    },[])
    const sendMessage = (e) =>{
        e.preventDefault()

        let body = {
            name: document.getElementById('contactform_name').value,
            subject: document.getElementById('contactform_subject').value,
            email: document.getElementById('contactform_email').value,
            phone: document.getElementById('contactform_phone').value,
            message: document.getElementById('contactform_message').value
        }
        let button = document.getElementById('contactform_submit');
        button.disabled = true
        button.innerText = 'Skickar...'
        /*fetch('https://europe-west1-wetterstadensbil.cloudfunctions.net/appserver/update/mail', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then((data) => {
                alert('Meddelandet är nu skickat')
                button.innerText = 'Skicka'
                button.disabled = false;
                document.getElementById('contactform_message').value = '';
                document.getElementById('contactform_subject').value = ''
                document.getElementById('contactform_phone').value = '';
                document.getElementById('contactform_email').value = '';
                document.getElementById('contactform_name').value = ''
            })
            .catch(() => {
                alert('Testa att skicka igen något gick fel')
                button.innerText = 'Skicka'
                button.disabled = false;
            })*/
    }

    return(
        <PageWrapper titel={'Kontakt'}
                     lineHeight={1}
                     letterSpacing={4}
        >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        justifyContent: 'center',
                        //alignItems: 'center',
                        gap: '5rem',
                        marginTop: 40,
                        marginBottom: 40,
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexBasis: '25%',
                            gap: '4rem'
                        }}>
                            <div id={'contactId'}>
                                <div style={{
                                    color: 'var(--main-bg-color)',
                                    fontWeight: 500,
                                    fontSize: 20,
                                    lineHeight: '30px'
                                }}>
                                    Info
                                </div>
                                <div style={{
                                    color: '#919197',
                                    fontSize: 16,
                                    lineHeight: '26px',
                                    marginTop: 15
                                }}>
                                    {props.info.text}
                                </div>
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                color: '#919197',
                                fontSize: 15,
                                lineHeight: '26px',
                                marginTop: 15,
                                gap: '1rem'
                            }}>
                                <div style={{
                                    color: 'var(--main-bg-color)',
                                    fontWeight: 500,
                                    fontSize: 20,
                                    lineHeight: '30px'
                                }}>
                                    Kontakt
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', gap: '0.2rem'}}>
                                    <a href={'tel:+46' + props.contact.phone.replaceAll(/\s/g,'').replaceAll(/-/g,'').replace(/0/,'')}
                                       style={{
                                           textDecoration: 'inherit',
                                           color: 'inherit',
                                           display: 'flex',
                                           flexDirection: 'row',
                                           alignItems: "center"
                                       }}
                                    >
                                        <div>
                                            <img src={phone}
                                                 style={{height: 30, filter: 'brightness(.9)'}}
                                            />
                                        </div>
                                        <div style={{
                                            height: 30,
                                            marginLeft: 10,
                                            marginTop: -2,
                                            fontWeight: 600
                                        }}>
                                            {props.contact.phone}
                                        </div>
                                    </a>
                                    <a href={'mailto:' + props.contact.email}
                                       style={{
                                           textDecoration: 'inherit',
                                           color: 'inherit',
                                           display: 'flex',
                                           flexDirection: 'row',
                                           alignItems: 'center'
                                       }}
                                    >
                                        <div>
                                            <img src={mail}
                                                 style={{width: 30, filter: 'brightness(.9)'}}
                                            />
                                        </div>
                                        <div style={{
                                            height: 30,
                                            marginLeft: 10,
                                            marginTop: -2,
                                            fontWeight: 600
                                        }}>
                                            {props.contact.email}
                                        </div>
                                    </a>

                                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <div>
                                            <img src={address}
                                                 style={{width: 30, filter: 'brightness(.9)'}}
                                            />
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            textAlign: 'left'
                                        }}>
                                            <div style={{
                                                height: 30,
                                                marginLeft: 10,
                                                marginTop: -2,
                                                fontWeight: 600,
                                                textAlign: 'left'
                                            }}>
                                                {props.contact.address.road}
                                            </div>
                                            <div style={{
                                                height: 30,
                                                marginLeft: 10,
                                                marginTop: -2,
                                                fontWeight: 600,
                                                textAlign: 'left'
                                            }}>
                                                {props.contact.address.post}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: 'fit-content'
                            }}>
                                <div style={{
                                    color: 'rgb(0, 147, 201)',
                                    fontWeight: 500,
                                    fontSize: 20,
                                    lineHeight: '30px'
                                }}>
                                    Sociala medier
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: "center",
                                    width: '70%',
                                    marginTop: 20,
                                    gap: '1rem'
                                }}>
                                    <a href="https://www.instagram.com/wetterstadensbilab/"
                                       style={{cursor: 'pointer', marginTop: 5, filter: 'invert(.6)'}}
                                    >
                                        <img style={{cursor: 'pointer'}}
                                             src="https://assets.website-files.com/5a7d94ae2dbf1b0001dc27df/5a7f232638edac000186dc94_font-awesome_4-7-0_instagram_100_0_ffffff_none.png"
                                             width="40" alt=""/>
                                    </a>
                                    <a href="https://www.facebook.com/wetterstadensbilab/"
                                       className="social-icon w-inline-block"
                                       style={{filter: 'invert(.6)'}}
                                    >
                                        <img src="https://assets.website-files.com/5a7d94ae2dbf1b0001dc27df/5a7f230dee248c0001384b27_font-awesome_4-7-0_facebook_100_0_ffffff_none.png"
                                             width="40"
                                             alt=""
                                             style={{cursor:'pointer', marginTop: 5,}}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            //width: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexBasis: '56%',
                            //marginTop: 40
                        }}>
                            <div className="gmap_canvas"
                                 style={{
                                     overflow: 'hidden',
                                     height:'100%',
                                     width: '100%',
                                     borderRadius: 20,

                                 }}>
                                <iframe width="100%" height="100%" id="gmap_canvas"
                                        title={'googleMap'}
                                        src="https://maps.google.com/maps?q=mekanikv%C3%A4gen%206%20bankeryd&t=&z=11&ie=UTF8&iwloc=&output=embed"
                                        frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"/>
                                <a href="https://www.whatismyip-address.com/divi-discount/"/>
                                <a href="https://www.embedgooglemap.net"/>
                            </div>
                        </div>
                    </div>
        </PageWrapper>
    )
}
export default ContactDesktop;

/*
 <div style={{
                display: 'flex',
                flexDirection: 'row',
                minHeight: '50vh',
                width: '80%',
                justifyContent: 'center',
                maxWidth: 1500,
                position: 'relative'
            }}>
                <div style={{
                    position: 'relative',
                    top: 0,
                    right: 0,
                    width: '50%',
                    minHeight: '50vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>
                    <div style={{
                        display: 'flex',
                        width: 'auto',
                        height: '80%',
                        marginTop: 20,
                        marginLeft: 100,
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
 */


/*
 <div style={{minHeight: '50vh', maxWidth: 700, width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, left: '15%'}}>
                    <div style={{display: 'flex', height: '90%', flexDirection: 'column', justifyContent: 'space-around', width: 400, marginRight: 100}}>
                        <div style={{color: '#0056ff'}}>
                            <div style={{fontSize: 50, fontWeight: 600, fontFamily: 'Oswald'}}>
                                Medelande
                            </div>
                            <div style={{fontSize: 17, fontWeight: 400, fontFamily: 'Oswald', lineHeight: '28px', marginBottom: 10}}>
                                Hör gärna av dig
                            </div>
                        </div>
                        <form id={'wf-form-Contact-Form'}
                              name={'wf-form-Contact-Form'}
                              data-name={'Contact Form'}
                              method={'get'}
                              //onsubmit={'return false'}
                              //action={'javascript:;'}
                            onSubmit={(e)=>{
                                e.preventDefault()
                                sendMessage(e)
                                return false
                            }}
                        >
                            <input type={'text'}
                                   id={'contactform_name'}
                                   maxLength={256}
                                   placeholder={'Skriv ditt namn'}
                                   required
                                   name={'name'}
                                   style={{width: '100%', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 40, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                       padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                            />
                            <input type={'text'}
                                   id={'contactform_email'}
                                   maxLength={256}
                                   name={'email'}
                                   placeholder={'Skriv din email'}
                                   required
                                   style={{width: '100%', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 40, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                       padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                            />
                            <input type={'text'}
                                   id={'contactform_phone'}
                                   maxLength={256}
                                   name={'phone'}
                                   placeholder={'Skriv ditt telefonummer'}
                                   required
                                   style={{width: '100%', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 40, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                       padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                            />
                            <input type={'text'}
                                   id={'contactform_subject'}
                                   maxLength={256}
                                   name={'subject'}
                                   placeholder={'Skriv ditt ämne'}
                                   required
                                   style={{width: '100%', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 40, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                       padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                            />
                            <input type={'text'}
                                   id={'contactform_message'}
                                   maxLength={5000}
                                   name={'message'}
                                   placeholder={'Skriv ditt medelande'}
                                   required
                                   style={{width: '100%', marginBottom: 20, float: 'none', borderStyle: 'none', borderRadius: 10, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)', paddingTop: '20px', paddingBottom: '60px',
                                       paddingLeft: '12px', paddingRight: '12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                            />
                            <button style={{width: 426, textAlign: 'center', backgroundColor: '#0056ff', padding: '14px 24px', borderRadius: 3, boxShadow: '0 10px 50px -12px rgba(0, 0, 0, 0.3)', color: 'white',
                                fontWeight: 500, cursor: 'pointer', appearance: 'button', transition: 'all 300ms ease', border: 0, display: 'inline-block',}}
                                   type={'submit'}
                                   id={'contactform_submit'}
                                   value={'Skicka Meddelande'}
                            >
                                Skicka Meddelande
                            </button>
                        </form>
                    </div>
                </div>
 */