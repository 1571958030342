import React, {useEffect, useRef, useState} from "react";

const MiddleComponent = (props) =>{

    const secondBox = useRef([]);
    const [firstCar, setFirstCar] = useState('')
    const [secondCar, setSecondCar] = useState('')
    const [thirdCar, setThirdCar] = useState('')
    const [boxData, setBoxData] = useState([])

    useEffect(()=>{
        console.log('this', props.width, props)
        fetch('https://europe-west1-wetterstadensbil.cloudfunctions.net/appserver/update/top3')
            .then((res)=>{
                return res.json()
            })
            .then((data)=>{
                setBoxData(data.data)
                let arr = data.data[0]?.img.split('=')
                let img = arr ? arr[0] + '=original' : ''
                setFirstCar(img)
                arr = data.data[1]?.img.split('=')
                img = arr ? arr[0] + '=original' : ''
                setSecondCar(img)
                arr = data.data[2]?.img.split('=')
                img = arr ? arr[0] + '=original' : ''
                setThirdCar(img)
            })
            .catch((e)=>{
                console.log('e', e)
            })
    },[])

    let boxes = [
        {
            title: 'Title title',
            message: 'ihfisj oko oekf oewk ofk efokf oewf owekf okweopf kwmf eow ',
            //image: car3,
        },
        {
            title: 'Title title',
            message: 'ihfisj oko oekf oewk ofk efokf oewf owekf okweopf kwmf eow ',
            //image: car3,
        },
        {
            title: 'Title title',
            message: 'ihfisj oko oekf oewk ofk efokf oewf owekf okweopf kwmf eow ',
            //image: car3,
        }
    ]

    return(
        <div style={{/*minHeight: 1350,*/ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50, position: 'relative', color: '#212127'}}>
            {boxes.map((data, index)=>{
                let top = 0;
                if(secondBox.current[index]?.getBoundingClientRect().top){
                    top = secondBox.current[index].getBoundingClientRect().top
                }
                let pos = 0
                //if(window.scrollY > window.innerHeight){
                    pos = (window.scrollY - top)*.02
                //}
                if(boxData.length > 0){

                    if(props.width < 600){
                        return(
                            <div ref={el => secondBox.current[index] = el}
                                 key={index}
                                 style={{position: 'relative', height: 400, width: '100%', marginBottom: 50}}
                            >
                                <div style={{
                                    position: 'absolute', /*height: 200,*/ width: '80%', overflow: 'hidden', borderRadius: 30, right: 0,
                                    marginRight: '10%'
                                    //transform: 'translateX(-' + pos + 'px)',
                                    //transition: 'transform 0s ease'
                                }}>
                                    <img src={index === 0 ? firstCar : (index === 1 ? secondCar : thirdCar)}
                                         style={{width: '100%', height: 'inherit'}}
                                    />
                                </div>
                                <div style={{
                                    position: 'absolute',bottom: '0%', background: 'white', width: '75%', /*maxWidth: 290,*/ display: 'flex', flexDirection: 'column',
                                    height: 170, alignItems: 'start',/* paddingTop: 20,*/ paddingBottom: 20,/* paddingLeft: 25, paddingRight: 15,*/ borderRadius: 20,
                                    backgroundImage: 'linear-gradient(270deg, rgba(255, 255, 255, 0.11) 37%, #fff 68%, #fff)', left: 0, marginLeft: '12.5%'
                                    //transform: 'translateX(' + pos + 'px)',
                                    //transition: 'transform 0s ease'
                                }}>
                                    <div style={{color: '#0056ff', fontWeight: 500, fontSize: 20, lineHeight: '30px', marginTop: 15, marginLeft:25 ,marginRight: 15}}>
                                        {boxData[index].text[boxData[index].text.length - 2]}
                                    </div>
                                    <div style={{color: '#919197', fontSize: 15, lineHeight: '26px', marginTop: 10, width: '80%', marginLeft:25 ,marginRight: 15}}>
                                        {boxData[index].text[3]}
                                    </div>
                                    <div style={{color: 'rgba(0,86,255,0.8)', borderBottom: '1px solid #c7c7c7', padding: '0px 0px 3px', fontSize: 13, fontWeight: 500, marginTop: 15, marginLeft:25 ,marginRight: 15}}
                                         onClick={()=>{
                                             window.location.href=boxData[index].href
                                         }}
                                    >
                                        TILL ANNONS
                                    </div>
                                </div>

                            </div>
                        )
                    }

                    return (
                        <div ref={el => secondBox.current[index] = el}
                             key={index}
                             style={{position: 'relative', height: 380, width: '100%', marginBottom: 40}}
                        >
                            <div style={{
                                 position: 'absolute', /*height: 200,*/
                                width: '50%',
                                overflow: 'hidden',
                                borderRadius: 30, left: index !== 1 ? null : '5%',
                                right: index !== 1 ? '5%' : null,
                                transform: index === 1 ? 'translateX(' + pos + 'px)' : 'translateX(-' + pos + 'px)',
                                transition: 'transform 0s ease',
                                display: 'flex',
                                alignItems: 'center',
                                height: 'inherit'
                            }}>
                                <img src={index === 0 ? firstCar : (index === 1 ? secondCar : thirdCar)}
                                     style={{width: '100%'}}
                                />
                            </div>
                            <div style={{
                                position: 'absolute',top: '21%', background: 'white', width: '40%', /*maxWidth: 290,*/ display: 'flex', flexDirection: 'column',
                                height: 170, alignItems: 'start',/* paddingTop: 20,*/ paddingBottom: 20,/* paddingLeft: 25, paddingRight: 15,*/ borderRadius: 20,
                                backgroundImage: 'linear-gradient(270deg, rgba(255, 255, 255, 0.11) 37%, #fff 68%, #fff)', left: index === 1 ? null : '5%', right: index === 1 ? '5%' : null,
                                transform: index === 1 ? 'translateX(-' + pos + 'px)' : 'translateX(' + pos + 'px)',
                                transition: 'transform 0s ease'
                            }}>
                                <div style={{color: '#0056ff', fontWeight: 500, fontSize: 20, lineHeight: '30px', marginTop: 20, marginLeft:25 ,marginRight: 15}}>
                                    {boxData[index].text[boxData[index].text.length - 2]}
                                </div>
                                <div style={{color: '#919197', fontSize: 15, lineHeight: '26px', marginTop: 15, width: '80%', marginLeft:25 ,marginRight: 15}}>
                                    {boxData[index].text[3]}
                                </div>
                                <div style={{color: 'rgba(0,86,255,0.8)', borderBottom: '1px solid #c7c7c7', padding: '0px 0px 3px', fontSize: 13, fontWeight: 500, marginTop: 20, marginLeft:25 ,marginRight: 15}}
                                    onClick={()=>{
                                        window.location.href=boxData[index].href
                                    }}
                                >
                                    TILL ANNONS
                                </div>
                            </div>

                        </div>
                    )
                }
                return (
                    <div ref={el => secondBox.current[index] = el}
                         key={index}
                         style={{position: 'relative', height: 450, width: '100%', marginBottom: 50}}
                    >
                        <div style={{
                            top: 250, position: 'absolute', height: 200, width: '80%', overflow: 'hidden', borderRadius: 30, right:0,
                            transform: 'translateX(-' + pos + 'px)',
                            transition: 'transform 0s ease'
                        }}>
                            <img src={''}
                                 style={{width: '100%', height: 'inherit'}}
                            />
                        </div>
                        <div style={{
                            position: 'absolute', background: 'white', width: '80%', maxWidth: 290, display: 'flex', flexDirection: 'column',
                            height: 170, alignItems: 'start', paddingTop: 20, paddingBottom: 20, paddingLeft: 25, paddingRight: 15, borderRadius: 20,
                            backgroundImage: 'linear-gradient(270deg, rgba(255, 255, 255, 0.11) 37%, #fff 68%, #fff)', left: 0,
                            transform: 'translateX(' + pos + 'px)',
                            transition: 'transform 0s ease'
                        }}>
                            <div style={{color: '#0056ff', fontWeight: 500, fontSize: 20, lineHeight: '30px'}}>
                                Title
                            </div>
                            <div style={{color: '#919197', fontSize: 15, lineHeight: '26px', marginTop: 15, width: '80%'}}>
                                ifhofidj osij o isejofidj ofjk kjpfo wkpef okwep kepowkf pwoejk fpowejfpoijwe
                            </div>
                            <div style={{color: '#9b9b9b', borderBottom: '1px solid #c7c7c7', padding: '0px 0px 3px', fontSize: 13, fontWeight: 500, marginTop: 20}}>
                                TILL ANNONS
                            </div>
                        </div>

                    </div>
                )
            })}
        </div>
    )
}
export default MiddleComponent;