import React, {useEffect, useRef, useState} from "react";
import loga from '../../../assets/images/logo/final/wbstrans.png'
import {firstPageFunc, footerFunc, transportFunc, paymentFunc, contactFunc, carsFunc, aboutFunc, galleryFunc, settingFunc} from './PageFunc'
import FirstPageEdit from "../../firstpage/desktop/FirstPageEdit";
import AboutEdit from "../../about/desktop/AboutEdit";
import PaymentEdit from "../../payment/desktop/PaymentEdit";
import TransportEdit from "../../transport/desktop/TransportEdit";
import ContactEdit from "../../contact/desktop/ContactEdit";
import FooterEdit from "../../../common/footer/FooterEdit";
import Settings from '../../../common/Settings'
import {auth} from "../../../firebase";
const LogedinDesktop = (props) =>{

    const [pageNr, setPageNr] = useState(0)
    const [pageArr] = useState([
        {page: FirstPageEdit, func: firstPageFunc, title: 'Startsida'},
        //{page: Gallery, func: galleryFunc, title: 'Bilder'},
        {page: AboutEdit, func: aboutFunc, title: 'Om'},
        //{page: Cars, func: carsFunc, title: 'Bilar'},
        {page: ContactEdit, func: contactFunc, title: 'Kontakt'},
        {page: PaymentEdit, func: paymentFunc, title: 'Betalning'},
        {page: TransportEdit, func: transportFunc, title: 'Transport'},
        {page: FooterEdit, func: footerFunc, title: 'Footer'},
        {page: Settings, func: settingFunc, title: 'Inställningar'}
    ])

    const [page, setPage] = useState(pageArr[0])

    useEffect(()=>{
        document.body.style.overflow = 'hidden'
        return ()=>{
            document.body.style.overflow = 'scroll'
        }
    },[])

    useEffect(()=>{
        if(page && props.pageText.firstpage) {
            page.func(props)
        }
    },[page, props.pageText])

    const loggOut = async ()=>{
        await auth.signOut()
    }

    return(
        <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: 'lightgray', flexDirection: 'column', overflow:'hidden'}}>
            <div style={{height: '8vh', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                <div style={{height: '100%', width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={loga}
                         style={{height: '90%', cursor: 'pointer'}}
                         onClick={()=>{
                             window.location.href = '/'
                         }}
                    />
                </div>
                <div style={{height: '100%', width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                >
                    {pageArr.map((data, index)=>{
                        return(
                            <div key={index} style={{backgroundColor: page === data ? 'green' : '', marginRight: 10, borderStyle: 'solid solid none', cursor: 'pointer'}}>
                                <div style={{padding: 5}}
                                     onClick={()=>{
                                         setPage(data)
                                     }}
                                >{data.title}</div>
                            </div>
                        )
                    })}
                </div>
                <div style={{height: '100%', width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{marginRight: 10, borderStyle: 'solid solid none', cursor: 'pointer'}}>
                        <div style={{padding: 5}}
                            onClick={()=>{
                                loggOut()
                            }}
                        >
                            Logga ut
                        </div>
                    </div>
                </div>
            </div>
            <div style={{overflow: 'scroll', minWidth: '80%'}}
                id={'inlogContainer'}
            >
                <page.page/>
            </div>
        </div>
    )
}

export default LogedinDesktop;