import React, {useEffect, useState} from "react";
import loga from '../../../assets/images/logo/final/wbstrans.png';
import {auth} from '../../../firebase'
const LoginMobile = (props) =>{
    
    return(
        <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
            background: 'lightgray', flexDirection: 'column', overflow:'hidden'}}
        >
            Mobil version kommer, testa gärna på desktop
        </div>
    )
}

export default LoginMobile;