import React from "react";
import parse from "html-react-parser";
import PageWrapper from "../../../common/PageWrapper";
const AboutMobile = (props) =>{

    return(
        <PageWrapper titel={props.titel.upper.html}
                     letterSpacing={props.titel.upper.letterSpacing}
                     lineHeight={props.titel.upper.lineHeight}
        >
            <div style={{
                fontSize: 30,
                lineHeight: props.titel.lower.lineHeight,
                letterSpacing: props.titel.lower.letterSpacing,
                fontWeight: 400
            }}>
                {parse(props.titel.lower.html)}
            </div>
            <div style={{
                fontSize: 18,
                fontWeight: 300,
                marginBottom: 50,
                marginTop: 0,
                lineHeight: props.info.lineHeight,
                letterSpacing: props.info.letterSpacing + 'px',
            }}
            >
                {parse(props.info.html)}
            </div>
        </PageWrapper>
    )
}
export default AboutMobile

/*
const aboutTxt2 =
        //'Välkomna till Wetterstadens Bil AB. \n' +
        //'Ett familjärt företag med 25-års erfarenhet från fordonsbranschen.\n\n' +
        'Vi erbjuder fordon med bra historik till marknadens bästa priser.\n\n' +
        'Möjligheten till finansiering finns. Vi hjälper dig att skräddarsy ditt upplägg.\n' +
        'Vi samarbetar med Wasa Kredit, Svea ekonomi & My Money Go 0:- insats.\n\n' +
        'För extra trygghet erbjuder vi även våra kunder garanti som kan tecknas upp till 24 månader genom vår samarbetspartner Svensk Bilgaranti/Fragus.\n\n' +
        'Vi samarbetar med Länsförsäkringar och kan därför erbjuda 1 månads ”prova på” försäkring när du köper bil hos oss.\n\n' +
        'Vi byter gärna in din personbil, MC, mopedbil, lätt lastbil eller båt.\n\n' +
        'Vi finns på Mekanikervägen 6 i Bankeryd strax utanför Jönköping\n\n' +
        'Telefontid: Vardagar 07-18:00 Lördag 09-14:00 \n\n' +
        'Vi har öppet Mån-lördag.\n' +
        'Söndagar och röda dagar enligt ök.\n\n' +
        'Välkomna till Joacim & Pierre på Wetterstadens Bil AB.'
 */