import React, {useContext, useEffect, useState} from "react";
import {auth, db} from "../firebase";
import firebase from "firebase/compat/app";
import {Context} from '../common/context';
import Dialog from "./Dialog";
const Settings =  () =>{

    const [newPsd, setNewPsd] = useState('')
    const [doubleCheckPsd, setDoubleCheckPsd] = useState('')
    const [oldPsd, setOldPsd] = useState('')
    const [backup, setBackup] = useState(false)
    const [dialog, setDialog] = useState(false)
    const [title, setTitle] = useState('')
    const [msg, setMsg] = useState('')
    const user = useContext(Context)

    useEffect(()=>{
        if(user.settings) {
            setBackup(user.settings.backup)
        }
    }, [])

    const changePsd = () =>{
        if(newPsd.length > 5) {
            let user = auth.currentUser
            let cred = firebase.auth.EmailAuthProvider.credential(user.email, oldPsd)

            user.reauthenticateWithCredential(cred)
                .then((userr) => {
                    if (newPsd === doubleCheckPsd) {
                        user.updatePassword(newPsd).then(() => {
                            setTitle('Sparat')
                            setMsg('Lösenordet är uppdaterat')
                            setDialog(true)
                        }).catch((error) => {
                            setTitle('Fel')
                            setMsg(error.message)
                            setDialog(true)
                        });
                    } else {
                        setTitle('Fel')
                        setMsg('Lösenorden stämmer inte överens')
                        setDialog(true)
                    }
                })
                .catch((error) => {
                    setTitle('Fel')
                    setMsg('Nuvarande Lösenord är felaktigt')
                    setDialog(true)
                    console.log(error)
                });
        }else{
            setTitle('Fel')
            setMsg('Lösenordet är för kort, måste vara längre än 5 bokstäver')
            setDialog(true)
        }
    }
    const backupSwitch = () =>{
        setBackup(!backup)
    }
    const saveBackup = () =>{
        db.collection('webpage').doc('settings').update({
            backup: backup
        })
            .then(()=>{
                setTitle('Sparat')
                setMsg(backup ? 'Du använder nu backup texterna' : 'Du använder nu dina egna texter')
                setDialog(true)
            })
            .catch((error)=>{
                setTitle('Fel')
                setMsg(error.message)
                setDialog(true)
            })
    }
    const closeDialog = () =>{
        setDialog(false)
    }

    return(
        <>
            {dialog ? (<Dialog message={msg} title={title} close={closeDialog}/>) : null}
            <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                display: 'flex', flexDirection: 'row', width: 'auto', justifyContent: 'center'}}
            >
                <div style={{display: 'flex', flexDirection: 'column', padding: 20}}>
                    <div style={{fontSize: 26, alignSelf: 'center', marginBottom: 5}}>
                        Byta lösenord
                    </div>
                    <form action="javascript:void(0);"
                          style={{display: 'flex', flexDirection: 'column', fontSize: 20, fontWeight: 400,
                              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: 20, whiteSpace: 'nowrap'}}>
                        <div>
                            Nuvarande lösenord
                        </div>
                        <input style={{marginTop: 5, padding: 7}}
                               type={'password'}
                               placeholder={'Nuvarande Lösenord'}
                               value={oldPsd}
                               required={true}
                               minLength={1}
                               onInput={(e)=>{
                                   setOldPsd(e.currentTarget.value)
                               }}
                        />
                        <div style={{marginTop: 15}}>
                            Nytt lösenord
                        </div>
                        <input style={{marginTop: 5, padding: 7}}
                               type={'password'}
                               placeholder={'Nytt Lösenord'}
                               value={newPsd}
                               required={true}
                               minLength={5}
                               onInput={(e)=>{
                                   setNewPsd(e.currentTarget.value)
                               }}
                        />
                        <div style={{marginTop: 15}}>
                            Nytt lösenord
                        </div>
                        <input style={{marginTop: 5, padding: 7}}
                               type={'password'}
                               placeholder={'Nytt Lösenord'}
                               value={doubleCheckPsd}
                               required={true}
                               minLength={5}
                               onInput={(e)=>{
                                   setDoubleCheckPsd(e.currentTarget.value)
                               }}
                        />
                        <button style={{marginTop: 25, fontSize: 20, fontWeight: 500, padding: 5, cursor: 'pointer'}}
                                onClick={()=>{
                                    changePsd()
                                }}
                        >
                            Byt Lösenord
                        </button>
                    </form>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', padding: 20, width: 400}}>
                    <div style={{fontSize: 26, alignSelf: 'center', marginBottom: 5}}>
                        Beta Backup
                    </div>
                    <div style={{boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: 20, display: 'flex', flexDirection: 'column'}}>
                        <div style={{fontSize: 18}}>
                            Hände det något konstigt med dina nya texter...
                            Använd denna switch för att använda backup texterna och rappotera!
                        </div>

                        <div style={{display: 'flex', flexDirection: 'row', marginTop: 20, width: '100%', justifyContent: 'space-evenly',
                            alignItems: 'center', fontSize: 20, fontWeight: 500}}
                        >
                            <div style={{fontWeight: backup ? 300 : 500}}>
                                Egna
                            </div>
                            <label className="switch"
                                   style={{ alignSelf: 'center'}}
                            >
                                <input type="checkbox"
                                       checked={backup}
                                       onChange={()=>{
                                           backupSwitch()
                                       }}
                                />
                                <span className="slider round"/>
                            </label>
                            <div style={{fontWeight: !backup ? 300 : 500}}>
                                Backup
                            </div>
                        </div>
                        <button style={{marginTop: 25, fontSize: 20, fontWeight: 500, padding: 5, width: 200, alignSelf: 'center',
                            cursor: 'pointer'}}
                                onClick={()=>{
                                    saveBackup()
                                }}
                        >
                            Spara
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Settings;