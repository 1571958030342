import React, {useEffect, useState} from "react";
import phone from '../../../assets/images/icons/call.png';
import mail from '../../../assets/images/icons/email.png';
import blocket from "../../../assets/images/logo/blocket.svg";

const ContactMobile = (props) =>{

    const [scroll, setScroll] = useState(0)

    useEffect(()=>{
        window.addEventListener('scroll', scrollEvent, {passive: true})
        return ()=>{
            window.removeEventListener('scroll', scrollEvent)
        }
    },[])

    const scrollEvent = () =>{
        setScroll(window.scrollY)
    }



    const contact = () => {
        return(
                        <div className="gmap_canvas"
                             style={{
                                 borderRadius: 20,
                                 overflow: 'hidden',
                                 height: '100%',
                                 width: '90%',
                                 alignSelf: 'center',
                                 marginBottom: 20
                        }}>
                            <iframe width="100%" height="400px" id="gmap_canvas"
                                    title={'googleMap'}
                                    src="https://maps.google.com/maps?q=mekanikv%C3%A4gen%206%20bankeryd&t=&z=11&ie=UTF8&iwloc=&output=embed"
                                    frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"/>
                            <a href="https://www.whatismyip-address.com/divi-discount/"/>
                            <a href="https://www.embedgooglemap.net"/>
                        </div>
        )
    }

    return(
        <div style={{backgroundColor: '#f3f3f7',/*minHeight: 'calc(100vh - 70px)',*/ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 70, overflow: 'hidden',
            backfaceVisibility: 'hidden', clip: 'rect(auto, auto, auto, auto)'}}
        >
            <div style={{/*position: 'fixed', top: scroll < 300 ? 70 : 300 - scroll, height: 300,*/ width: '100%',
                //backgroundPosition: '50% 100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat',
                 display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}>
                <div style={{background: 'white', width: '100%', maxWidth: 290, display: 'flex', flexDirection: 'column', minHeight: 170, alignItems: 'start',
                    paddingTop: 20, paddingBottom: 20, paddingLeft: 25, paddingRight: 15, borderRadius: 20,
                    backgroundImage: 'linear-gradient(90deg, rgba(255, 255, 255, 0.11) 37%, #fff 68%, #fff)', marginTop: 20, marginBottom: 20}}
                >
                    <div style={{color: 'rgb(0,147,201)', fontWeight: 500, fontSize: 20, lineHeight: '30px'}}>
                        Kontakt
                    </div>
                    <div style={{color: '#919197', fontSize: 15, lineHeight: '26px', marginTop: 15, width: '100%'}}>
                        {props.info.text}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', color: '#919197', fontSize: 15, lineHeight: '26px', marginTop: 15, width: '100%'}}>
                        <div style={{display: 'flex', flexDirection: 'column', /*color: 'rgb(0,147,201)'*/}}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: "center"}}>
                                <a href={'tel:+46' + props.contact.phone.replaceAll(/\s/g,'').replaceAll(/-/g,'').replace(/0/,'')}
                                    style={{textDecoration: 'inherit', color: 'inherit'}}
                                >
                                    <img src={phone}
                                        style={{height: 30, filter: 'brightness(.9)'}}
                                    />
                                </a>
                                <div style={{height: 30, marginLeft: 10, marginTop: -2, fontWeight: 600}}>
                                    {props.contact.phone}
                                </div>
                            </div>
                            <a href={'mailto:' + props.contact.email}
                               style={{textDecoration: 'inherit',color: 'inherit', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                <div>
                                    <img src={mail}
                                        style={{width: 30, filter: 'brightness(.9)'}}
                                    />
                                </div>
                                <div style={{height: 30, marginLeft: 10, marginTop: -2, fontWeight: 600}}>
                                    {props.contact.email}
                                </div>
                            </a>
                        </div>
                    </div>
                    <div style={{color: '#9b9b9b', padding: '0px 0px 3px', fontSize: 16, fontWeight: 500, marginTop: 15}}>
                        <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <a href="https://www.facebook.com/wetterstadensbilab/" className="social-icon w-inline-block"
                                style={{filter: 'invert(.6)'}}
                            >
                                <img src="https://assets.website-files.com/5a7d94ae2dbf1b0001dc27df/5a7f230dee248c0001384b27_font-awesome_4-7-0_facebook_100_0_ffffff_none.png"
                                     width="22"
                                     alt=""
                                     style={{cursor:'pointer', marginTop: 5}}
                                />
                            </a>
                            <a href="https://www.instagram.com/wetterstadensbilab/"
                               style={{cursor: 'pointer', marginTop: 5, marginLeft: 10, filter: 'invert(.6)'}}
                            >
                                <img style={{cursor: 'pointer'}}
                                     src="https://assets.website-files.com/5a7d94ae2dbf1b0001dc27df/5a7f232638edac000186dc94_font-awesome_4-7-0_instagram_100_0_ffffff_none.png"
                                     width="22" alt=""/>
                            </a>
                            <a href={'https://www.blocket.se/butik/wetterstadens-bil'}
                               style={{cursor: 'pointer', marginLeft: 15, display: 'flex', flexDirection: 'row', textDecoration: 'none', filter: 'grayscale(50%)'}}
                            >
                                <img style={{cursor: 'pointer'}}
                                     src={blocket}
                                     width="100" alt=""
                                />
                                <div style={{color: 'white', textDecoration: 'none', marginTop: 5}}>
                                    .se
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: "center", width: '100%',//marginTop: 300,
                fontFamily: 'Oswald', fontSize: 24, fontWeight: 300, position: 'relative',
                color: 'hsla(0, 0%, 100%, 0.6)',/*minHeight: window.innerHeight - 70 - 300*/}}
            >
                {contact()}
            </div>
        </div>
    )
}

export default ContactMobile

/*
  const submitMessage = (e) => {
        e.preventDefault();
        let body = {
            name: document.getElementById('contactform_name').value,
            subject: document.getElementById('contactform_subject').value,
            email: document.getElementById('contactform_email').value,
            phone: document.getElementById('contactform_phone').value,
            message: document.getElementById('contactform_message').value
        }
        let button = document.getElementById('contactform_submit');
        button.disabled = true
        button.innerText = 'Skickar...'
        /*fetch('https://europe-west1-wetterstadensbil.cloudfunctions.net/appserver/update/mail', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then((data) => {
                alert('Meddelandet är nu skickat')
                button.innerText = 'Skicka'
                button.disabled = false;
                document.getElementById('contactform_message').value = '';
                document.getElementById('contactform_subject').value = ''
                document.getElementById('contactform_phone').value = '';
                document.getElementById('contactform_email').value = '';
                document.getElementById('contactform_name').value = ''
            })
            .catch(() => {
                alert('Testa att skicka igen något gick fel')
                button.innerText = 'Skicka'
                button.disabled = false;
            })*/

//}


/*
<div style={{display: 'flex', height: '90%', flexDirection: 'column', justifyContent: 'space-around', width: '90%'}}>
                    <div>
                        <div style={{fontSize: 40, fontWeight: 600, fontFamily: 'Oswald', color: 'white'}}>
                            Kontakt
                        </div>
                        <div style={{fontSize: 17, fontWeight: 400, fontFamily: 'Oswald', color: 'hsla(0,0%,100%,0.7)', lineHeight: '23px', marginBottom: 15}}>
                            Hör gärna av dig
                        </div>
                    </div>
<form id={'wf-form-Contact-Form'}
                          name={'wf-form-Contact-Form'}
                          data-name={'Contact Form'} method={'get'}
                          onSubmit={(e)=>{
                              e.preventDefault()
                              submitMessage(e)
                              return false
                          }}
                    >
                        <input type={'text'}
                               id={'contactform_name'}
                               maxLength={256}
                               name={'name'}
                               placeholder={'Skriv ditt namn'}
                               required
                               style={{width: 'calc(100% - 24px)', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 30, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                   padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                        />
                        <input type={'text'}
                               id={'contactform_email'}
                               maxLength={256}
                               name={'email'}
                               placeholder={'Skriv din email'}
                               required
                               style={{width: 'calc(100% - 24px)', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 30, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                   padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                        />
                        <input type={'text'}
                               id={'contactform_phone'}
                               maxLength={256}
                               name={'phone'}
                               placeholder={'Skriv ditt telefonnummer'}
                               required
                               style={{width: 'calc(100% - 24px)', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 30, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                   padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                        />
                        <input type={'text'}
                               id={'contactform_subject'}
                               maxLength={256}
                               name={'subject'}
                               placeholder={'Skriv ditt ämne'}
                               required
                               style={{width: 'calc(100% - 24px)', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 30, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                   padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                        />
                        <input type={'text'}
                               id={'contactform_message'}
                               maxLength={5000}
                               name={'message'}
                               placeholder={'Skriv ditt medelande'}
                               required
                               style={{width: 'calc(100% - 24px)', marginBottom: 20, float: 'none', borderStyle: 'none', borderRadius: 10, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)', paddingTop: '20px', paddingBottom: '50px',
                                   paddingLeft: '12px', paddingRight: '12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                        />
                        <button style={{width: 'calc(100% - 24px)', textAlign: 'center', backgroundColor: '#0056ff', padding: '14px 24px', borderRadius: 3, boxShadow: '0 10px 50px -12px rgba(0, 0, 0, 0.3)', color: 'white',
                            fontWeight: 500, cursor: 'pointer', appearance: 'button', transition: 'all 300ms ease', border: 0, display: 'inline-block', marginLeft: 12
                        }}
                               type={'submit'}
                               id={'contactform_submit'}
                               value={'Skicka Meddelande'}
                               >
                               Skicka Meddelande
                               </button>
                    </form>
                    </div>
 */