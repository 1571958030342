import {useContext, useEffect, useRef, useState} from "react";
import {db} from '../../../firebase'
import TextEditor from "../../../common/textEditor/TextEditor";
import {Context} from "../../../common/context";

const FirstPageDesktop = () =>{
    const introBox = useRef();
    const [imgHeight, setImgHeight] = useState(1)

    const user = useContext(Context)
    const [letterSpacing, setLetterSpacing] = useState(1)
    const [lineHeight, setLineHeight] = useState(1)
    const [currentHTML, setCurrentHTML] = useState('')
    const [editorSpace, setEditorSpace] = useState()
    const [activeElement, setActiveElement] = useState('upperTitel')
    const [element, setElement] = useState([
        {
            id: 'upperTitel',
            html: '',
            letterSpacing: 1,
            lineHeight: 1,
            raw: '',
        },
        {
            id: 'lowerTitel',
            html: '',
            letterSpacing: 1,
            lineHeight: 1,
            raw: '',
        }
    ])

    const upperTitelRef = useRef(null);
    const lowerTitelRef = useRef(null);
    const infoTextRef = useRef(null);
    const upperTitelRefChild = useRef(null);
    const lowerTitelRefChild = useRef(null);
    const infoTextRefChild = useRef(null);





    useEffect(()=>{
        window.addEventListener('click', activeElementEvent)
        return ()=> window.removeEventListener('click', activeElementEvent)
    },[])

    useEffect(()=>{
        element.map((item)=>{
            if(item.id === activeElement){
                item.html = currentHTML
            }
        })
    }, [currentHTML])

    useEffect(()=>{
        if(editorSpace) {
            editorSpace.style.letterSpacing = letterSpacing //+ 'px'
            editorSpace.style.lineHeight = lineHeight
        }
        setElement(
            element.map((item)=>{
                return item.id === activeElement ?
                    {
                        id: activeElement,
                        lineHeight: lineHeight,
                        letterSpacing: letterSpacing,
                        html: item.html
                    } : item
            })
        )
    },[letterSpacing, lineHeight])

    useEffect(()=>{
        if(user.transport) {
            let arr = [user.firstpage.titel.upper, user.firstpage.titel.lower]
            setElement(
                element.map((item, index)=>{
                    return {
                        id: item.id,
                        element: item.element,
                        lineHeight: item.lineHeight,
                        letterSpacing: item.letterSpacing,
                        html: arr[index].html,
                    }
                })
            )
        }
    },[user])
    const activeElementEvent = (event) =>{
        if(upperTitelRef?.current?.contains(event.target)){
            setActiveElement(element[0].id)
        }else if(lowerTitelRef?.current?.contains(event.target)){
            setActiveElement(element[1].id)
        }else if(infoTextRef?.current?.contains(event.target)){
            setActiveElement(element[2].id)
        }
    }
    const save = ()=>{
        db.collection('webpage').doc('firstpage').update({
            titel: {
                upper: {
                    lineHeight: element[0].lineHeight,
                    letterSpacing: element[0].letterSpacing,
                    html: upperTitelRefChild.current.getContent()//element[0].html
                },
                lower: {
                    lineHeight: element[1].lineHeight,
                    letterSpacing: element[1].letterSpacing,
                    html: lowerTitelRefChild.current.getContent().replaceAll('<p></p>', '</br>').replaceAll('<p>', '<div>').replaceAll('</p>', '</div>')//element[1].html
                },
            }
        })
            .then(()=>{
                alert('Uppdaterat och sparat')
            })
            .catch(()=>{
                alert('Något gick fel!')
            })
    }

    return(
        <div style={{
            marginBottom: 0,
            maxWidth: ((window.innerHeight/imgHeight)/75)*100 ,
            //marginLeft: window.innerWidth > ((window.innerHeight/imgHeight)/75)*100 ? (window.innerWidth - (((window.innerHeight/imgHeight)/75)*100))/2 : 0,
        }}
             id={'pageWith'}
        >
            <div style={{
                marginTop: 300,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                position: 'relative',
                //height: window.innerHeight*.75,
                //overflow: 'hidden',
                alignItems: 'center'
            }}
            >
                <div style={{
                    //position: 'relative',
                    display: 'flex',
                    justifyContent:'space-around',
                    paddingBottom: 30,
                    zIndex: 1,
                    width: '25%',
                    height: '100%'
                }}
                     ref={introBox}
                >
                    <div style={{
                        display: 'flex',
                        height: '100%',
                        padding: 0,
                        //color: 'white',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        borderRadius: 0,
                        fontFamily: 'Oswald',
                        width: '100%'
                    }}
                         id={'introBox'}
                    >
                        <div className={'textRef'}
                             ref={upperTitelRef}
                             style={{
                                 display: 'grid',
                                 minWidth: '100%',
                                 minHeight: 30,
                                 marginBottom: 20,
                                 letterSpacing: element[0].letterSpacing + 'px',
                                 lineHeight: element[0].lineHeight
                             }}
                        >

                            <TextEditor lineHeight={{lineHeight, setLineHeight, current: element[0].lineHeight}}
                                        letterSpacing={{letterSpacing, setLetterSpacing, current: element[0].letterSpacing}}
                                        updateEditorState={setCurrentHTML}
                                        html={element[0].html}
                                        id={element[0].id}
                                        activeElement={activeElement}
                                        showToolbar={element[0].id === activeElement}
                                        ref={upperTitelRefChild}
                            />
                        </div>
                        <div className={'textRef'}
                             ref={lowerTitelRef}
                             style={{
                                 display: 'grid',
                                 minWidth: '100%',
                                 minHeight: 500,
                                 marginTop: 20,
                                 letterSpacing: element[1].letterSpacing + 'px',
                                 lineHeight: element[1].lineHeight
                             }}
                        >
                            <TextEditor lineHeight={{lineHeight, setLineHeight, current: element[1].lineHeight}}
                                        letterSpacing={{letterSpacing, setLetterSpacing, current: element[1].letterSpacing}}
                                        updateEditorState={setCurrentHTML}
                                        html={element[1].html}
                                        id={element[1].id}
                                        activeElement={activeElement}
                                        showToolbar={element[1].id === activeElement}
                                        ref={lowerTitelRefChild}
                            />
                        </div>

                    </div>
                </div>

            </div>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', height: 200, alignItems: 'center',
                        fontSize: 18, fontWeight: 600
            }}>
                <button style={{height: 50, padding: 10, marginLeft: 30, backgroundColor: 'green', fontSize: 18, fontWeight: 600}}
                    onClick={()=> {
                        save()
                    }}
                >
                    SPARA
                </button>
            </div>
        </div>
    )
}
export default FirstPageDesktop;
/*
<div id={'titelText'}
                            contentEditable={true}
                             suppressContentEditableWarning={true}
                             style={{fontSize: window.innerWidth*.014, fontWeight: 400, fontFamily: 'Goodtimes', textTransform: 'uppercase',
                                 marginBottom: 10, marginTop: 50, maxWidth: '90%', padding: 5, color: 'black', backgroundColor: 'white'}}>
                            {titel}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: 18, fontWeight: 300,
                            width: '90%', alignItems: 'center', textAlign: 'left', marginTop: 25, maxWidth: 400, maxHeight: 450}}>
                            <div id={'infoText'}
                                 contentEditable={true}
                                 suppressContentEditableWarning={true}
                                 style={{width: '95%', fontSize: 20, marginTop: 0, backgroundColor: 'white', color: 'black', padding: 10}}
                            >
                            {intro.map((text,index)=>{
                                return (<>{text}<br/><br/></>)
                            })}
                            </div>
                        </div>
 */
/*
 <button style={{height: 50, padding: 10, marginRight: 30, backgroundColor: 'yellow', fontSize: 18, fontWeight: 600}}
                        onClick={()=>{
                          preview()
                        }}
                >
                    Förhandsvisa
                </button>
 */
