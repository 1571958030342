import App from './App';
import React from 'react';
import './index.css';
import './assets/css/Fonts.css'
import history from "./common/history";
import {BrowserRouter as Router} from 'react-router-dom'
import Header from "./common/Header";
import { createRoot } from 'react-dom/client';
import {isMobile} from "react-device-detect";
import {render} from 'react-dom'
import Footer from "./common/Footer";

const container = document.getElementById('root');
const root = createRoot(container);



render(

    <React.StrictMode>
        <div style={{position: 'relative',backgroundColor:'rgb(243, 243, 247)'}}>
            {isMobile ? null : null/*<Footer/>*/}
        <Router history={history}>
            <div style={{
                position: 'relative',
                backgroundColor:'rgb(243, 243, 247)'
            }}
            >
                <Header/>
                <div style={{
                    minHeight: 'calc(100vh - 400px)' ,
                    overflow: 'hidden',
                    //backgroundColor:'rgba(38, 38, 49, .5)',//'rgba(0,58,73,0.5)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>

                    <App history={history}/>
                </div>
            </div>
        </Router>
            {isMobile ? null : null}
            {isMobile ? null : null/*<div style={{height: 400}} id={'footerHeight'}/>*/}
        </div>
    </React.StrictMode>, container
);


//rgb(0, 147, 201)