import {isMobile} from "react-device-detect";
import React, {useContext, useEffect, useState} from "react";
import TransportMobile from "./mobile/TransportMobile";
import TransportDesktop from './desktop/TransportDesktop'
import {Context} from "../../common/context";

const Transport = () =>{

    const context = useContext(Context)
   // const [titel, setTitel] = useState('')
    const [info, setInfo] = useState([])
    const [contact, setContact] = useState({phone: '', mail: '', titel: ''})
    const [titel, setTitel] = useState({
        upper: {
            lineHeight: 1,
            letterSpacing: 1,
            html: ''
        },
        lower: {
            lineHeight: 1,
            letterSpacing: 1,
            html: ''
        }})

    useEffect(()=>{
        if(context.transport){
            setTitel(context.transport.titel)
            setInfo(context.transport.info)
            setContact(context.transport.contact)
        }
    }, [context])

    return(
        <React.Fragment>
            {isMobile ? (
                <TransportMobile titel={titel} info={info} contact={contact}/>
            ):(
                <TransportMobile titel={titel} info={info} contact={contact}/>
            )}
        </React.Fragment>
    )
}

export default Transport;