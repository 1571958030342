import React, {useRef, useState} from "react";
import gsap, {Power2} from "gsap";
import Transport from "../../routes/transport/Transport";
import home from '../../assets/images/icons/menu/home.png'
import cars from '../../assets/images/icons/menu/cars.png'
import images from '../../assets/images/icons/menu/picture.png'
import contact from '../../assets/images/icons/menu/envelope.png'
import transport from '../../assets/images/icons/menu/truck-side.png'
import payment from '../../assets/images/icons/menu/bank.png'
import about from '../../assets/images/icons/menu/info.png'
import loga from '../../assets/images/logo/Logos_new/dark_logo_transparent_background_noLogo.png'
import finalLogga from '../../assets/images/logo/final/wbstrans.png'
import {useNavigate} from "react-router-dom";

const HeaderMobile = () =>{
    const routesList = () =>{
        return [
            {
                path: '/',
                icon: home,
                title: 'Hem'
            },
            {
                path: '/bilar',
                icon: cars,
                title: 'Bilar'
            },
            {
                path: '/galleri',
                icon: images,
                title: 'Bilder'
            },
            {
                path: '/transport',
                icon: transport,
                title: 'Transport'
            },
            {
                path: '/betalning',
                icon: payment,
                title: 'Betalning'
            },
            {
                path: '/kontakt',
                icon: contact,
                title: 'Kontakt'
            },
            {
                path: '/om',
                icon: about,
                title: 'Om'
            }
        ]
    }
    const [open, setOpen] = useState(false)
    const menuRef = useRef();
    const routeRef = useRef([])
    const lineOne = useRef();
    const lineTwo = useRef()
    const lineThree = useRef();
    const headerRef = useRef()
    const menuFadeRef = useRef()
    const navigate = useNavigate();

    const animMenu = () =>{
        if(!open){
            gsap.to(
                menuRef.current,
                {
                    display: 'flex',
                    duration: 0,
                }
            )
            gsap.fromTo(
                menuRef.current,
                {
                    height: 0,
                    borderBottomLeftRadius: 100
                },
                {
                    height: '100%',
                    borderBottomLeftRadius: 0,
                    duration: .6,
                    ease: Power2.easeInOut
                }
            )
            gsap.fromTo(
                menuRef.current,
                {
                    width: 0,
                },
                {
                    width: 200,
                    duration: .3,
                    ease: Power2.easeInOut,
                    onComplete: routeItem()
                }
            )
        }else{
            routeItem();
        }
        animLines();
        setOpen(!open);
    }

    const routeItem = () =>{
        for(let i = 0; i < routesList().length; i++){
            if(!open) {
                gsap.fromTo(
                    routeRef.current[i],
                    {
                        marginTop: 100,
                        opacity: 0
                    },
                    {
                        marginTop: 0,
                        opacity: 1,
                        duration: 1,
                        ease: Power2.easeInOut
                    }
                )
            }else{
                gsap.fromTo(
                    routeRef.current[i],
                    {
                        marginTop: 0,
                        opacity: 1
                    },
                    {
                        marginTop: 100,
                        opacity: 0,
                        duration: .2,
                        onComplete: ()=>{
                            if(i + 1 === routesList().length) {
                                gsap.to(
                                    menuRef.current,
                                    {
                                        display: 'none',
                                        width: 0,
                                        height: 0,
                                        borderBottomLeftRadius: 100,
                                        duration: .3,
                                    }
                                )
                            }
                        }
                    }
                )
            }
        }

        if(!open){
            gsap.to(
                menuFadeRef.current,
                {
                    display: 'flex',
                    duration: 0
                }
            )
            gsap.to(
                menuFadeRef.current,
                {
                    opacity: .5,
                    duration: .5
                }
            )
        }else{
            gsap.to(
                menuFadeRef.current,
                {
                    opacity: 0,
                    duration: .5,
                    onComplete:()=>{
                        gsap.to(
                            menuFadeRef.current,
                            {
                                display: 'none',
                                duration: 0
                            }
                        )
                    }
                }
            )
        }
    }

    const animLines = () =>{
        if(!open) {
            gsap.fromTo(
                lineOne.current,
                {
                    skewY: '0deg',
                    height: 4,
                    top: '0%'
                },
                {
                    skewY: '-50deg',
                    top: 'calc(50% - 2.5px)',
                    height: 5,
                    duration: .5,
                    onComplete: ()=> {
                        gsap.to(
                            lineOne.current,
                            {
                                skewY: '-42deg',
                                width: '100%',
                                duration: .2
                            }
                        )
                    }
                })
            gsap.fromTo(
                lineTwo.current,
                {
                    opacity: 1
                },
                {
                    opacity: 0,
                    duration: .5
                }
            )
            gsap.fromTo(
                lineThree.current,
                {
                    skewY: '0deg',
                    height: 4,
                    top: '100%'
                },
                {
                    skewY: '46deg',
                    top: 'calc(50% - 2.5px)',
                    height: 5,
                    duration: .5,
                    onComplete: ()=>{
                        gsap.to(
                            lineThree.current,
                            {
                                skewY: '42deg',
                                width: '100%',
                                duration: .2
                            }
                        )
                    }
                }
            )

        }else{
            gsap.to(
                lineOne.current,
                {
                    width: '100%',
                    height: 4,
                    top: '0%',
                    skewY: '0deg',
                    duration: .5
                },
            )
            gsap.fromTo(
                lineTwo.current,
                {
                    opacity: 0
                },
                {
                    opacity: 1,
                    duration: .5
                }
            )
            gsap.to(
                lineThree.current,
                {
                    width: '100%',
                    height: 4,
                    top: '100%',
                    skewY: '0deg',
                    duration: .5
                },
            )
        }
    };

    const scrollEvent = () =>{
        gsap.to(
            headerRef.current,
            {
                background: 'white',
                duration: 1,
                onComplete:()=>{
                    gsap.to(
                        headerRef.current,
                        {
                            boxShadow: '0 20px 100px 0 rgba(0, 0, 0, 0.15)',
                            duration: .5,
                            ease: Power2.easeInOut
                        }
                    )
                },
                ease: Power2.easeInOut
            }
        )
        window.removeEventListener('scroll', scrollEvent)
    }

    return(
        <div style={{position: 'fixed', zIndex: 999999, top: 0, left: 0, width: '100%', height: 70, background: 'white', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',}}
            ref={headerRef}
             className={'headerId'}
        >
            <div style={{/*width: '60%',*/ zIndex: 4, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img src={finalLogga}
                     style={{maxHeight: '80%', width: '100%', marginLeft: 20}}
                     onClick={()=>{
                         navigate('/')
                     }}
                />
            </div>
            <div style={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}/>
            <div style={{width: 90, display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                 onClick={()=>animMenu()}
            >
                <div style={{position: 'relative', width: '45%', height: '30%', overflow: open ? 'hidden' : 'visible'}}>
                    <div ref={lineOne}
                         style={{backgroundColor: 'black', width: '100%', height: 4, position: 'absolute', left: '0%', top: '0%', borderRadius: 100}}
                    />
                    <div ref={lineTwo}
                         style={{backgroundColor: 'black', width: '100%', height: 4, position: 'absolute', left: '0%', top: '50%', borderRadius: 20}}
                    />
                    <div ref={lineThree}
                         style={{backgroundColor: 'black', width: '100%', height: 4, position: 'absolute', left: '0%', top: '100%', borderRadius: 20}}
                    />
                </div>
                <div style={{position: 'absolute', top: 70, left: 0, width: '100vw', height: '100vh', background: 'black', opacity: 0, display: 'none'}}
                    ref={menuFadeRef}
                     onClick={()=>{
                         setOpen(false)
                     }}
                />
                <div style={{display: 'none', position: 'fixed', height: 0, top: 70, right: 0, width: 0, background: 'white', borderBottomLeftRadius: 200, flexDirection: 'column',
                    alignItems: 'center', paddingTop: 40, boxShadow: '0 20px 100px 0 rgba(0, 0, 0, 0.15)'}}
                     ref={menuRef}
                >
                        {routesList().map((data, index)=>{
                        return(
                            <div key={index}
                                 ref={(el)=> routeRef.current[index] = el}
                                 style={{marginBottom: 25, opacity: 0, display: 'flex', flexDirection: 'row', width: '80%', justifyContent: 'center', fontFamily: 'Oswald', fontWeight: 300, fontSize: 20, letterSpacing: 1.3}}
                                 onClick={()=>{
                                     if(data.path === '/bilar'){
                                         window.location.href="https://www.blocket.se/butik/wetterstadens-bil"
                                     }else{
                                         navigate(data.path);
                                     }
                                 }}
                            >
                                <div style={{marginRight: 15, alignSelf: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={data.icon} style={{height: 25}}/>
                                </div>
                                <div style={{width: '50%',  textAlign: 'left', alignSelf: 'center', height: '100%'}}>
                                    {data.title}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export default HeaderMobile;