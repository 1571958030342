import {useEffect, useRef, useState} from "react";
import finalLoga from '../../assets/images/logo/final/wbstrans.png'
import blocket from "../../assets/images/logo/blocket.svg";
const FooterMobile = (props) =>{
    const [footerPos2] = useState(0)
    const [scroll] = useState(0)
    const [scrollHeight, setScrollHeight] = useState(0)
    const [foot, setFoot] = useState(0)
    const ref = useRef()
    const scrollRef = useRef()
    const footerRef = useRef();
    const scrollHeightRef = useRef()
    scrollHeightRef.current = scrollHeight
    ref.current = footerPos2
    scrollRef.current  = scroll;

    useEffect(()=>{
        setFoot(footerRef.current.clientHeight)
        setScrollHeight(document.getElementById('root').scrollHeight)
        //document.getElementById('footerHeight').style.height = document.getElementById('footer').offsetHeight + 'px'
    },[])

    return(
        <div style={{width: '100%', height: 'auto', zIndex: 0, backgroundColor: '#262631'}}
            ref={footerRef}
             id={'footer'}
        >
            <div style={{width: '100%', height: '100%', position: 'relative', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',fontFamily: 'Oswald', color: '#fff'}}>
                <div style={{width: '90%', display: 'flex', justifyContent: 'center', flexDirection: 'column', }}>
                    <div style={{height: 90, marginTop: 30}}>
                        <img src={finalLoga}
                             style={{maxHeight: '70%', marginLeft: 0, maxWidth: '80%'}}
                        />
                    </div>
                    <div style={{marginTop: 0, display: 'flex', flexDirection: 'row'}}>
                        <a href="https://www.facebook.com/wetterstadensbilab/" className="social-icon w-inline-block">
                            <img src="https://assets.website-files.com/5a7d94ae2dbf1b0001dc27df/5a7f230dee248c0001384b27_font-awesome_4-7-0_facebook_100_0_ffffff_none.png"
                                 width="22"
                                 alt=""
                                 style={{cursor:'pointer', marginTop: 5}}
                            />
                        </a>
                        <a href="https://www.instagram.com/wetterstadensbilab/"
                           style={{cursor: 'pointer', marginTop: 5, marginLeft: 15}}
                        >
                            <img style={{cursor: 'pointer'}}
                                 src="https://assets.website-files.com/5a7d94ae2dbf1b0001dc27df/5a7f232638edac000186dc94_font-awesome_4-7-0_instagram_100_0_ffffff_none.png"
                                 width="22" alt=""/>
                        </a>
                        <a href="https://www.blocket.se/butik/wetterstadens-bil"
                           style={{cursor: 'pointer', marginLeft: 20, display: 'flex', flexDirection: 'row', textDecoration: 'none', filter: 'grayscale(50%)'}}
                        >
                            <img style={{cursor: 'pointer'}}
                                 src={blocket}
                                 width="100" alt=""
                            />
                            <div style={{color: 'white', textDecoration: 'none', marginTop: 8, marginLeft: 2}}>
                                .se
                            </div>
                        </a>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', width: '90%', height: 'auto', justifyContent: 'space-between', marginTop: 35}}>
                    <div style={{width: '50%', height: '100%', marginBottom: 10}}>
                        <div style={{marginTop: 0, letterSpacing: 1, fontSize: 14, fontWeight: 200}}>
                            <div style={{display: 'flex', fontSize: 18, borderBottom: '1px solid hsla(0, 0%, 100%, 0.05)', letterSpacing: 1, width: '100%',
                                textAlign: 'left', fontWeight: 400, paddingBottom: 10, paddingRight: 30}}
                            >
                                {props.open.titel}
                            </div>
                            <div style={{width: '100%', marginTop: 5}}>
                                <div style={{width: '100%', display: 'flex', flexDirection: 'column', fontSize: 16}}>
                                    {props.open.hours.map((hour, index)=>{
                                        return(
                                            <div key={index}>
                                                {hour}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{width: '100%', height: '100%', marginTop: 25, marginBottom: 30}}>
                        <div style={{display:'flex', width: 300, marginTop: 0, flexDirection: 'column', letterSpacing: 1}}>
                            <div style={{display: 'flex', fontSize: 18, marginBottom: 15, borderBottom: '1px solid hsla(0, 0%, 100%, 0.05)', letterSpacing: 1,
                                    paddingBottom: 10, width: 200, textAlign: 'left', fontWeight: 400}}
                            >
                                Kontakt
                            </div>
                            <div style={{fontWeight: 200, fontSize: 16}}>
                                <div style={{display: 'flex', flexDirection: 'row', marginTop: -5}}>
                                    <div>
                                        Address:
                                    </div>
                                    <div style={{marginLeft: 25}}>
                                        <div>
                                            {props.contact.address.road}
                                        </div>
                                        <div>
                                            {props.contact.address.post}
                                        </div>
                                    </div>
                                </div>
                                <div style={{fontWeight: 200}}>
                                    <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                                        <div>
                                            Tel:
                                        </div>
                                        <a href={'tel:+46' + + props.contact.phone.replaceAll(/\s/g,'').replaceAll(/-/g,'').replace(/0/,'')}
                                           style={{marginLeft: 55, textDecoration: 'inherit', color:'inherit'}}
                                        >
                                            {props.contact.phone}
                                        </a>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                                        <div>
                                            mail:
                                        </div>
                                        <a href={'mailto:' + props.contact.mail} style={{marginLeft: 48, textDecoration: 'inherit', color:'inherit'}}>
                                            {props.contact.mail}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterMobile;