import React, {useContext, useEffect, useState} from "react";
import {gsap, Power2} from "gsap";
import phone from "../../../assets/images/icons/call.png";
import mail from "../../../assets/images/icons/email.png";
import address from '../../../assets/images/icons/home.png'
import {Context} from "../../../common/context";
import {db} from "../../../firebase";

const ContactEdit = () =>{

    const user = useContext(Context)
    const [contact, setContact] = useState({email: '', phone: '', address: {road: '', post: ''}})
    const [info, setInfo] = useState({text: '', titel: ''})

    useEffect(()=>{
        if(user.contact) {
            setContact(user.contact.contact)
            setInfo(user.contact.info)
        }
    },[user])

    const preview = ()=>{

    }

    const save = ()=>{
        let infoText = document.getElementById('infoText')
        let titelText = document.getElementById('titelText')
        let contactPost = document.getElementById('contactPost')
        let contactRoad = document.getElementById('contactRoad')
        let contactEmail = document.getElementById('contactEmail')
        let contactPhone = document.getElementById('contactPhone')

        infoText = infoText.innerText
        titelText = titelText.innerHTML
        contactPost = contactPost.innerHTML
        contactRoad = contactRoad.innerHTML
        contactEmail = contactEmail.innerHTML
        contactPhone = contactPhone.innerHTML

        db.collection('webpage').doc('contact').update({
            contact: {
                address:
                    {
                        post: contactPost,
                        road: contactRoad
                    },
                email: contactEmail,
                phone: contactPhone
            },
            info:{text: infoText, titel: titelText}
        })
    }

    return(
        <div style={{minHeight: '100vh', paddingTop: 200, paddingBottom: 50, backgroundColor: 'lightgray',
            width: '100vw', display: 'flex', alignItems: 'center', flexDirection: 'column'}}
        >
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', alignItems: 'center',
                marginTop: -50, marginBottom: 80}}
            >
                <div className={'line'}
                     style={{backgroundImage: 'linear-gradient(9deg, rgba(79,175,204,0) 0%, rgba(43, 111, 245,1) 100%)',
                         width: '100%', height: 12}}
                />
                <div className={'lineText'}
                     style={{opacity: 1, marginLeft: 10, marginRight: 10, fontFamily: 'Oswald, sans-serif',
                         textTransform: 'uppercase', letterSpacing: '4px', fontWeight: 300, fontSize: 16}}
                >
                    Kontakt
                </div>
                <div className={'line'}
                     style={{backgroundImage: 'linear-gradient(270deg, rgba(79,175,204,0) 0%, rgba(43, 111, 245,1) 100%)',
                         width: '100%', height: 12}}
                />
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: "space-around", width: '80%'}}>
                <div style={{maxWidth: '30%'}}
                     id={'contactId'}
                >
                    <div style={{color: '#0056ff', fontWeight: 500, fontSize: 20, lineHeight: '30px'}}
                        id={'titelText'}
                    >
                        Info
                    </div>
                    <div style={{fontSize: 16, lineHeight: '26px', marginTop: 15, color: 'black',
                        backgroundColor: 'white', padding: 5,}}
                        id={'infoText'}
                         contentEditable={true}
                    >
                        {info.text}
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', color: '#919197', fontSize: 15, lineHeight: '26px', marginTop: 15, }}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <a style={{textDecoration: 'inherit',color: 'inherit',display: 'flex', flexDirection: 'row', alignItems: "center"}}>
                            <div>
                                <img src={phone}
                                     style={{height: 30, filter: 'brightness(.9)'}}
                                />
                            </div>
                            <div style={{height: 30, marginLeft: 10, marginTop: 0, fontWeight: 600, color: 'black',
                                backgroundColor: 'white', padding: 5, marginBottom: 5}}
                                id={'contactPhone'}
                                 contentEditable={true}
                            >
                                {contact.phone}
                            </div>
                        </a>
                        <a style={{textDecoration: 'inherit',color: 'inherit',display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <div>
                                <img src={mail}
                                     style={{width: 30, filter: 'brightness(.9)'}}
                                />
                            </div>
                            <div style={{height: 30, marginLeft: 10, marginTop: 0, fontWeight: 600, color: 'black',
                                backgroundColor: 'white', padding: 5, marginBottom: 5}}
                                id={'contactEmail'}
                                 contentEditable={true}
                            >
                                {contact.email}
                            </div>
                        </a>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div>
                            <img src={address}
                                 style={{width: 30, filter: 'brightness(.9)'}}
                            />
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left'}}>
                            <div style={{height: 30, marginLeft: 10, marginTop: 0, fontWeight: 600, textAlign: 'left',
                                color: 'black', backgroundColor: 'white', padding: 5, marginBottom: 5}}
                                id={'contactRoad'}
                                 contentEditable={true}
                            >
                                {contact.address.road}
                            </div>
                            <div style={{height: 30, marginLeft: 10, marginTop: 0, fontWeight: 600, textAlign: 'left',
                                color: 'black', backgroundColor: 'white', padding: 5}}
                                id={'contactPost'}
                                 contentEditable={true}
                            >
                                {contact.address.post}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{color: '#9b9b9b', padding: '0px 0px 3px', fontSize: 16, fontWeight: 500}}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{color: '#0056ff', fontWeight: 500, fontSize: 20, lineHeight: '30px'}}>
                            Sociala medier
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", width: '70%',
                            marginBottom: 10, marginTop: 20}}
                        >
                            <a href="https://www.instagram.com/wetterstadensbilab/"
                               style={{cursor: 'pointer', marginTop: 5, filter: 'invert(.6)'}}
                            >
                                <img style={{cursor: 'pointer'}}
                                     src="https://assets.website-files.com/5a7d94ae2dbf1b0001dc27df/5a7f232638edac000186dc94_font-awesome_4-7-0_instagram_100_0_ffffff_none.png"
                                     width="40" alt=""/>
                            </a>
                            <a href="https://www.facebook.com/wetterstadensbilab/" className="social-icon w-inline-block"
                               style={{filter: 'invert(.6)'}}
                            >
                                <img src="https://assets.website-files.com/5a7d94ae2dbf1b0001dc27df/5a7f230dee248c0001384b27_font-awesome_4-7-0_facebook_100_0_ffffff_none.png"
                                     width="40"
                                     alt=""
                                     style={{cursor:'pointer', marginTop: 5,}}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', height: 200, alignItems: 'center',
                fontSize: 18, fontWeight: 600
            }}>
                <button style={{height: 50, padding: 10, marginLeft: 30, backgroundColor: 'green', fontSize: 18, fontWeight: 600}}
                        onClick={()=> {
                            save()
                        }}
                >
                    SPARA
                </button>
            </div>
        </div>
    )
}
export default ContactEdit;
