import icon from '../../../assets/images/icons/menu/bank.png'
import {useContext, useEffect, useRef, useState} from "react";
import {Context} from "../../../common/context";
import {db} from "../../../firebase";
import TextEditor from "../../../common/textEditor/TextEditor";
import PageWrapper from "../../../common/PageWrapper";

const PaymentEdit = (props) =>{

    /*const flagRef = useRef();
    const iconRef = useRef();
    const textRef = useRef();
    const lineRef = useRef();
    const [contact, setContact] = useState({email: '', phone: '', titel: ''})
    const [info, setInfo] = useState([])*/
    const user = useContext(Context)
    const [letterSpacing, setLetterSpacing] = useState(1)
    const [lineHeight, setLineHeight] = useState(1)
    const [currentHTML, setCurrentHTML] = useState('')
    const [editorSpace, setEditorSpace] = useState()
    const [activeElement, setActiveElement] = useState('upperTitel')
    const [element, setElement] = useState([
        {
            id: 'upperTitel',
            html: '',
            letterSpacing: 1,
            lineHeight: 1,
            raw: '',
        },
        {
            id: 'lowerTitel',
            html: '',
            letterSpacing: 1,
            lineHeight: 1,
            raw: '',
        },
        {
            id: 'infoText',
            html: '',
            letterSpacing: 1,
            lineHeight: 1,
            raw: '',
        }
    ])

    const upperTitelRef = useRef(null);
    const lowerTitelRef = useRef(null);
    const infoTextRef = useRef(null);
    const upperTitelRefChild = useRef(null);
    const lowerTitelRefChild = useRef(null);
    const infoTextRefChild = useRef(null);


   /* useEffect(()=>{
        if(user.payment) {
            setContact(user.payment.contact)
            setInfo(user.payment.info)
        }
    },[user])*/

    useEffect(()=>{
        window.addEventListener('click', activeElementEvent)
        return ()=> window.removeEventListener('click', activeElementEvent)
    },[])

    useEffect(()=>{
        element.map((item)=>{
            if(item.id === activeElement){
                item.html = currentHTML
            }
        })
    }, [currentHTML])

    useEffect(()=>{
        if(editorSpace) {
            editorSpace.style.letterSpacing = letterSpacing //+ 'px'
            editorSpace.style.lineHeight = lineHeight
        }
        setElement(
            element.map((item)=>{
                return item.id === activeElement ?
                    {
                        id: activeElement,
                        lineHeight: lineHeight,
                        letterSpacing: letterSpacing,
                        html: item.html
                    } : item
            })
        )
    },[letterSpacing, lineHeight])

    useEffect(()=>{
        if(user.payment) {
            let arr = [user.payment.titel.upper, user.payment.titel.lower, user.payment.infoTemp]
            setElement(
                element.map((item, index)=>{
                    return {
                        id: item.id,
                        element: item.element,
                        lineHeight: item.lineHeight,
                        letterSpacing: item.letterSpacing,
                        html: arr[index].html,
                    }
                })
            )
        }
    },[user])
    const activeElementEvent = (event) =>{
        if(upperTitelRef?.current?.contains(event.target)){
            setActiveElement(element[0].id)
        }else if(lowerTitelRef?.current?.contains(event.target)){
            setActiveElement(element[1].id)
        }else if(infoTextRef?.current?.contains(event.target)){
            setActiveElement(element[2].id)
        }
    }
    const editComponent = () =>{
        return(
            <div className={'textRef'}
                 ref={upperTitelRef}
                 style={{
                     display: 'grid',
                     minWidth: '100%',
                     minHeight: 30,
                     marginBottom: 20,
                     letterSpacing: element[0].letterSpacing + 'px',
                     lineHeight: element[0].lineHeight
                 }}
            >

                <TextEditor lineHeight={{lineHeight, setLineHeight, current: element[0].lineHeight}}
                            letterSpacing={{letterSpacing, setLetterSpacing, current: element[0].letterSpacing}}
                            updateEditorState={setCurrentHTML}
                            html={element[0].html}
                            id={element[0].id}
                            activeElement={activeElement}
                            showToolbar={element[0].id === activeElement}
                            ref={upperTitelRefChild}
                />
            </div>
        )
    }
    const save = ()=>{
        db.collection('webpage').doc('payment').update({
            titel: {
                upper: {
                    lineHeight: element[0].lineHeight,
                    letterSpacing: element[0].letterSpacing,
                    html: upperTitelRefChild.current.getContent()//element[0].html
                },
                lower: {
                    lineHeight: element[1].lineHeight,
                    letterSpacing: element[1].letterSpacing,
                    html: lowerTitelRefChild.current.getContent().replaceAll('<p></p>', '</br>').replaceAll('<p>', '<div>').replaceAll('</p>', '</div>')//element[1].html
                },
            },
            infoTemp: {
                lineHeight: element[2].lineHeight,
                letterSpacing: element[2].letterSpacing,
                html: infoTextRefChild.current.getContent()//element[2].html,

            }
        })
            .then(()=>{
                alert('Uppdaterat och sparat')
            })
            .catch(()=>{
                alert('Något gick fel!')
            })
    }

    return(
        <>
            <PageWrapper    edit={editComponent()}
                            lineHeight={1}
                            letterSpacing={1}
            >
                <div className={'textRef'}
                     ref={lowerTitelRef}
                     style={{
                         display: 'grid',
                         minWidth: '100%',
                         minHeight: 500,
                         marginTop: 20,
                         letterSpacing: element[1].letterSpacing + 'px',
                         lineHeight: element[1].lineHeight
                     }}
                >
                    <TextEditor lineHeight={{lineHeight, setLineHeight, current: element[1].lineHeight}}
                                letterSpacing={{letterSpacing, setLetterSpacing, current: element[1].letterSpacing}}
                                updateEditorState={setCurrentHTML}
                                html={element[1].html}
                                id={element[1].id}
                                activeElement={activeElement}
                                showToolbar={element[1].id === activeElement}
                                ref={lowerTitelRefChild}
                    />
                </div>
                <div className={'textRef'}
                     ref={infoTextRef}
                     style={{
                         display: 'none',
                         minWidth: '40%',
                         alignSelf: 'start',
                         minHeight: 150,
                         marginTop: 30,
                         letterSpacing: element[2].letterSpacing + 'px',
                         lineHeight: element[2].lineHeight
                     }}
                >
                    <TextEditor lineHeight={{lineHeight, setLineHeight, current: element[2].lineHeight}}
                                letterSpacing={{letterSpacing, setLetterSpacing, current: element[2].letterSpacing}}
                                updateEditorState={setCurrentHTML}
                                html={element[2].html}
                                id={element[2].id}
                                showToolbar={element[2].id === activeElement}
                                activeElement={activeElement}
                                ref={infoTextRefChild}
                    />
                </div>
            </PageWrapper>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', height: 200, alignItems: 'center',
                fontSize: 18, fontWeight: 600
            }}>
                <button style={{height: 50, padding: 10, marginLeft: 30, backgroundColor: 'green', fontSize: 18, fontWeight: 600}}
                        onClick={()=> {
                            save()
                        }}
                >
                    SPARA
                </button>
            </div>
        </>
    )
}

export default PaymentEdit;

/*
 <div style={{minHeight: '50vh', paddingTop: 200, paddingBottom: 200, backgroundColor: 'lightgrey', width: '100vw', display: 'flex', justifyContent: "center", alignItems: 'center'}}>
                <div style={{display: 'flex', flexDirection: 'row', minHeight: '50vh', width: '80%', justifyContent: 'center', maxWidth: 1500}}>
                    <div style={{minHeight: '50vh', width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <div style={{width: '58%', alignSelf: 'start', height: '100%', backgroundImage: 'linear-gradient(0deg, rgba(79,175,204,0) 0%, rgba(43, 111, 245,1) 100%)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                             ref={flagRef}
                        >
                            <img src={icon}
                                 style={{width: '50%', opacity: 1}}
                                 ref={iconRef}
                            />
                        </div>
                    </div>
                    <div style={{ width: '50%', minHeight: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}
                         ref={textRef}
                         id={'transportId'}
                    >
                        <div className={'textRef'}  style={{opacity: 1, fontSize: 16, fontWeight: 300, letterSpacing: '4px', textTransform: 'uppercase', fontFamily: 'Oswald, sans-serif', textAlign: 'center'}}>
                            Betalning
                        </div>
                        <div style={{height: 5, backgroundImage: 'linear-gradient(90deg, rgba(79,175,204,0) 0%, rgba(43, 111, 245,1) 50%, rgba(79,175,204,0) 100%)', width: '100%', marginTop: 9, marginBottom: 6}}
                             ref={lineRef}
                        />
                        <div className={'textRef'} style={{opacity: 1, marginTop: 20, whiteSpace: 'pre-wrap', textAlign: 'left', backgroundColor: 'white',
                            color: 'black', fontFamily: 'Roboto Slab, serif', fontSize: 20, lineHeight: 1.5, fontWeight: 300}}
                        >
                            <div className={'textRef'}
                                id={'infoText'}
                                 contentEditable={true}
                                 style={{padding: 10}}
                            >
                            {info.map((text, index)=>{
                                if(index < info.length-1) {
                                    return (<>{text}<br/><br/></>)
                                }
                                return (<>{text}</>)
                            })}
                            </div>
                        </div>
                        <div className={'textRef'}
                             style={{alignSelf: 'start', fontWeight: 300, opacity: 1, whiteSpace: 'pre-wrap', textAlign: 'left',
                                  marginTop: 20, fontFamily: 'Roboto Slab, serif', fontSize: 20, lineHeight: 1.5,
                                 display: 'flex', flexDirection: 'column'}}
                        >
                            <div contentEditable={true}
                                 id={'contactTitel'}
                                style={{color: 'black', backgroundColor: 'white', padding: 5, marginBottom: 5}}
                            >
                                {contact.titel}
                            </div>
                            <a style={{textDecoration: 'inherit', color: 'black', backgroundColor: 'white', padding: 5, marginBottom: 5}}
                               id={'contactEmail'}
                               contentEditable={true}
                            >
                                {contact.email}
                            </a>
                            <a style={{textDecoration: 'inherit',color: 'black', backgroundColor: 'white', padding: 5}}
                               contentEditable={true}
                               id={'contactPhone'}
                            >
                                {contact.phone}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
 */