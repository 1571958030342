import React, {useEffect, useState} from "react";
import loga from '../../../assets/images/logo/final/wbstrans.png';
import {auth} from '../../../firebase'
const LoginDesktop = (props) =>{

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [forgotPassword, setForgottPassword] = useState(false)

    useEffect(()=>{
        document.body.style.overflow = 'hidden'
        return ()=>{
            document.body.style.overflow = 'scroll'
        }
    },[])

    const loggInButton = () =>{
        props.loggin(username, password)
        setPassword('')
    }

    if(forgotPassword){
        return(
            <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'space-between',
                alignItems: 'center', background: 'lightgray', flexDirection: 'column', overflow:'hidden'}}
            >
                <div style={{height: '8vh', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                    <div style={{height: '100%', width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={loga}
                             style={{height: '60%', cursor: 'pointer'}}
                             onClick={()=>{
                                 window.location.href = '/'
                             }}
                        />
                    </div>
                </div>
                <form action="javascript:void(0);">
                    <div>
                        <div>Email</div>
                        <input type={'email'}
                               style={{padding: 5}}
                               placeholder={'Email'}
                               value={username}
                               required={true}
                               onInput={(e)=>{
                                   setUsername(e.currentTarget.value)
                               }}
                        />
                    </div>
                    <div>
                        <button style={{width: '100%', height: 30, marginTop: 15, fontWeight: 400, fontSize: 18}}
                                name={'submit'}
                                value={'submit'}
                                onClick={()=>{
                                    auth.sendPasswordResetEmail(username)
                                        .then(()=>{
                                            console.log('Password reset email sent.')
                                            alert('Ett email är skickat för att återställa lösenordet')
                                        })
                                        .catch((error)=>{
                                            console.log(error)
                                            alert(error.code + ': ' + error.message)
                                        })
                                }}
                        >
                            Återställ Lösenord
                        </button>
                    </div>
                </form>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{marginBottom: 40, cursor: 'pointer', color: 'blue', fontSize: 26, fontWeight: 400}}
                     onMouseOver={(e)=>{
                         e.target.style.fontWeight = 500
                     }}
                     onMouseLeave={(e)=>{
                         e.target.style.fontWeight = 400
                     }}
                     onClick={()=>{
                         setForgottPassword(false)
                     }}
                >
                    Logga in
                </div>
                <div style={{marginBottom: 20, color: 'black', fontSize: 20, fontWeight: 400}}>
                    Beta version!
                </div>
            </div>
            </div>
        )
    }

    return(
        <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
            background: 'lightgray', flexDirection: 'column', overflow:'hidden'}}
        >
            <div style={{height: '8vh', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
                <div style={{height: '100%', width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={loga}
                            style={{height: '60%', cursor: 'pointer'}}
                             onClick={()=>{
                                 window.location.href = '/'
                             }}
                        />

                </div>
            </div>
            <form action="javascript:void(0);">
                <div>
                    <div>Email</div>
                    <input type={'email'}
                           style={{padding: 5}}
                           placeholder={'Email'}
                           required={true}
                           value={username}
                           onInput={(e)=>{
                               setUsername(e.currentTarget.value)
                           }}
                    />
                </div>
                <div style={{marginTop: 10}}>
                    <div>Lösenord</div>
                    <input  style={{padding: 5}}
                        type={'password'}
                           placeholder={'Lösenord'}
                           value={password}
                           required={true}
                           onInput={(e)=>{
                               setPassword(e.currentTarget.value)
                           }}
                    />
                </div>
                <div>
                    <button style={{width: '100%', height: 30, marginTop: 15, fontWeight: 400, fontSize: 18}}
                            name={'submit'}
                            value={'submit'}
                            onClick={()=>{
                                loggInButton()
                            }}
                    >
                        Logga in
                    </button>
                </div>
            </form>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{marginBottom: 40, cursor: 'pointer', color: 'blue', fontSize: 26, fontWeight: 400}}
                onMouseOver={(e)=>{
                    e.target.style.fontWeight = 500
                }}
                 onMouseLeave={(e)=>{
                     e.target.style.fontWeight = 400
                 }}
                 onClick={()=>{
                     setForgottPassword(true)
                 }}
            >
                Glömt lösenord?
            </div>
            <div style={{marginBottom: 20, color: 'black', fontSize: 20, fontWeight: 400}}>
                Beta version!
            </div>
            </div>
        </div>
    )
}

export default LoginDesktop;