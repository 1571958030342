import React, {useEffect, useRef, useState} from "react";

const CarsMobile = () =>{

    return(
        <div style={{minHeight: '100vh', backgroundColor: 'grey'}}>
            Blocket
        </div>
    )
}

export default CarsMobile;