import React, {useEffect, useRef, useState} from "react";
import CarMobile from './mobile/CarsMobile';
import CarDesktop from './desktop/CarsDesktop';
import {isMobile} from "react-device-detect";

const Cars = () =>{

    return(
        <React.Fragment>
            {isMobile ? (
                <CarMobile/>
            ):(
                <CarDesktop/>
            )}
        </React.Fragment>
    )
}

export default Cars;