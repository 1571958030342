import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import loadingIcon from '../../../assets/images/icons/Loading_2.gif'
import arrowLeft from "../../../assets/images/icons/5a83073f25c8bc00017dc516_arrow-top.svg";
import arrowRight from "../../../assets/images/icons/5a830751bd255a0001fb3866_arrow-top-2.svg";


const GalleryMobile = () =>{
    const [scroll, setScroll] = useState(0)
    const iconRef = useRef();
    const bottomRef = useRef()
    const [bHeight, setBHeight] = useState(0)
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [maxHeight, setMaxHeight] = useState(300)
    const [zIndex, setZindex] = useState(0)
    const ulRef = useRef(null)

    const [carArr, setCarArr] = useState([])
    const [carIndex, setCarIndex] = useState(0)

    useEffect(()=>{
        fetch('https://europe-west1-wetterstadensbil.cloudfunctions.net/appserver/update/instagram')
            .then((res)=>{
                return res.json()
            })
            .then((data)=>{
                setCarArr(data.data)
                setLoading(false)
            })
            .catch((e)=>{
                console.log('e', e)
            })
    },[])

    useEffect(()=>{
        window.addEventListener('scroll', scrollEvent, {passive: true})
        window.addEventListener('orientationchange', landscape)
        landscape()
        //setBHeight(bottomRef.current.clientHeight)
        return ()=>{
            window.removeEventListener('scroll', scrollEvent)
            window.removeEventListener('orientationchange', landscape)
        }
    },[])

    useEffect(()=>{
        if(!loading) {
            setMaxHeight((((ulRef.current?.getClientRects()[0].width * 0.9) / 3) / 4) * 3)
        }
    },[ulRef, loading])

    useLayoutEffect(() => {
        function updateSize() {
            if(ulRef.current) {
                console.log('upd',(((ulRef.current?.getClientRects()[0].width * 0.9) / 3) / 4) * 3)
                setMaxHeight((((ulRef.current?.getClientRects()[0].width * 0.9) / 3) / 4) * 3)
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize)
    }, []);
    const landscape = (e) =>{
        //console.log(window?.screen?.orientation?.type, e?.currentTarget?.orientation, window.orientation)
        if(window?.screen?.orientation?.type === 'landscape-primary'){
            setZindex(999999)
        }else{
            setZindex(99)
        }
    }
    const scrollEvent = () =>{
        setScroll(window.scrollY)
    }

    return(
        <div style={{minHeight: 'calc(100vh - 70px)', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 70, overflow: 'hidden',
            backfaceVisibility: 'hidden', clip: 'rect(auto, auto, auto, auto)', background: 'rgb(243, 243, 247)'
        }}
        >
            {loading ? (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: "center", width: '100%',//marginTop: 300,
                    fontFamily: 'Oswald', fontSize: 24, fontWeight: 300, position: 'relative',  background: 'rgb(243, 243, 247)',//background: 'blue', backgroundImage: 'linear-gradient(45deg, #4fafcc, #2b6ff5)',
                    /*color: 'hsla(0, 0%, 100%, 0.6)',minHeight: window.innerHeight - 70 - 300*/ height: '100%'}}
                     ref={bottomRef}
                >
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: "center", width: '80%',marginTop: 50/*filter: 'drop-shadow(2px 2px 3px black)'*/}}>

                        <div style={{textTransform: 'uppercase', letterSpacing: 4, color: 'black',fontSize: 16, textAlign: 'center'}}>
                            <p>
                                {'Galleri'}
                            </p>
                        </div>
                        <div style={{height: 5, backgroundColor: 'rgb(0,147,201)'/*'hsla(0,0%,100%,0.6)'*/, width: '100%', marginTop: 10, marginBottom: 20}}/>
                        <div style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: "center", marginBottom: 20, alignItems: 'center'}}>
                            <div style={{marginTop: 50}}>
                                <img src={loadingIcon}
                                    style={{width: 50, height: 50}}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            ):(
                <>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: "center", width: '100%',//marginTop: 300,
                        fontFamily: 'Oswald',
                        fontSize: 24,
                        fontWeight: 300,
                        position: 'relative',
                        background: 'rgb(243, 243, 247)',
                        maxWidth: 1200
                    }}
                         ref={bottomRef}
                    >
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: "center", width: '80%',marginTop: 50/*filter: 'drop-shadow(2px 2px 3px black)'*/}}>

                            <div style={{textTransform: 'uppercase', letterSpacing: 4, color: 'black',fontSize: 16, textAlign: 'center'}}>
                                <p>Galleri</p>
                            </div>
                            <div style={{height: 5, backgroundColor: 'rgb(0,147,201)'/*'hsla(0,0%,100%,0.6)'*/, width: '100%', marginTop: 10, marginBottom: 20}}/>
                            <div style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: "space-between", marginBottom: 20}}>
                                <ul id={'ulImage'}
                                    ref={ulRef}
                                    style={{display: 'flex', flexWrap: 'wrap',  width: '100%', justifyContent: 'space-between',padding: 0, marginTop: 0}}>
                                    {carArr.map((car,index)=>{
                                        return(
                                            <li key={index}
                                                style={{position: 'relative',listStyle: 'none', flex: '0 0 30.333%',display: 'flex',
                                                /*width: '30%', height: 70,*/cursor: 'pointer',maxHeight: maxHeight , maxWidth: '33%', overflow: 'hidden', marginTop: 30,
                                                justifyContent: 'center', alignItems: 'center',
                                                boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px' /*boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'*/
                                            }}
                                                onClick={()=>{
                                                    setCarIndex(index)
                                                    setOpen(true)
                                                }}
                                            >
                                                <img src={car.href}
                                                     style={{width: '100%', minHeight: '100%', objectFit: 'cover'}}
                                                />
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {open ? (
                        <div style={{
                            width: '100%',
                            height: '100%',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            zIndex: zIndex,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'rgba(0,0,0,.7)',
                            color: 'black'
                        }}
                             onClick={(e)=>{
                                 e.preventDefault()
                                 e.stopPropagation()
                                 setOpen(false)
                             }}
                        >
                            <div style={{
                                maxWidth: 1200,
                                width: '100%',
                                position: 'relative',
                                height: '100%',
                            }}
                            >
                            <img src={carArr.length > 0 ? carArr[carIndex].href : ''}
                                 style={{width: '100%', maxWidth: '100%', objectFit: 'contain', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxHeight: '100%'}}
                                 ref={iconRef}
                                 onClick={(e)=>{
                                     e.stopPropagation()
                                     e.preventDefault()
                                 }}
                            />

                                <div style={{position: 'absolute', top: '40%', left: 10, width: '6%',overflow: 'hidden', minWidth: 35}}>
                                    <img src={arrowLeft}
                                         style={{position: 'relative', top: '0%', left: -2, width: '150%', cursor: 'pointer'}}
                                         onClick={(e)=>{
                                             e.stopPropagation()
                                             e.preventDefault()
                                             if(carIndex - 1 >= 0) {
                                                 setCarIndex(carIndex - 1)
                                             }else{
                                                 setCarIndex(carArr.length - 1)
                                             }
                                         }}
                                    />
                                </div>
                                <div style={{position: 'absolute', top: '40%', right: 10, width: '6%',overflow: 'hidden', minWidth: 35}}>
                                    <img src={arrowRight}
                                         style={{position: 'relative', top: '0%', right: '47%', width: '150%', cursor: 'pointer'}}
                                         onClick={(e)=>{
                                             e.stopPropagation()
                                             e.preventDefault()
                                             if(carIndex+1 < carArr.length) {
                                                 setCarIndex(carIndex + 1)
                                             }else{
                                                 setCarIndex(0)
                                             }
                                         }}
                                    />
                                </div>


                            <div style={{position: 'fixed', top: zIndex === 999999 ? 20 : 85, right: 5, width: 40, height: 40, fontSize: 30,
                                textAlign: 'center', fontWeight: 900, fontFamily: 'cursive', cursor: 'pointer'}}>
                                X
                            </div>
                            </div>
                        </div>
                    ):null}
                </>
                )}


        </div>
    )
}
export default GalleryMobile;

/*
<div style={{width: 50, marginTop: 50}}>
                        <img src={icon}
                             style={{width: '100%', filter: "invert(1)"}}
                        />
                    </div>
 */

/*
<div style={{width: '100%', height: 300, position: 'fixed', top: scroll < bHeight ? 70 :  70 + bHeight - scroll, zIndex:99}}>
                <img src={carArr.length > 0 ? carArr[carIndex].href : ''}
                     style={{height: '100%', minHeight: '100%'}}
                     ref={iconRef}
                />

            </div>
 */