import PropTypes from "prop-types";
import {Component} from "react";
import FontPicker from 'font-picker';
import React from 'react';
class CustomFontPicker extends Component {

    static propTypes = {
        expanded: PropTypes.bool,
        onExpandEvent: PropTypes.func,
        onChange: PropTypes.func,
        currentState: PropTypes.object,
    };
    constructor(props) {
        super(props);
        this.state = {
            activeFontFamily: this.props.currentState.fontFamily === undefined ? 'Open Sans' : this.props.currentState.fontFamily,
            fontPicker:  '',
            id: '',
            fontsAdded: false,
        };

        this.fontPickerRef = React.createRef(null);
    }
    componentDidMount() {
        console.log(this.fontPickerRef.current.parentElement.parentElement.parentElement.parentElement.id)
        this.setState({
            id: this.fontPickerRef.current.parentElement.parentElement.parentElement.parentElement.id
        })
        //this.fontPickerRef.current.id = 'font-picker-' + this.fontPickerRef.current.parentElement.parentElement.parentElement.parentElement.id

        this.waitForElement('font-picker-' + this.fontPickerRef.current.parentElement.parentElement.parentElement.parentElement.id, ()=>{
            let fontPicker = new FontPicker(
                'AIzaSyC_LIIUdcISYz8m6QuhhA-kYFfm3pSd8vI', // Google API key
                "Open Sans", // Default font
                {
                    limit: 50,
                    pickerId: this.fontPickerRef.current.parentElement.parentElement.parentElement.parentElement.id,
                }, // Additional options

            )
            this.setState({
                fontPicker:  fontPicker,
                //id: this.fontPickerRef.current.parentElement.parentElement.parentElement.parentElement.id
            })
        })

        window.addEventListener('click', this.customFontClick)
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.customFontClick)
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let {fontPicker, fontsAdded} = this.state;

        if (fontPicker !== '' && document.getElementsByClassName('font-list').length !== 0 && !fontsAdded) {
            fontPicker.addFont('Helvetica Neue', 1)
            fontPicker.addFont('Helvetica Neue Condensed', 2)
            fontPicker.addFont('Consolas', 3)
            fontPicker.addFont('Menlo', 4)
            fontPicker.addFont('Courier New', 5)
            fontPicker.addFont('Sf Mono', 6)
            fontPicker.addFont('Good Times', 7)
            fontPicker.addFont('Icomoon', 8)
            fontPicker.addFont('Nimbus Sans', 9)
            fontPicker.addFont('Sailec', 10)
            this.setState({
                fontsAdded: true
            })
        }
console.log('new font', this.props.currentState)
        if(fontPicker !== '') {
            console.log('lyckad', prevProps.currentState.fontFamily)
            let fonts = fontPicker.getFonts()
            //if (prevProps.currentState.fontFamily !== this.props.currentState.fontFamily) {

                if(this.props.currentState.fontFamily === undefined){
                    fontPicker.setActiveFont('Open Sans')
                }else if(fonts.get(this.props.currentState.fontFamily) !== undefined /*&& fontPicker.getActiveFont().family !== this.props.currentState.fontFamily*/){
                    console.log('lyckad', fontPicker.getActiveFont())
                    fontPicker.setActiveFont(this.props.currentState.fontFamily)
                }else{
                    fontPicker.setActiveFont('Open Sans')
                }
            //}
        }
    }
    customFontClick = (e) =>{
        let className = e.target.className.split(' ')[0]
        let {fontPicker} = this.state;
        //console.log(e.target.innerText, fontPicker.getActiveFont().family, 'hej')
        if(className === 'font-button'){
            //console.log('olof', window.getSelection().type, e.target.innerText)
            //if(window.getSelection().type === 'Range'){
                /*var sel = window.getSelection();
                var span = document.createElement("span");
                if (sel.rangeCount) {
                    var range = sel.getRangeAt(0).cloneRange();
                    range.surroundContents(span);
                    sel.removeAllRanges();
                    sel.addRange(range);
                    span.style.fontFamily = e.target.innerText
                }*/

               /* let selectedText = window.getSelection().toString();
                let NoteHeader  = document.getElementsByClassName('public-DraftEditor-content')[1]//.getElementById("note_header");
                let EditedText = NoteHeader.innerText.replace(selectedText,
                    `<span style="font-family: ">${selectedText} </span>`);
                NoteHeader.innerHTML = EditedText;*/

                fontPicker.setActiveFont(e.target.innerText)
                //this.onChange({family: e.target.innerText})

           // }else {
                this.onChange({family: fontPicker.getActiveFont().family})
            //}
        }
    }
    waitForElement = (id, callback) =>{
        let poops = setInterval(function(){
            if(document.getElementById(id)){
                clearInterval(poops);
                callback();
            }
        }, 100);
    }
    onChange = (font) => {
        const { onChange } = this.props;
        this.setState({
            activeFontFamily: font.family
        })
        //this.state.fontPicker.setActiveFont()
        onChange(font.family);
    }
    render() {
        return (
            <div id={'fontParrent'}>
                <div id={`font-picker-${this.state.id}`} ref={this.fontPickerRef}/>
            </div>
        );
    }
}

export default CustomFontPicker

/*
<FontPicker
                    pickerId={'picker'}
                    apiKey={'AIzaSyC_LIIUdcISYz8m6QuhhA-kYFfm3pSd8vI'}
                    activeFontFamily={activeFontFamily}
                    onChange={(nextFont) =>
                        this.onChange(nextFont)
                    }
                />
 */