import {isMobile} from "react-device-detect";
import GalleryMobile from './mobile/GalleryMobile';
import GalleryDesktop from './desktop/GalleryDesktop'
 import React from "react";


const Gallery = () =>{

    return(
        <React.Fragment>
            {isMobile ? (
                <GalleryMobile/>
            ):(
                <GalleryMobile/>
            )}
        </React.Fragment>
    )
}

export default Gallery;