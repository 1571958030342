
const firstPageFunc = async (props) => {
/*
    let titel = document.getElementById('titelText')
    let infoArr = document.getElementsByClassName('infoText')
    let info = ''

    props.pageText.firstpage.intro.map((text, i)=>{
        info += text
        if( i !== props.pageText.firstpage.intro.length -1){
            info = info + '<br> <br>'
        }
    })

    let infoLength = infoArr.length
    for(let i = 0; i < infoLength; i++){
        if(i !== 0){
            infoArr[infoArr.length - 1].remove()
        }
    }

    titel.contentEditable = true;
    titel.style.backgroundColor= 'white'
    titel.style.color = 'black'
    titel.style.padding = '10px'

    infoArr[0].contentEditable = true
    infoArr[0].innerHTML = info
    infoArr[0].style.backgroundColor = 'white'
    infoArr[0].style.color = 'black'
    infoArr[0].style.padding = '5px'

    let parent = document.getElementById('sellBox')
    let parent2 = document.getElementById('contactBox')

    if(parent !== null) {
        parent.remove()
    }
    if(parent2 !== null) {
        parent2.remove()
    }
*/

}

const galleryFunc = () =>{
    /*
    let aboutBox = document.getElementById('introBox')

    let rubrik = aboutBox.children[2]
    let box1 = aboutBox.children[3]

    let inputRubrik = document.createElement('input')
    let inputBox1 = document.createElement('textarea')

     */
}

const aboutFunc = () =>{
    /*
    let aboutBox = document.getElementById('aboutId')

    let rubrik = aboutBox.children[2]
    let box1 = aboutBox.children[3]

    let inputRubrik = document.createElement('input')
    let inputBox1 = document.createElement('textarea')

    inputRubrik.style = rubrik.style
    inputRubrik.style.height = rubrik.clientHeight + 'px'
    inputRubrik.style.width = rubrik.clientWidth + 'px'
    inputRubrik.style.fontSize = rubrik.style.fontSize
    inputRubrik.value = 'dsaddd'//rubrik.innerText

    inputBox1.style = box1.style
    inputBox1.style.height = box1.clientHeight + 'px'
    inputBox1.style.width = box1.clientWidth + 'px'
    inputBox1.style.fontSize = box1.style.fontSize
    inputBox1.value = 'dsaddd'//rubrik.innerText

    rubrik.replaceWith(inputRubrik)
    box1.replaceWith(inputBox1)

     */
}

const carsFunc = () =>{
    /*
    let aboutBox = document.getElementById('introBox')

    let rubrik = aboutBox.children[2]
    let box1 = aboutBox.children[3]

    let inputRubrik = document.createElement('input')
    let inputBox1 = document.createElement('textarea')

    inputRubrik.style = rubrik.style
    inputRubrik.style.height = rubrik.clientHeight + 'px'
    inputRubrik.style.width = rubrik.clientWidth + 'px'
    inputRubrik.style.fontSize = rubrik.style.fontSize
    inputRubrik.value = 'dsaddd'//rubrik.innerText

    inputBox1.style = box1.style
    inputBox1.style.height = box1.clientHeight + 'px'
    inputBox1.style.width = box1.clientWidth + 'px'
    inputBox1.style.fontSize = box1.style.fontSize
    inputBox1.value = 'dsaddd'//rubrik.innerText

    rubrik.replaceWith(inputRubrik)
    box1.replaceWith(inputBox1)

     */
}

const contactFunc = () =>{
    /*
    let aboutBox = document.getElementById('contactId')

    let rubrik = aboutBox.children[0]
    let box1 = aboutBox.children[1]

    let inputRubrik = document.createElement('input')
    let inputBox1 = document.createElement('textarea')

    inputRubrik.style = rubrik.style
    inputRubrik.style.height = rubrik.clientHeight + 'px'
    inputRubrik.style.width = rubrik.clientWidth + 'px'
    inputRubrik.style.fontSize = rubrik.style.fontSize
    inputRubrik.value = 'dsaddd'//rubrik.innerText

    inputBox1.style = box1.style
    inputBox1.style.height = box1.clientHeight + 'px'
    inputBox1.style.width = box1.clientWidth + 'px'
    inputBox1.style.fontSize = box1.style.fontSize
    inputBox1.value = 'dsaddd'//rubrik.innerText

    rubrik.replaceWith(inputRubrik)
    box1.replaceWith(inputBox1)

     */
}

const paymentFunc = () =>{
    /*
    let aboutBox = document.getElementById('paymentId')

    let rubrik1 = aboutBox.children[0].children[0]
    let box1 = aboutBox.children[0].children[1]
    let rubrik2 = aboutBox.children[1].children[0]
    let box2 = aboutBox.children[1].children[1]

    let inputRubrik1 = document.createElement('input')
    let inputBox1 = document.createElement('textarea')
    let inputRubrik2 = document.createElement('input')
    let inputBox2 = document.createElement('textarea')

    inputRubrik1.style = rubrik1.style
    inputRubrik1.style.height = rubrik1.clientHeight + 'px'
    inputRubrik1.style.width = rubrik1.clientWidth + 'px'
    inputRubrik1.style.fontSize = rubrik1.style.fontSize
    inputRubrik1.value = 'dsaddd'//rubrik.innerText

    inputBox1.style = box1.style
    inputBox1.style.height = box1.clientHeight + 'px'
    inputBox1.style.width = box1.clientWidth + 'px'
    inputBox1.style.fontSize = box1.style.fontSize
    inputBox1.value = 'dsaddd'//rubrik.innerText

    inputRubrik2.style = rubrik2.style
    inputRubrik2.style.height = rubrik2.clientHeight + 'px'
    inputRubrik2.style.width = rubrik2.clientWidth + 'px'
    inputRubrik2.style.fontSize = rubrik2.style.fontSize
    inputRubrik2.value = 'dsaddd'//rubrik.innerText

    inputBox2.style = box2.style
    inputBox2.style.height = box2.clientHeight + 'px'
    inputBox2.style.width = box2.clientWidth + 'px'
    inputBox2.style.fontSize = box2.style.fontSize
    inputBox2.value = 'dsaddd'//rubrik.innerText

    rubrik1.replaceWith(inputRubrik1)
    box1.replaceWith(inputBox1)
    rubrik2.replaceWith(inputRubrik2)
    box2.replaceWith(inputBox2)

     */
}

const transportFunc = () =>{
    /*
    let aboutBox = document.getElementById('transportId')

    let rubrik = aboutBox.children[2]
    let box1 = aboutBox.children[3]

    let inputRubrik = document.createElement('input')
    let inputBox1 = document.createElement('textarea')

    inputRubrik.style = rubrik.style
    inputRubrik.style.height = rubrik.clientHeight + 'px'
    inputRubrik.style.width = rubrik.clientWidth + 'px'
    inputRubrik.style.fontSize = rubrik.style.fontSize
    inputRubrik.value = 'dsaddd'//rubrik.innerText

    inputBox1.style = box1.style
    inputBox1.style.height = box1.clientHeight + 'px'
    inputBox1.style.width = box1.clientWidth + 'px'
    inputBox1.style.fontSize = box1.style.fontSize
    inputBox1.value = 'dsaddd'//rubrik.innerText

    rubrik.replaceWith(inputRubrik)
    box1.replaceWith(inputBox1)

     */
}

const footerFunc = () =>{
   /*
    let aboutBox = document.getElementById('footerId')

    aboutBox.parentElement.style.position = 'relative'
    aboutBox.parentElement.parentElement.style.overflow = 'none'

    let box1 = aboutBox.children[0].children[1]
    let box2 = aboutBox.children[2].children[1]

    let inputBox1 = document.createElement('textarea')
    let inputBox2 = document.createElement('textarea')

    aboutBox.children[0].style.display = 'flex'
    aboutBox.children[0].style.flexDirection = 'column'

    inputBox1.style = box1.style
    inputBox1.style.height = box1.clientHeight + 'px'
    inputBox1.style.width = box1.clientWidth + 'px'
    inputBox1.style.fontSize = box1.style.fontSize
    inputBox1.value = 'dsaddd'//rubrik.innerText

    inputBox2.style = box2.style
    inputBox2.style.height = box2.clientHeight + 'px'
    inputBox2.style.width = box2.clientWidth + 'px'
    inputBox2.style.fontSize = box2.style.fontSize
    inputBox2.value = 'dsaddd'//rubrik.innerText

    box1.replaceWith(inputBox1)
    box2.replaceWith(inputBox2)

    */
}

const settingFunc = () =>{

}

export {firstPageFunc, galleryFunc, aboutFunc, carsFunc, contactFunc, paymentFunc, transportFunc, footerFunc, settingFunc}