import CustomFontPicker from "./CustomFontPicker";

const toolbar = {
    options: ['inline','fontSize', 'fontFamily', 'list', 'textAlign',
        'colorPicker', 'link', 'emoji', 'remove', 'history'
    ],
    inline: {
      options: ['bold', 'italic', 'underline', 'strikethrough']
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36]
    },
    fontFamily: {
        options: ['Arimo', 'Barlow', 'DM Sans', 'Dosis', 'Fira Sans', 'Heebo', 'Hind Siliguri',
            'IBM Plex Sans', 'Inconsolata', 'Inter', 'Josefin Sans', 'Kanit', 'Karla', 'Lato',
            'Libre Baskerville', 'Libre Franklin', 'Lora', 'Manrope', 'Material Icons',
            'Material Icons Outlined', 'Merriweather', 'Montserrat', 'Mukta', 'Mulish',
            'Nanum Gothic', 'Noto Sans', 'Noto Sans JP', 'Noto Sans KR', 'Noto Sans TC',
            'Noto Serif', 'Nunito', 'Nunito Sans', 'Open Sans', 'Oswald', 'Playfair Display',
            'Poppins', 'PT Sans', 'PT Serif', 'Quicksand', 'Raleway', 'Roboto', 'Roboto Condensed',
            'Roboto Mono', 'Roboto Slab', 'Rubik', 'Source Sans Pro', 'Source Serif Pro',
            'Titillium Web', 'Ubuntu', 'Work Sans', "arne"
        ],
        component: CustomFontPicker,
        currentState: {fontFamily: 'Open Sans'}
    }
}



export default toolbar