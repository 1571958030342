import {useEffect, useRef, useState} from 'react';
import history from "./history";

const ScrollToTop = (props) => {
    //const history = useNavigate();
    const [scrolling, setScrolling] = useState(true);
    const [loc, setLoc] = useState(history.location.pathname)
    const locRef = useRef();
    const setLocRef = useRef();
    locRef.current = loc;
    setLocRef.current = setLoc


    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            if(location.pathname !== locRef.current) {
                window.scrollTo(0, 0);
                if (window.scrollY > 100) {
                    setScrolling(true)
                    window.onscroll = e => {
                        if (window.scrollY < 5 && scrolling) {
                            setScrolling(false)
                        }
                    }
                } else {
                    setScrolling(false)
                }
                setLocRef.current(location.pathname);
            }else {
                setScrolling(false)
                setLocRef.current(location.pathname);
            }
        });
        if(window.scrollY > 100) {
            window.scrollTo(0, 0);
            setScrolling(true)
            window.onscroll = e => {
                if (window.scrollY < 5 && scrolling) {
                    setScrolling(false)
                }
            }
        }else{
            setScrolling(false)
        }
        return () => {
            unlisten();
        }
    }, []);

    return (
        <>
            {scrolling ? (
                <div style={{position: 'fixed',backgroundColor: 'black', width:'calc(100vw - 12px)', height: '100vh', top: 0, left: 0, zIndex: 99999999}}/>
            ) : (
                props.children
            )}
        </>
    )
}

export default ScrollToTop;