import icon from '../../../assets/images/icons/menu/truck-side (1).png'
import {useContext, useEffect, useRef, useState} from "react";
import {gsap, Power2} from "gsap";
import {Context} from "../../../common/context";
import {db} from "../../../firebase";
import TextEditor from "../../../common/textEditor/TextEditor";
import PageWrapper from "../../../common/PageWrapper";

const TransportEdit = () =>{

    const user = useContext(Context)
    const [letterSpacing, setLetterSpacing] = useState(1)
    const [lineHeight, setLineHeight] = useState(1)
    const [currentHTML, setCurrentHTML] = useState('')
    const [editorSpace, setEditorSpace] = useState()
    const [activeElement, setActiveElement] = useState('upperTitel')
    const [element, setElement] = useState([
        {
            id: 'upperTitel',
            html: '',
            letterSpacing: 1,
            lineHeight: 1,
            raw: '',
        },
        {
            id: 'lowerTitel',
            html: '',
            letterSpacing: 1,
            lineHeight: 1,
            raw: '',
        },
        {
            id: 'infoText',
            html: '',
            letterSpacing: 1,
            lineHeight: 1,
            raw: '',
        }
    ])

    const upperTitelRef = useRef(null);
    const lowerTitelRef = useRef(null);
    const infoTextRef = useRef(null);
    const upperTitelRefChild = useRef(null);
    const lowerTitelRefChild = useRef(null);
    const infoTextRefChild = useRef(null);

    useEffect(()=>{
        window.addEventListener('click', activeElementEvent)
        return ()=> window.removeEventListener('click', activeElementEvent)
    },[])

    useEffect(()=>{
        element.map((item)=>{
            if(item.id === activeElement){
                item.html = currentHTML
            }
        })
    }, [currentHTML])

    useEffect(()=>{
        if(editorSpace) {
            editorSpace.style.letterSpacing = letterSpacing //+ 'px'
            editorSpace.style.lineHeight = lineHeight
        }
        setElement(
            element.map((item)=>{
                return item.id === activeElement ?
                    {
                        id: activeElement,
                        lineHeight: lineHeight,
                        letterSpacing: letterSpacing,
                        html: item.html
                    } : item
            })
        )
    },[letterSpacing, lineHeight])

    useEffect(()=>{
        if(user.transport) {
            let arr = [user.transport.titel.upper, user.transport.titel.lower, user.transport.infoTemp]
            setElement(
                element.map((item, index)=>{
                    return {
                        id: item.id,
                        element: item.element,
                        lineHeight: item.lineHeight,
                        letterSpacing: item.letterSpacing,
                        html: arr[index].html,
                    }
                })
            )
        }
    },[user])
    const activeElementEvent = (event) =>{
        if(upperTitelRef?.current?.contains(event.target)){
            setActiveElement(element[0].id)
        }else if(lowerTitelRef?.current?.contains(event.target)){
            setActiveElement(element[1].id)
        }else if(infoTextRef?.current?.contains(event.target)){
            setActiveElement(element[2].id)
        }
    }
    const editComponent = () =>{
        return(
            <div className={'textRef'}
                 ref={upperTitelRef}
                 style={{
                     display: 'grid',
                     minWidth: '100%',
                     minHeight: 30,
                     marginBottom: 20,
                     letterSpacing: element[0].letterSpacing + 'px',
                     lineHeight: element[0].lineHeight
                 }}
            >

                <TextEditor lineHeight={{lineHeight, setLineHeight, current: element[0].lineHeight}}
                            letterSpacing={{letterSpacing, setLetterSpacing, current: element[0].letterSpacing}}
                            updateEditorState={setCurrentHTML}
                            html={element[0].html}
                            id={element[0].id}
                            activeElement={activeElement}
                            showToolbar={element[0].id === activeElement}
                            ref={upperTitelRefChild}
                />
            </div>
        )
    }
    const save = ()=>{
        db.collection('webpage').doc('transport').update({
            titel: {
                upper: {
                    lineHeight: element[0].lineHeight,
                    letterSpacing: element[0].letterSpacing,
                    html: upperTitelRefChild.current.getContent()//element[0].html
                },
                lower: {
                    lineHeight: element[1].lineHeight,
                    letterSpacing: element[1].letterSpacing,
                    html: lowerTitelRefChild.current.getContent().replaceAll('<p></p>', '</br>').replaceAll('<p>', '<div>').replaceAll('</p>', '</div>')//element[1].html
                },
            },
            infoTemp: {
                lineHeight: element[2].lineHeight,
                letterSpacing: element[2].letterSpacing,
                html: infoTextRefChild.current.getContent()//element[2].html,

            }
        })
            .then(()=>{
                alert('Uppdaterat och sparat')
            })
            .catch(()=>{
                alert('Något gick fel!')
            })
    }

    return(
        <>
            <PageWrapper    edit={editComponent()}
                            lineHeight={1}
                            letterSpacing={1}
            >
                <div className={'textRef'}
                     ref={lowerTitelRef}
                     style={{
                         display: 'grid',
                         minWidth: '100%',
                         minHeight: 500,
                         marginTop: 20,
                         letterSpacing: element[1].letterSpacing + 'px',
                         lineHeight: element[1].lineHeight
                     }}
                >
                    <TextEditor lineHeight={{lineHeight, setLineHeight, current: element[1].lineHeight}}
                                letterSpacing={{letterSpacing, setLetterSpacing, current: element[1].letterSpacing}}
                                updateEditorState={setCurrentHTML}
                                html={element[1].html}
                                id={element[1].id}
                                activeElement={activeElement}
                                showToolbar={element[1].id === activeElement}
                                ref={lowerTitelRefChild}
                    />
                </div>
                <div className={'textRef'}
                     ref={infoTextRef}
                     style={{
                         display: 'none',
                         minWidth: '40%',
                         alignSelf: 'start',
                         minHeight: 150,
                         marginTop: 30,
                         letterSpacing: element[2].letterSpacing + 'px',
                         lineHeight: element[2].lineHeight
                     }}
                >
                    <TextEditor lineHeight={{lineHeight, setLineHeight, current: element[2].lineHeight}}
                                letterSpacing={{letterSpacing, setLetterSpacing, current: element[2].letterSpacing}}
                                updateEditorState={setCurrentHTML}
                                html={element[2].html}
                                id={element[2].id}
                                showToolbar={element[2].id === activeElement}
                                activeElement={activeElement}
                                ref={infoTextRefChild}
                    />
                </div>
            </PageWrapper>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', height: 200, alignItems: 'center',
                fontSize: 18, fontWeight: 600
            }}>
                <button style={{height: 50, padding: 10, marginLeft: 30, backgroundColor: 'green', fontSize: 18, fontWeight: 600}}
                        onClick={()=> {
                            save()
                        }}
                >
                    SPARA
                </button>
            </div>
        </>
    )
}

export default TransportEdit;