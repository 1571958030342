import "./App.css";
import React, {Component, useEffect, createContext} from "react";
import {Routes, Route , useLocation} from "react-router-dom";
import ScrollToTop from "./common/ScrollToTop";
import FirstPage from "./routes/firstpage/FirstPage";
import About from "./routes/about/About";
import Payment from "./routes/payment/Payment";
import Transport from "./routes/transport/Transport";
import Gallery from "./routes/gallery/Gallery";
import Contact from "./routes/contact/Contact";
import Cars from "./routes/cars/Cars";
import Login from "./routes/login/Login";
import {db} from "./firebase";
import {Context} from "./common/context";
import {isMobile} from "react-device-detect";
import Footer from "./common/Footer";

function hook(Component){
  return function WrappedComponent(props){
    const location = useLocation();

    useEffect(()=>{
      console.log('route change')
      window.scrollTo(0,0)
    },[location])

    return(
        <Component {...props}/>
    )
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: this.routesList(),
      pageText: {}
    };
  }

  async componentDidMount() {
      await db.collection('webpage').get()
          .then((snapshot)=>{
            let obj = {};
            snapshot.docs.map((doc)=>{
               obj[doc.id] = doc.data();
            })
            if(!obj.settings.backup){
              this.setState({
                pageText: obj
              })
            }else{
               db.collection('webpagebackup').get()
                  .then((snapshot)=>{
                    let obj = {};
                    snapshot.docs.map((doc)=>{
                      obj[doc.id] = doc.data();
                    })
                      this.setState({
                        pageText: obj
                      })
                  })
                  .catch((e)=>{
                    console.log('failed to get collection',e)
                  })
            }
          })
          .catch((e)=>{
            console.log('failed to get collection',e)
          })

  }

  routesList = () =>{
    return [
      {
        path: '/',
        component: <FirstPage/>
      },
      {
        path: '/om',
        component: <About/>
      },
      {
        path: '/betalning',
        component: <Payment/>
      },
      {
        path: '/transport',
        component: <Transport/>
      },
      {
        path: '/galleri',
        component: <Gallery/>
      },
      {
        path: '/kontakt',
        component: <Contact/>
      },
      {
        path: '/bilar',
        component: <Cars/>
      }
    ]
  }

  /*setLoggin = (password, username)=>{
    this.setState({
      password: password,
      username: username
    })
  }*/

  render() {
    return (
        <ScrollToTop>
          <Context.Provider value={this.state.pageText}>
              <Routes>
                {this.routesList().map((route, index)=>{
                  return(
                          <Route path={route.path}
                                 exact={true}
                                 key={index}
                                 element={route.component}
                          />
                      )
                })}
                <Route path={'/login'}
                      exact={true}
                       element={<Login/>}
                />
              </Routes>
              {isMobile ? <Footer /> : <Footer/>/*null*/}
            {isMobile ? null : null/*<div style={{height: 400}} id={'footerHeight'}/>*/}
          </Context.Provider>
  </ScrollToTop>
    );
  }
}

export default hook(App);