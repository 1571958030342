import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import DialogMui from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class Dialog extends Component {
    constructor(props){
        super(props);
        this.state = {
            setOpen: false,
            open: this.props.open,
            action: null,
            title: '',
            message: ''
        };
    }

    componentDidMount() {
        if(this.props?.message && this.props?.title){
            this.setState({
                message: this.props.message,
                title: this.props.title,
            })
        }else{
            this.handleClose()
        }
        if(this.props?.action){
            this.setState({
                action:this.props.action
            })
        }
    }

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleClose = () => {
        if(this.props.dialogContext) {
            this.props.dialogContext()
        }else if(this.props.close){
            this.props.close(null)
        }
    };

    render() {
        let {title, message, action} = this.state;
        return (
            <div style={{zIndex: 999999999999999}}>
                <DialogMui open={true}
                    onClose={()=>this.handleClose()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{zIndex: 99999}}
                >
                    <DialogTitle id="alert-dialog-title">
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" component={'div'}>
                               <p style={{margin: 0}}>
                                   {message}
                             </p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {action !== null ? (
                            <div>
                                <Button href={''}
                                        onClick={()=>this.props.action()}
                                        color="primary"
                                        autoFocus
                                >
                                    Yes
                                </Button>
                                <Button href={''}
                                        onClick={()=>this.handleClose()}
                                        color="primary"
                                        autoFocus
                                >
                                    No
                                </Button>
                            </div>
                        ):(
                        <Button href={''}
                                onClick={()=>this.handleClose()}
                                color="primary"
                                autoFocus
                        >
                            OK
                        </Button>
                        )}
                    </DialogActions>
                </DialogMui>
            </div>
        );
    }
}
export default Dialog;
