import {useContext, useEffect, useRef, useState} from "react";
import {gsap, Power2} from "gsap";
import useOnScreen from "../../../common/OnScreen";
import {Context} from "../../../common/context";
import parse from "html-react-parser";

const FirstPageDesktop = (props) =>{
    const imgRef = useRef();
    const imgRef2 = useRef();
    const imgRef3 = useRef();
    const boxRef = useRef();
    const introBox = useRef()
    const sideLine = useRef();
    const bigBox = useRef([]);
    const contactRef = useRef();
    const [scrollLeft, setScrollLeft] = useState(0)
    const [boxData, setBoxData] = useState([])
    const [imgHeight, setImgHeight] = useState(1)
    const pageRef = useRef();
    const arrowRef = useRef();
    const user = useContext(Context)
    const [titel, setTitel] = useState({
        upper:{
            html: '',
            letterSpacing: 1,
            lineHeight: 1
        },
        lower: {
            html: '',
            letterSpacing: 1,
            lineHeight: 1
        }
    })
    const [intro, setIntro] = useState([])

    let box = [
        {
            box: {
                title: 'Titel titel',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in elementum tristique'
            },
            visible: useOnScreen(bigBox, 0),
        },
        {
            box: {
                title: 'Titel titel',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in elementum tristique'
            },
            visible: useOnScreen(bigBox, 1),
        },
        {
            box: {
                title: 'Titel titel',
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in elementum tristique'
            },
            visible: useOnScreen(bigBox, 2),
        },
    ]

    useEffect(()=>{
        if(user.firstpage) {
            setTitel(user.firstpage.titel)
            setIntro(user.firstpage.intro)
        }
    },[user])

    useEffect(()=>{
        fetch('https://europe-west1-wetterstadensbil.cloudfunctions.net/appserver/update/top3')
            .then((res)=>{
                return res.json()
            })
            .then((data)=>{
                setBoxData(data.data)
            })
            .catch((e)=>{
                console.log('e', e)
            })
        startAnim()
    },[])

    useEffect(()=>{
        gsap.fromTo(
            arrowRef.current,
            {
                opacity: 0,
            },
            {
                opacity: 1,
                duration: 2,
                ease: Power2.easeInOut
            }
        )
        let counter = 0;
        const intervalImg = setInterval(() => {
            if (counter % 3 === 0) {
                gsap.to(
                    imgRef.current,
                    {
                        opacity: 0,
                        duration: 2
                    }
                )
                gsap.fromTo(
                    imgRef2.current,
                    {
                        opacity: 0
                    },
                    {
                        opacity: 1,
                        duration: 2
                    }
                )
                gsap.to(
                    imgRef3.current,
                    {
                        opacity: 0,
                        duration: 2
                    }
                )

            } else if (counter % 3 === 1) {
                gsap.to(
                    imgRef.current,
                    {
                        opacity: 0,
                        duration: 2
                    }
                )
                gsap.to(
                    imgRef2.current,
                    {
                        opacity: 0,
                        duration: 2
                    }
                )
                gsap.fromTo(
                    imgRef3.current,
                    {
                        opacity: 0
                    },
                    {
                        opacity: 1,
                        duration: 2
                    }
                )
            } else if (counter % 3 === 2) {
                gsap.fromTo(
                    imgRef.current,
                    {
                        opacity: 0
                    },
                    {
                        opacity: 1,
                        duration: 2
                    }
                )
                gsap.to(
                    imgRef2.current,
                    {
                        opacity: 0,
                        duration: 2
                    }
                )
                gsap.to(
                    imgRef3.current,
                    {
                        opacity: 0,
                        duration: 2
                    }
                )
            }

            counter++;
        }, 4000)


        window.addEventListener('scroll', scrollEvent)
        return ()=> {
            window.removeEventListener('scroll', scrollEvent)
            clearInterval(intervalImg)
        }
    }, [])
    const startAnim = () => {
        /* gsap.fromTo(
             introBox.current,
             {
                 bottom: -introBox.current.clientHeight,
                 opacity: "0",
                 scale: 0.5
             },
             {
                 scale: 1,
                 bottom: 30,
                 opacity: "1",
                 duration: 1.8,
                 ease: Power2.easeInOut,
             }
         );*/
        gsap.fromTo(
            sideLine.current,
            {
                opacity: "0",
            },
            {
                opacity: "1",
                duration: 0,
                ease: Power2.easeInOut,
            }
        );
        gsap.to(
            imgRef.current,
            {
                opacity: 1,
                duration: 1,
                ease: Power2.easeInOut
            }
        )
        gsap.fromTo(
            imgRef.current,
            {
                scale: 2
            },
            {
                scale: 1,
                duration: 1.5,
                ease: Power2.easeInOut
            }
        )
    }

    useEffect(()=>{
        if( box[0].visible) {
            animation(0)
        }
        if( box[1].visible) {
            animation(1)
        }
        if( box[2].visible) {
            animation(2)
        }
    }, [box[0].visible, box[1].visible, box[2].visible])
    const animation = (index) =>{
        gsap.fromTo(
            bigBox.current[index],
            {
                opacity: "0",
            },
            {
                opacity: "1",
                duration: .8,
                ease: Power2.easeInOut,
            }
        );
        gsap.fromTo(
            bigBox.current[index].children[0],
            {
                marginRight: `${window.innerWidth/2}px`,
            },
            {
                marginRight: "0px",
                duration: 1,
                ease: Power2.easeInOut,
            }
        );
    }

    const scrollEvent = () =>{
        let hh = document.getElementById('root').scrollHeight
        setScrollLeft(window.scrollY*0.1)
        /*if(window.scrollY + window.innerHeight > hh - 400){
            setContactHeight(window.scrollY + window.innerHeight + 400 - hh)
        }*/
    }

    const contact = () => {
        let body = {
            name: document.getElementById('contactform_name').value,
            subject: document.getElementById('contactform_subject').value,
            email: document.getElementById('contactform_email').value,
            phone: document.getElementById('contactform_phone').value,
            message: document.getElementById('contactform_message').value
        }
        let button = document.getElementById('contactform_submit');
        button.disabled = true
        button.innerText = 'Skickar...'
        fetch('https://europe-west1-wetterstadensbil.cloudfunctions.net/appserver/update/mail', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then((data) => {
                alert('Meddelandet är nu skickat')
                button.innerText = 'Skicka'
                button.disabled = false;
                document.getElementById('contactform_message').value = '';
                document.getElementById('contactform_subject').value = ''
                document.getElementById('contactform_phone').value = '';
                document.getElementById('contactform_email').value = '';
                document.getElementById('contactform_name').value = ''
            })
            .catch(() => {
                alert('Testa att skicka igen något gick fel')
                button.innerText = 'Skicka'
                button.disabled = false;
            })
    }

    /*
                     <img style={{zIndex: -1, position: 'absolute', opacity: 0, minHeight: '100%', minWidth: '100%', left:'50%', top:'50%', transform: 'translate(-50%, -50%)'}}
                         //src={'https://firebasestorage.googleapis.com/v0/b/wetterstadensbil.appspot.com/o/img1.jpg?alt=media'}//firstCar}
                        src={'https://storage.googleapis.com/wetterstadensbil.appspot.com/instagram/1.jpg?GoogleAccessId=firebase-adminsdk-6wfpp%40wetterstadensbil.iam.gserviceaccount.com&Expires=16447017600&Signature=hGCY7uw15BudP0yfBQ4ExbH%2Bu2UVa%2F2W%2FSiH7HM7xQSeV3ILNWAZurQOBAwyTnNeOgBHdoM2q%2BnhciOPcQTPVgI7FhPtkBV5aOJIdZeFXl%2FI5p6treKDVEMDfKT%2BUUCxMpq7OMjeqR1VOvKCN31%2Be775J68TwrRrwPXMeLb4dGQ%2F6n5SDspQo%2FDstk3hdNB6s9B%2BMUd4LfZ3A3fkG1%2Bt5FQlzr4SIlWMVDUEne%2FRdTK5%2FhlJOOrgKmMmOLuGs5zZtJh%2F7ZxEVHPqLIKC3PaMMDIuf4AwDUga8N6h9CgjDKqu9BClCXfJDXEpx8f6eGS5LMoLImucPwHWZ3eutL%2FqAA%3D%3D'}
                         ref={imgRef2}
                         className="demoPage"
                    />
                    <img style={{zIndex: -1, position: 'absolute', opacity: 0, minHeight: '100%', minWidth: '100%', left:'50%', top:'50%', transform: 'translate(-50%, -50%)'}}
                         //src={'https://firebasestorage.googleapis.com/v0/b/wetterstadensbil.appspot.com/o/img2.jpg?alt=media'}//secondCar}
                        src={'https://storage.googleapis.com/wetterstadensbil.appspot.com/instagram/2.jpg?GoogleAccessId=firebase-adminsdk-6wfpp%40wetterstadensbil.iam.gserviceaccount.com&Expires=16447017600&Signature=TPU6A5ni83e36Y4ziSjWFWcKEheGDW81ez%2FAID0x09KyuvHjH0m2n1j5HEe9qs9eSZ0faOU0BN%2FKA4u1dCJGeePVuUxU%2FkuMJ0Puz1s5hsNMYN4THWMg92GQHmNLWtlkGoDRXKF9z6erm%2B2uMDJx1cuzqUJyerEU%2Fgt92N0B1SQnCEo2OacbGDmH2uzXF2d99P6c0J5op3hsr5HPLCHk%2BSd9F4OnnboXDTnpSodTGKBfFJjZ0BWXmDYeKbV1tjMbTf9epiCqMtx0puuaRYrDO%2FybJZWpBRRPU777wSCVt%2B%2BQ056KOmukmkH5XKfa6t3OMXcgkvSHrjzqXAjO7zpDow%3D%3D'}
                         ref={imgRef3}
                         className="demoPage"
                    />
                    <div style={{background: 'linear-gradient(0deg, rgba(243, 243, 247, 1) 0%, rgba(243, 243, 247, 0) 100%)', width: '100%', position: 'absolute', height: 100, bottom: 0}}
                        ref={sideLine}
                        className={'text'}
                    >
                        <div className={'fadingEffect'}></div>
                    </div>



                     <div style={{position: 'absolute', bottom: 30, height: 300, width: '100%', display: 'flex', justifyContent:'space-around', paddingBottom: 30, zIndex: 1}}
                     ref={introBox}
                >
                    <div style={{display: 'flex', backgroundColor: 'blue', width: '50vw', height: '100%',boxShadow: '0 20px 100px 0 rgba(0, 0, 0, 0.15)',// boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.75)',
                        padding: 20, color: 'white', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', textAlign: 'center',
                        borderRadius: 50, backgroundImage: 'linear-gradient(45deg, #4fafcc, #0056ff)', fontFamily: 'Oswald'}}
                         id={'introBox'}
                    >
                        <div style={{fontSize: 40, fontWeight: 400,}}>
                            Välkomna till Wetterstadens Bil
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', fontSize: 20, fontWeight: 300}}>
                            <div style={{width: '30%', fontSize: 24}}>
                                Vi erbjuder finansiering via Wasa Kredit & My Money.
                                Vi erbjuder garantier upp till 24 mån.
                            </div>
                            <div style={{width: '30%', fontSize: 24}}>
                                Vi har 25 års samlad erfarenhet från fordonsbranschen. Vårt mål är att erbjuda fordon med bra historik till marknadens bästa priser.
                            </div>
                            <div style={{width: '30%', fontSize: 24}}>
                                För att kunna erbjuda bästa service vill vi att ni ringer och bokar tid.
                            </div>
                        </div>
                    </div>
                </div>
     */

    useEffect(()=>{
        const  img = new Image()
        img.onload = ()=> {
            //console.log(this)
            //alert(img.width + 'x' + img.height)
            let height = img.height/img.width

            setImgHeight(1)
            console.log(height)
        }
        img.src = 'https://storage.googleapis.com/wetterstadensbil.appspot.com/instagram/0.jpg?GoogleAccessId=firebase-adminsdk-6wfpp%40wetterstadensbil.iam.gserviceaccount.com&Expires=16447017600&Signature=CsVF9EpYvmpW7LnBCmU0jjUrj%2BJ3i%2B%2F6bjlp2S6CaScZ%2FjpSCSV3jnowuKfDpZ%2BXZtsvPu11vGSUOjhr32vEUqCzOV4XqzhoaQkNYJZu4R7%2BmR%2B8NDWD0zIjKexHJAaOzbDw2%2BRjhY80RH4l%2BpKaixF58xCuBTuazeA%2B4trrOHfuZVGrbN%2BYU666VUw1t5drZLoOL0wjZUtMEHgL6EREVVz3kq3qVuDpcrBgxMU9UHEpyh5SEu2zADdurYRhJZOhtDFFw7BJQs1ksmZMlN6g8LX6hfSOxXudxhVJlu6GDQVQLOQxwp%2FBEoqPQc0z6sphwisKEz%2FtTB49DATeOuv%2Fmg%3D%3D'
    },[])

    const ImageContainer = (src, imageRef) => {
        return(
            <img style={{
                zIndex: -1,
                position: 'absolute',
                opacity: 0,
                minWidth: '100%',
                 left:'0%',
                top:'0%',
                //minHeight: '100%',
                maxHeight: '100%',
                objectFit: 'cover',
                width: '100%',
            }}
                src={src}
                 ref={imageRef}
                 className="demoPage"
            />
        )
    }

    return(
        <div style={{
            marginBottom: 0,
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 100,
            paddingRight: 100,
            backgroundColor: 'rgb(243,243,247)',
            maxWidth: 1200//((window.innerHeight/imgHeight)/75)*100,
            /*marginLeft: window.innerWidth > ((window.innerHeight/imgHeight)/75)*100 ?
                (window.innerWidth - (((window.innerHeight/imgHeight)/75)*100))/2 : 0,*/
        }}
             ref={pageRef}
             id={'pageWith'}
        >
            <div style={{
                marginTop: props.width < 1200 ? 70 : 100,
                display: 'flex',
                flexDirection: 'row',
                width: props.width < 1200 ? '100%' : window.innerWidth,
                alignSelf: 'center',
                maxWidth: 1400,
                /*transform: props.width < 1200 ?
                    'translateX(-' + (props.width - document.getElementById('pageWith')?.getBoundingClientRect()?.width) /2  +'px)' : null,*/
                position: 'relative',
                backgroundColor:'white',
                height: '60vh',//window.innerHeight*.75,
                overflow: 'hidden',
                minHeight: '50vh'
            }}
            >
                <div style={{
                    position: 'relative',
                    height: '100%',
                    width: '75%',
                    overflow: 'hidden',
                    backgroundColor:'white',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                     id={'imageBox'}
                >
                    {ImageContainer('https://storage.googleapis.com/wetterstadensbil.appspot.com/instagram/0.jpg?GoogleAccessId=firebase-adminsdk-6wfpp%40wetterstadensbil.iam.gserviceaccount.com&Expires=16447017600&Signature=CsVF9EpYvmpW7LnBCmU0jjUrj%2BJ3i%2B%2F6bjlp2S6CaScZ%2FjpSCSV3jnowuKfDpZ%2BXZtsvPu11vGSUOjhr32vEUqCzOV4XqzhoaQkNYJZu4R7%2BmR%2B8NDWD0zIjKexHJAaOzbDw2%2BRjhY80RH4l%2BpKaixF58xCuBTuazeA%2B4trrOHfuZVGrbN%2BYU666VUw1t5drZLoOL0wjZUtMEHgL6EREVVz3kq3qVuDpcrBgxMU9UHEpyh5SEu2zADdurYRhJZOhtDFFw7BJQs1ksmZMlN6g8LX6hfSOxXudxhVJlu6GDQVQLOQxwp%2FBEoqPQc0z6sphwisKEz%2FtTB49DATeOuv%2Fmg%3D%3D', imgRef)}
                    {ImageContainer('https://storage.googleapis.com/wetterstadensbil.appspot.com/instagram/1.jpg?GoogleAccessId=firebase-adminsdk-6wfpp%40wetterstadensbil.iam.gserviceaccount.com&Expires=16447017600&Signature=hGCY7uw15BudP0yfBQ4ExbH%2Bu2UVa%2F2W%2FSiH7HM7xQSeV3ILNWAZurQOBAwyTnNeOgBHdoM2q%2BnhciOPcQTPVgI7FhPtkBV5aOJIdZeFXl%2FI5p6treKDVEMDfKT%2BUUCxMpq7OMjeqR1VOvKCN31%2Be775J68TwrRrwPXMeLb4dGQ%2F6n5SDspQo%2FDstk3hdNB6s9B%2BMUd4LfZ3A3fkG1%2Bt5FQlzr4SIlWMVDUEne%2FRdTK5%2FhlJOOrgKmMmOLuGs5zZtJh%2F7ZxEVHPqLIKC3PaMMDIuf4AwDUga8N6h9CgjDKqu9BClCXfJDXEpx8f6eGS5LMoLImucPwHWZ3eutL%2FqAA%3D%3D', imgRef2)}
                    {ImageContainer('https://storage.googleapis.com/wetterstadensbil.appspot.com/instagram/2.jpg?GoogleAccessId=firebase-adminsdk-6wfpp%40wetterstadensbil.iam.gserviceaccount.com&Expires=16447017600&Signature=TPU6A5ni83e36Y4ziSjWFWcKEheGDW81ez%2FAID0x09KyuvHjH0m2n1j5HEe9qs9eSZ0faOU0BN%2FKA4u1dCJGeePVuUxU%2FkuMJ0Puz1s5hsNMYN4THWMg92GQHmNLWtlkGoDRXKF9z6erm%2B2uMDJx1cuzqUJyerEU%2Fgt92N0B1SQnCEo2OacbGDmH2uzXF2d99P6c0J5op3hsr5HPLCHk%2BSd9F4OnnboXDTnpSodTGKBfFJjZ0BWXmDYeKbV1tjMbTf9epiCqMtx0puuaRYrDO%2FybJZWpBRRPU777wSCVt%2B%2BQ056KOmukmkH5XKfa6t3OMXcgkvSHrjzqXAjO7zpDow%3D%3D', imgRef3)}

                </div>
                <div style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent:'space-around',
                    paddingBottom: 30,
                    zIndex: 1,
                    width: '25%',
                    maxHeight: '100%',
                    height: props.width ? document.getElementById('imageBox')?.children[0]?.getBoundingClientRect().height : document.getElementById('imageBox')?.children[0]?.getBoundingClientRect().height
                }}
                     ref={introBox}
                >
                    <div style={{display: 'flex',
                        height: '100%',
                        padding: 0,
                        color: 'white',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        borderRadius: 0,
                        //backgroundImage:'linear-gradient(90deg, var(--main-bg-color) 0%, rgba(2,0,36,1) 130%)',
                        backgroundColor: 'var(--main-bg-color)',
                        fontFamily: 'Oswald',
                        width: '100%'
                    }}
                         id={'introBox'}
                    >
                        <div id={'titelText'}
                             style={{
                                 fontSize: '1.8rem',//window.innerWidth*.014,
                                 fontWeight: 400,
                                 fontFamily: 'Goodtimes',
                                 textTransform: 'uppercase',
                                 marginBottom: 20,
                                 maxWidth: '96%',
                                 letterSpacing: titel.upper.letterSpacing,
                                 lineHeight: titel.upper.lineHeight
                        }}
                        >
                            {parse(titel.upper.html)}
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            fontSize: 21,
                            fontWeight: 300,
                            width: '90%',
                            alignItems: 'center',
                            textAlign: 'left',
                            /*height: '45%',*/
                            //marginTop: 25,
                            maxWidth: 400,
                            maxHeight: 450,
                            lineHeight: titel.lower.lineHeight,
                            letterSpacing: titel.lower.letterSpacing
                        }}
                        >
                            {parse(titel.lower.html)}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative', paddingBottom: 50}}
                 ref={boxRef}
                 id={'sellBox'}
            >
                { box.map((data, index)=>{
                    // let arr = boxData[index]?.img.split('=')
                    let inde = parseInt(index) + 1
                    let str = inde.toString()
                    let img = 'https://firebasestorage.googleapis.com/v0/b/wetterstadensbil.appspot.com/o/img' + str + '.jpg?alt=media'//arr ? arr[0] + '=original' : ''
                    if(boxData.length > 0){
                        return(
                            <div key={index}
                                 ref={el => bigBox.current[index] = el }
                                 style={{display: 'flex', flexDirection: 'row', opacity: 0, /*marginLeft: index % 2 === 1 ? -600 - scrollLeft : 600 + scrollLeft,*/ marginTop: 50}}
                            >
                                <div style={{ width: index % 2 === 1 ? 500 : 'auto', height: 500 , display: 'flex', alignItems: 'center', zIndex: 2}}>
                                    {index % 2 === 1 ? (
                                        <div style={{
                                            backgroundImage: 'linear-gradient(90deg, hsla(0, 0%, 100%, 0.11) 37%, #fff 68%, #fff)',
                                            paddingLeft: 25,
                                            paddingRight: 50,
                                            padding: '50px 50px 50px 25px',
                                            borderRadius: 20,
                                            fontFamily: 'Oswald',
                                            width: 450,
                                            display: 'flex',
                                            justifyContent: "flex-end",
                                            textAlign: 'right',
                                            flexDirection: 'column',
                                            alignItems: "flex-end",
                                            zIndex: 1,
                                            boxShadow: '100px 10px 15px -3px rgba(0,0,0,0.1)',
                                        }}>
                                            <div style={{fontSize: 30, fontWeight: 500, letterSpacing: 1, color: '#0056ff', lineHeight: '36px'}}>
                                                {boxData[index].text[boxData[index].text.length - 2]}
                                            </div>
                                            <div style={{fontSize: 20, lineHeight: '26px', color: '#919197', marginBottom: 10, marginTop: 30, flexWrap: 'wrap', width: '60%', wordWrap: 'break-word', textAlign: 'start'}}>
                                                {boxData[index].text[3]}
                                            </div>
                                            {boxData[index].text[0] === 'Bilar' ? (
                                                <div style={{fontSize: 16, lineHeight: '26px', color: '#919197', marginBottom: 0, marginTop: 0, flexWrap: 'wrap', width: '60%', display: 'flex', flexDirection: 'row'}}>
                                                    <div style={{marginRight: 10}}>
                                                        {boxData[index].text[boxData[index].text.length - 4]}
                                                    </div>

                                                    <div style={{marginRight: 0, marginLeft: 0}}>
                                                        {boxData[index].text[boxData[index].text.length - 5]}
                                                    </div>

                                                    <div style={{marginLeft: 10}}>
                                                        {boxData[index].text[boxData[index].text.length - 3]}
                                                    </div>
                                                </div>
                                            ) : null}
                                            <div style={{marginTop: 20}}>
                                                <a href={boxData[index].href}
                                                   style={{fontSize: 16, color: '#0056ff', marginBottom: 10, marginTop: 30, flexWrap: 'wrap', width: '60%', whiteSpace: 'nowrap', textDecoration: 'none'}}
                                                   onMouseOver={(e)=>{
                                                       e.currentTarget.style.fontSize = '20px'
                                                   }}
                                                   onMouseOut={(e)=>{
                                                       e.currentTarget.style.fontSize = '16px'
                                                   }}
                                                >
                                                    Mer info...
                                                </a>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{
                                            width: '100%',
                                            height: 'inherit',
                                            marginLeft: 0,
                                            //overflow: 'hidden',
                                            //boxShadow: '0 20px 50px -20px rgba(0, 0, 0, 0.3)',
                                            borderRadius: 30,
                                            filter: 'drop-shadow(-2px 20px 20px rgba(0,0,0, 0.5))'
                                        }}
                                        >
                                            <img src={img}
                                                 style={{height: '100%' }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div style={{ width: index % 2 === 1 ? 'auto' : 500, height: 500, display: 'flex', alignItems: 'center', marginLeft: -100 , overflow: index % 2 === 1 ? 'unset' : 'hidden'}}>
                                    {index % 2 === 0 ? (
                                        <div style={{
                                            backgroundImage: 'linear-gradient(270deg, hsla(0, 0%, 100%, 0.11) 37%, #fff 68%, #fff)',
                                            paddingLeft: 50,
                                            paddingRight: 25,
                                            padding: '50px 50px 50px 25px',
                                            borderRadius: 20,
                                            zIndex: 2,
                                            fontFamily: 'Oswald',
                                            width: 450,
                                            boxShadow: '-100px 10px 15px -3px rgba(0,0,0,0.1)',

                                        }}>
                                            <div style={{fontSize: 30, fontWeight: 500, letterSpacing: 1, color: '#0056ff', lineHeight: '36px'}}>
                                                {boxData[index].text[boxData[index].text.length - 2]}
                                            </div>
                                            <div style={{
                                                fontSize: 20,
                                                lineHeight: '26px',
                                                color: '#919197',
                                                marginBottom: 10,
                                                marginTop: 30,
                                                flexWrap: 'wrap',
                                                width: '60%',
                                                //whiteSpace: 'nowrap',

                                            }}>
                                                {boxData[index].text[3]}
                                            </div>
                                            {boxData[index].text[0] === 'Bilar' ? (
                                                <div style={{
                                                    fontSize: 16,
                                                    lineHeight: '26px',
                                                    color: '#919197',
                                                    marginBottom: 10,
                                                    marginTop: 0,
                                                    flexWrap: 'wrap',
                                                    width: '60%',
                                                    display: 'flex',
                                                    flexDirection: 'row'
                                                }}>
                                                    <div style={{marginRight: 10}}>
                                                        {boxData[index].text[boxData[index].text.length - 4]}
                                                    </div>

                                                    <div style={{marginRight: 0, marginLeft: 0}}>
                                                        {boxData[index].text[boxData[index].text.length - 5]}
                                                    </div>

                                                    <div style={{marginLeft: 10}}>
                                                        {boxData[index].text[boxData[index].text.length - 3]}
                                                    </div>
                                                </div>
                                            ):null}
                                            <div style={{marginTop: 20}}>
                                                <a href={boxData[index].href}
                                                   style={{fontSize: 16, color: '#0056ff', marginBottom: 10, marginTop: 30, flexWrap: 'wrap', width: '60%', whiteSpace: 'nowrap', textDecoration: 'none'}}
                                                   onMouseOver={(e)=>{
                                                       e.currentTarget.style.fontSize = '20px'
                                                   }}
                                                   onMouseOut={(e)=>{
                                                       e.currentTarget.style.fontSize = '16px'
                                                   }}
                                                >
                                                    Mer info...
                                                </a>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{
                                            width: '100%',
                                            height: 'inherit',
                                            //overflow: 'hidden',
                                            //boxShadow: '0 20px 50px -20px rgba(0, 0, 0, 0.3)',
                                            borderRadius: 30,
                                            filter: 'drop-shadow(-2px 20px 20px rgba(0,0,0, 0.5))'
                                        }}
                                        >
                                            <img src={img}
                                                 style={{height: '100%' }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    }
                    return(
                        <div key={index}
                             ref={el => bigBox.current[index] = el }
                             style={{display: 'flex', flexDirection: 'row', opacity: 0, marginLeft: index % 2 === 1 ? -600 - scrollLeft : 600 + scrollLeft, marginTop: 50}}
                        >
                            <div style={{ width: index % 2 === 1 ? 500 : 600, height: 500 , display: 'flex', alignItems: 'center'}}>
                                {index % 2 === 1 ? (
                                    <div style={{backgroundImage: 'linear-gradient(90deg, hsla(0, 0%, 100%, 0.11) 37%, #fff 68%, #fff)', paddingLeft: 25, paddingRight: 50, padding: '50px 50px 50px 25px', borderRadius: 20,
                                        fontFamily: 'Oswald', width: 450, display: 'flex', justifyContent: "flex-end", textAlign: 'right', flexDirection: 'column', alignItems: "flex-end", zIndex: 1
                                    }}>
                                        <div style={{fontSize: 30, fontWeight: 500, letterSpacing: 1, color: '#0056ff', lineHeight: '36px'}}>
                                            {data.box.title}
                                        </div>
                                        <div style={{fontSize: 15, lineHeight: '26px', color: '#919197', marginBottom: 10, marginTop: 30, flexWrap: 'wrap', width: '60%'}}>
                                            {data.box.text}
                                        </div>

                                    </div>
                                ) : (
                                    <div style={{width: '100%', height: 'inherit', marginLeft: 0, overflow: 'hidden', boxShadow: '0 20px 50px -20px rgba(0, 0, 0, 0.3)', borderRadius: 30}}>
                                        <img src={data.image}
                                             style={{height: '100%', marginLeft: -100 }}
                                        />
                                    </div>
                                )}
                            </div>
                            <div style={{ width: index % 2 === 1 ? 600 : 500, height: 500, display: 'flex', alignItems: 'center'}}>
                                {index % 2 === 0 ? (
                                    <div style={{backgroundImage: 'linear-gradient(270deg, hsla(0, 0%, 100%, 0.11) 37%, #fff 68%, #fff)', paddingLeft: 50, paddingRight: 25, padding: '50px 50px 50px 25px', borderRadius: 20, marginLeft: -100,
                                        fontFamily: 'Oswald', width: 450
                                    }}>
                                        <div style={{fontSize: 30, fontWeight: 500, letterSpacing: 1, color: '#0056ff', lineHeight: '36px'}}>
                                            {data.box.title}
                                        </div>
                                        <div style={{fontSize: 15, lineHeight: '26px', color: '#919197', marginBottom: 10, marginTop: 30, flexWrap: 'wrap', width: '60%'}}>
                                            {data.box.text}
                                        </div>
                                    </div>
                                ) : (
                                    <div style={{width: '100%', height: 'inherit', marginLeft: -100, overflow: 'hidden', boxShadow: '0 20px 50px -20px rgba(0, 0, 0, 0.3)', borderRadius: 30}}>
                                        <img src={data.image}
                                             style={{height: '100%', marginLeft: -100 }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
    )
}
export default FirstPageDesktop;

/*
{intro.map((text,index)=>{
                                return(
                                    <div key={index} className={'infoText'} style={{width: '90%', fontSize: "inherit", marginTop: index === 0 ? 0 : 15}}>
                                        {text}
                                    </div>
                                )
                            })}
 */

/*
<div style={{backgroundColor: 'blue',backgroundImage: 'linear-gradient(45deg, #4fafcc, #0056ff)', width: '100%', marginLeft: '0%', height: 650,
                        marginBottom:0, display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', zIndex: 99}}
                 ref={contactRef}
                 id={'contactBox'}
            >
                <div style={{display: 'flex', height: '90%', flexDirection: 'column', justifyContent: 'space-around', width: 400, marginRight: 0}}>
                    <div>
                        <div style={{fontSize: 50, fontWeight: 600, fontFamily: 'Oswald', color: 'white'}}>
                            Kontakt
                        </div>
                        <div style={{fontSize: 17, fontWeight: 400, fontFamily: 'Oswald', color: 'hsla(0,0%,100%,0.7)', lineHeight: '28px'}}>
                            Hör gärna av dig
                        </div>
                    </div>
                    <form id={'wf-form-Contact-Form'} name={'wf-form-Contact-Form'} data-name={'Contact Form'} method={'get'}>
                        <input type={'text'}
                               id={'contactform_name'}
                               maxLength={256}
                               name={'namn'}
                               placeholder={'Skriv ditt namn'}
                               required
                               style={{width: 'calc(100% - 24px)', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 40, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                   padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                        />
                        <input type={'text'}
                               id={'contactform_email'}
                               maxLength={256}
                               name={'namn'}
                               placeholder={'Skriv din email'}
                               required
                               style={{width: 'calc(100% - 24px)', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 40, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                   padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                        />
                        <input type={'text'}
                               id={'contactform_phone'}
                               maxLength={256}
                               name={'namn'}
                               placeholder={'Skriv ditt telefonnummer'}
                               required
                               style={{width: 'calc(100% - 24px)', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 40, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                   padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                        />
                        <input type={'text'}
                               id={'contactform_subject'}
                               maxLength={256}
                               name={'namn'}
                               placeholder={'Skriv ditt ämne'}
                               required
                               style={{width: 'calc(100% - 24px)', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 40, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                   padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                        />
                        <input type={'text'}
                               id={'contactform_message'}
                               maxLength={5000}
                               name={'namn'}
                               placeholder={'Skriv ditt medelande'}
                               required
                               style={{width: 'calc(100% - 24px)', marginBottom: 20, float: 'none', borderStyle: 'none', borderRadius: 10, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)', paddingTop: '20px', paddingBottom: '60px',
                                   paddingLeft: '12px', paddingRight: '12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                        />
                        <input style={{width: 'calc(100% - 0px)', textAlign: 'center', backgroundColor: '#0056ff', padding: '14px 24px', borderRadius: 3, boxShadow: '0 10px 50px -12px rgba(0, 0, 0, 0.3)', color: 'white',
                            fontWeight: 500, cursor: 'pointer', appearance: 'button', transition: 'all 300ms ease', border: 0, display: 'inline-block',
                        }}
                               type={'button'}
                               value={'Skicka Meddelande'}
                               id={'contactform_submit'}
                               data-wait={'Laddar...'}
                               onClick={()=>{
                                   contact();
                               }}
                        />
                    </form>
                    <div className={'success-message w-form-done'}
                         style={{display: 'none'}}
                    >
                        hej
                    </div>
                    <div className={'error-message w-form-fail'}
                         style={{display: 'none'}}
                    >
                        fail
                    </div>
                </div>
                <div style={{display: 'flex', width: 'auto', height: '80%',marginTop: 20,marginLeft: 0, flexDirection: 'column', justifyContent: 'space-between'}}>
                    <div className="mapouter" style={{position: 'relative', textAlign: 'right', height: 550, width: 700, filter: 'grayscale(100%)', borderRadius: 20, overflow: 'hidden'}}>
                        <div className="gmap_canvas" style={{overflow: 'hidden', background: 'none!important', height: 550, width: 700}}>
                            <iframe width="700" height="550" id="gmap_canvas"
                                    src="https://maps.google.com/maps?q=mekanikv%C3%A4gen%206%20bankeryd&t=&z=11&ie=UTF8&iwloc=&output=embed"
                                    frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"/>
                            <a href="https://www.whatismyip-address.com/divi-discount/"/>
                            <a href="https://www.embedgooglemap.net"/>
                        </div>
                    </div>
                </div>
            </div>
 */