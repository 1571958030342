import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyC_LIIUdcISYz8m6QuhhA-kYFfm3pSd8vI",
    authDomain: "wetterstadensbil.firebaseapp.com",
    databaseURL: "https://wetterstadensbil-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "wetterstadensbil",
    storageBucket: "wetterstadensbil.appspot.com",
    messagingSenderId: "252737264082",
    appId: "1:252737264082:web:21556197990ba72af3900b",
    measurementId: "G-8BND497CM9"
};

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();
const Auth = firebase.auth.Auth

export { auth, db , Auth};







/*
const admin = require('firebase-admin');

admin.initializeApp({
    credential: admin.credential.applicationDefault(),
    //storageBucket: "gs://dinmopedbil.appspot.com"
    //databaseURL: "https://testserver-ab0b1.firebaseio.com"
});

module.exports={admin: admin};

 */
