import React, {useContext, useEffect, useRef, useState} from "react";
import service2 from '../../../assets/images/icons/mobilepage/car.png'
import trade2 from '../../../assets/images/icons/mobilepage/customer-support.png'
import trade3 from '../../../assets/images/icons/mobilepage/trade.png'
import {gsap, Power2} from "gsap";
import MiddleComponent from "./MiddleComponent";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import car1 from '../../../assets/images/frontpage/car-selling11.jpg'
import blocket from "../../../assets/images/logo/blocket.svg";
import {Context} from "../../../common/context";
import parse from "html-react-parser";

const Testdesktop = (props) =>{

    const [scrollY, setScrollY] = useState(0)
    const contactRef = useRef();
    const carRef = useRef();
    const imgScroll = useRef()
    const initBox = useRef()
    const context = useContext(Context)
    const [titel, setTitel] = useState({
        upper: {
            html: '',
            letterSpacing: 1,
            lineHeight: 1
        },
        lower: {
            html: '',
            letterSpacing: 1,
            lineHeight: 1
        }
    })
    const [intro, setIntro] = useState([])

    useEffect(()=>{
        if(context.firstpage){
            setTitel(context.firstpage.titel)
            setIntro(context.firstpage.intro)
        }
    },[context])

    useScrollPosition((pos)=>{
        setScrollY(-pos.currPos.y)
    },[], null, false, 20)

    useEffect(()=>{
        gsap.fromTo(
            carRef.current,
            {
                scale: 2.5,
                filter: 'brightness(.9)'
            }, {
                scale: 1,
                filter: 'brightness(.8)',
                duration: 1,
                ease: Power2.easeInOut
            }
        )
       /* gsap.fromTo(
            '.headerId',
            {
                opacity: 0
            },
            {
                opacity: 1,
                duration: 1.2,
                ease: Power2.easeInOut,
                onComplete:()=>{
                    gsap.fromTo(
                        '.initText',
                        {
                            opacity: 0
                        },
                        {
                            opacity: 1,
                            duration: .2,
                            ease: Power2.easeInOut
                        }
                    )
                }
            }
        )*/

    },[])

    const contact = () => {
        let body = {
            name: document.getElementById('contactform_name').value,
            subject: document.getElementById('contactform_subject').value,
            email: document.getElementById('contactform_email').value,
            phone: document.getElementById('contactform_phone').value,
            message: document.getElementById('contactform_message').value
        }
        let button = document.getElementById('contactform_submit');
        button.disabled = true
        button.innerText = 'Skickar...'
        fetch('https://europe-west1-wetterstadensbil.cloudfunctions.net/appserver/update/mail', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then((data) => {
                alert('Meddelandet är nu skickat')
                button.innerText = 'Skicka'
                button.disabled = false;
                document.getElementById('contactform_message').value = '';
                document.getElementById('contactform_subject').value = ''
                document.getElementById('contactform_phone').value = '';
                document.getElementById('contactform_email').value = '';
                document.getElementById('contactform_name').value = ''
            })
            .catch(() => {
                alert('Testa att skicka igen något gick fel')
                button.innerText = 'Skicka'
                button.disabled = false;
            })
    }

    return(
        <div style={{/*minHeight: '100vh', backgroundColor: '#f3f3f7', */display: 'flex', flexDirection: 'column', width: '100vw', marginTop: 0, position: 'relative',
            //backgroundImage: 'url("https://assets.website-files.com/5a7d94ae2dbf1b0001dc27df/5a7db4f377769600019c9490_line.png")',
            //backgroundPosition: '50% 100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat',overflow: 'hidden'
        }}>
            <div style={{display: 'flex',flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                /*height: 'calc(100vh - 0px)',*/ overflow: 'visible', position: 'relative'}}
            >
                <div style={{ /*height: 'calc(120vh - 0px)',*/ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{overflow: 'hidden',/*display: scrollY > window.innerHeight + 600 ? 'none': 'block' ,*/opacity: 1, width: '100%',
                        /*height:'100vh', position: 'fixed', top: 0, overflow: 'hidden',transform: scrollY > window.innerHeight + 600 ? 0 : 'translateY(' + /*-scrollY*.5*//*0 + 'px)', transition: 'transform 0s linear'*/}}
                         ref={imgScroll}
                    >
                        <img //src={car1}
                            src={'https://storage.googleapis.com/wetterstadensbil.appspot.com/instagram/0.jpg?GoogleAccessId=firebase-adminsdk-6wfpp%40wetterstadensbil.iam.gserviceaccount.com&Expires=16447017600&Signature=CsVF9EpYvmpW7LnBCmU0jjUrj%2BJ3i%2B%2F6bjlp2S6CaScZ%2FjpSCSV3jnowuKfDpZ%2BXZtsvPu11vGSUOjhr32vEUqCzOV4XqzhoaQkNYJZu4R7%2BmR%2B8NDWD0zIjKexHJAaOzbDw2%2BRjhY80RH4l%2BpKaixF58xCuBTuazeA%2B4trrOHfuZVGrbN%2BYU666VUw1t5drZLoOL0wjZUtMEHgL6EREVVz3kq3qVuDpcrBgxMU9UHEpyh5SEu2zADdurYRhJZOhtDFFw7BJQs1ksmZMlN6g8LX6hfSOxXudxhVJlu6GDQVQLOQxwp%2FBEoqPQc0z6sphwisKEz%2FtTB49DATeOuv%2Fmg%3D%3D'}
                            ref={carRef}
                            style={{width: '100%', marginTop: 75, marginLeft: '0%'}}
                             //style={{minWidth: '100%', minHeight: '150vh', marginTop: -200, alignSelf:'center', transform: 'translate(-30%, 0%)'}}
                        />
                    </div>

                </div>
                <div style={{/*position: 'absolute', top: '70%', height: 'auto',*/ width: '100%', display: 'flex', //background: 'linear-gradient(90deg, rgba(0,40,255,0.6840861344537815) 0%, rgba(2,0,36,1) 100%)',//'linear-gradient(45deg, #4fafcc, #0056ff)',
                   /* boxShadow: '0 20px 100px 0 rgba(0, 0, 0, 0.15)',*/ flexDirection: 'column', borderRadius: 0, alignItems: 'center', color: 'black', fontFamily: 'Oswald',zIndex: 99, marginTop: -5}}
                >
                    <div style={{fontSize: 24, fontWeight: 400, fontFamily: 'Goodtimes', textTransform: 'uppercase', marginBottom: 0, marginTop: 30, maxWidth: '90%', textAlign: 'center', lineHeight: titel.upper.lineHeight, letterSpacing: titel.upper.letterSpacing}}>
                        {parse(titel.upper.html)}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: 26,
                        fontWeight: 300, width: '100%', /*filter: 'drop-shadow(2px 4px 6px black)' ,*/ height: '100%',
                        alignItems: 'center'
                    }}
                    >

                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: 18,
                            fontWeight: 300, width: '100%', alignItems: 'center', textAlign: 'center', /*height: '45%',*/
                            marginTop: 25, maxWidth: 400, maxHeight: 450, lineHeight: titel.lower.lineHeight, letterSpacing: titel.lower.letterSpacing
                        }}
                        >
                            {parse(titel.lower.html)}

                            <div style={{marginTop: 20, marginBottom: 20, width: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div style={{fontSize: 20}}>
                                    Titta gärna in i våran blocket butik efter din bil.
                                </div>
                                <div style={{opacity: 1, marginTop: 25, fontSize: 18, fontWeight: 300, paddingLeft: 40,
                                    paddingRight: 40, paddingBottom: 15, paddingTop: 15, background: 'blue', borderRadius: 5,
                                    backgroundImage: 'linear-gradient(45deg, #4fafcc, #2b6ff5)', color: 'white',
                                    width: 120
                                }}
                                >
                                    <a href="https://www.blocket.se/butik/wetterstadens-bil"
                                       style={{cursor: 'pointer', /*marginLeft: 15,*/ display: 'flex', flexDirection: 'row',
                                           textDecoration: 'none', filter: 'grayscale(10%)', alignItems: 'center', justifyContent: 'center'}}
                                    >
                                        <img style={{cursor: 'pointer', width: 100}}
                                             src={blocket}
                                             //width="100" alt=""
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{/*background: 'linear-gradient(0deg, rgba(243, 243, 247, 1) 0%, rgba(243, 243, 247, 0) 100%)', height: 300, width: '100%',zIndex:9*/}}/>
            <div style={{//zIndex: 9, backgroundColor: '#f3f3f7', position: 'relative',
                // backgroundImage: 'url("https://assets.website-files.com/5a7d94ae2dbf1b0001dc27df/5a7db4f377769600019c9490_line.png")',
                /*backgroundPosition: '50% 100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'*/}}>
                <MiddleComponent {...props}/>

            </div>
        </div>
    )
}
export default Testdesktop;

/*
{intro.map((text, index)=>{
                                return(
                                    <div key={index} style={{width: '90%', fontSize: 20, marginTop: index === 0 ? 0 : 15, marginBottom: index + 1 === intro.length ? 20 : 0}}>
                                        {text}
                                    </div>
                                )
                            })}
 */

/*
<div style={{opacity: scrollY < window.innerHeight ? 1.1-scrollY*0.003 : 0, display: 'flex', flexDirection: 'column',
                        alignItems: 'center', justifyContent: 'center', width: '90%', position: 'absolute', bottom: initBox.current?.clientHeight ? 'calc(40% + ' + scrollY*0.5 + 'px)' : '40%',
                        paddingRight: 50, paddingLeft: 50, textAlign: 'center', color: 'white', fontFamily: 'Oswald',transition: 'all 0s linear',
                    }}
                         ref={initBox}
                    >

                        <div style={{opacity: 0, fontSize: 20, fontWeight: 300, letterSpacing: 1.1, marginTop: 20}}
                             className={'initText'}
                        >
                            Titta gärna in i våran blocket butik efter din bil.
                        </div>
                        <div style={{opacity: 0, minWidth: 150, marginTop: 25, fontSize: 18, fontWeight: 300, paddingLeft: 40, paddingRight: 40, paddingBottom: 15, paddingTop: 15, background: 'blue', borderRadius: 5, backgroundImage: 'linear-gradient(45deg, #4fafcc, #2b6ff5)', color: 'white' }}
                             className={'initText'}
                        >
                            <a href="https://www.blocket.se/butik/wetterstadens-bil"
                               style={{cursor: 'pointer', marginLeft: 15, display: 'flex', flexDirection: 'row', textDecoration: 'none', filter: 'grayscale(10%)'}}
                            >
                                <img style={{cursor: 'pointer'}}
                                     src={blocket}
                                     width="100" alt=""
                                />
                            </a>
                        </div>
                    </div>
 */

/*
<div style={{opacity: 0, fontSize: 26, fontWeight: 400, letterSpacing: 1, maxWidth: '70%'}}
                             className={'initText'}
                        >
                            Välkommen till Wetterstadens Bil
                        </div>
 */

/*
<div style={{display: 'flex', flexDirection: "column", height: '75%', justifyContent: "space-between", marginTop: 40}}>
                            <div style={{}}>
                                <img src={service2}
                                     style={{width: 50, filter: 'invert(1)'}}
                                />
                            </div>
                            <div>
                                <img src={trade3}
                                     style={{width: 50, filter: 'invert(1)'}}
                                />
                            </div>
                            <div>
                                <img src={trade2}
                                     style={{width: 50, filter: 'invert(1)'}}
                                />
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', height: '75%', justifyContent: "space-between", marginTop: 40}}>
                            <div style={{marginLeft: 40, height: 50}}>
                                Begagnat
                            </div>
                            <div style={{marginLeft: 40, height: 50}}>
                                Inbyte
                            </div>
                            <div style={{marginLeft: 40, height: 50}}>
                                Service
                            </div>
                        </div>
 */

/*
<div style={{backgroundColor: 'blue',backgroundImage: 'linear-gradient(45deg, #4fafcc, #0056ff)', width: '100%', height: 'auto', marginBottom: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 99, paddingBottom: 60, paddingTop: 30}}
                     ref={contactRef}
                >
                    <div style={{display: 'flex', height: '90%', flexDirection: 'column', justifyContent: 'space-around', width: '90%'}}>
                        <div>
                            <div style={{fontSize: 40, fontWeight: 600, fontFamily: 'Oswald', color: 'white'}}>
                                Kontakt
                            </div>
                            <div style={{fontSize: 17, fontWeight: 400, fontFamily: 'Oswald', color: 'hsla(0,0%,100%,0.7)', lineHeight: '23px', marginBottom: 15}}>
                                Hör gärna av dig
                            </div>
                        </div>
                        <form id={'wf-form-Contact-Form'} name={'wf-form-Contact-Form'} data-name={'Contact Form'} method={'get'}>
                            <input type={'text'}
                                   id={'contactform_name'}
                                   maxLength={256}
                                   name={'namn'}
                                   placeholder={'Skriv ditt namn'}
                                   required
                                   style={{width: 'calc(100% - 24px)', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 30, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                       padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                            />
                            <input type={'text'}
                                   id={'contactform_email'}
                                   maxLength={256}
                                   name={'namn'}
                                   placeholder={'Skriv din email'}
                                   required
                                   style={{width: 'calc(100% - 24px)', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 30, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                       padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                            />
                            <input type={'text'}
                                   id={'contactform_phone'}
                                   maxLength={256}
                                   name={'namn'}
                                   placeholder={'Skriv ditt telefonnummer'}
                                   required
                                   style={{width: 'calc(100% - 24px)', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 30, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                       padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                            />
                            <input type={'text'}
                                   id={'contactform_subject'}
                                   maxLength={256}
                                   name={'namn'}
                                   placeholder={'Skriv ditt ämne'}
                                   required
                                   style={{width: 'calc(100% - 24px)', marginBottom: 15, float: 'none', borderStyle: 'none', borderRadius: 10, height: 30, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)',
                                       padding: '8px 12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                            />
                            <input type={'text'}
                                   id={'contactform_message'}
                                   maxLength={5000}
                                   name={'namn'}
                                   placeholder={'Skriv ditt medelande'}
                                   required
                                   style={{width: 'calc(100% - 24px)', marginBottom: 20, float: 'none', borderStyle: 'none', borderRadius: 10, border: '1px solid #e1e1e1', boxShadow: '0 10px 30px -10px rgba(0, 0, 0, 0.05)', paddingTop: '20px', paddingBottom: '50px',
                                       paddingLeft: '12px', paddingRight: '12px', fontSize: 14, lineHeight: 1.4, color: '#333333', verticalAlign: 'middle', backgroundColor: '#ffffff'}}
                            />
                            <input style={{width: 'calc(100% - 24px)', textAlign: 'center', backgroundColor: '#0056ff', padding: '14px 24px', borderRadius: 3, boxShadow: '0 10px 50px -12px rgba(0, 0, 0, 0.3)', color: 'white',
                                fontWeight: 500, cursor: 'pointer', appearance: 'button', transition: 'all 300ms ease', border: 0, display: 'inline-block', marginLeft: 12
                            }}
                                   type={'submit'}
                                   id={'contactform_submit'}
                                   onClick={()=>{
                                       contact()
                                   }}
                                   value={'Skicka Meddelande'}
                                   data-wait={'Laddar...'}
                            />
                        </form>
                        <div className={'success-message w-form-done'}
                             style={{display: 'none'}}
                        >
                            hej
                        </div>
                        <div className={'error-message w-form-fail'}
                             style={{display: 'none'}}
                        >
                            fail
                        </div>
                    </div>
                    <div style={{display: 'flex', width: '90%', height: '80vw',marginTop: 20, flexDirection: 'column', justifyContent: 'space-between'}}>
                        <div className="mapouter" style={{position: 'relative', textAlign: 'right', height: '100%', width: '100%', filter: 'grayscale(100%)', borderRadius: 20, overflow: 'hidden'}}>
                            <div className="gmap_canvas" style={{overflow: 'hidden', background: 'none!important', height: '100%', width: '100%'}}>
                                <iframe width="100%" height="100%" id="gmap_canvas"
                                        src="https://maps.google.com/maps?q=mekanikv%C3%A4gen%206%20bankeryd&t=&z=11&ie=UTF8&iwloc=&output=embed"
                                        frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"/>
                                <a href="https://www.whatismyip-address.com/divi-discount/"/>
                                <a href="https://www.embedgooglemap.net"/>
                            </div>
                        </div>
                    </div>
                </div>
 */