import {isMobile} from "react-device-detect";
import AboutMobile from './mobile/AboutMobile';
import AboutDesktop from './desktop/AboutDesktop';
import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../common/context";

const About = () =>{

    const context = useContext(Context)
    const [info, setInfo] = useState({
        lineHeight: 1,
        letterSpacing: 1,
        html: ''

    })
    const [titel, setTitel] = useState({
            upper: {
                lineHeight: 1,
                letterSpacing: 1,
                html: ''
            },
        lower: {
            lineHeight: 1,
            letterSpacing: 1,
            html: ''
        }})

    useEffect(()=>{
        if(context.about){
            setInfo(context.about.info)
            setTitel(context.about.titel)
        }
    }, [context])

    return(
        <React.Fragment>
            {isMobile ? (
                <AboutMobile titel={titel} info={info}/>
            ):(
                <AboutMobile titel={titel} info={info}/>
            )}
        </React.Fragment>
    )
}

export default About;