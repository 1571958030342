import parse from "html-react-parser";
import React from "react";


const PageWrapper = (props) =>{

    return(
        <div style={{
            minHeight: 'calc(100vh - 70px - 400px)',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 70,
            background: props.edit ? '' : ''
        }}
        >
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: "center",
                width: '100%',
                position: 'relative',
                background: props.edit ? '' : 'rgb(243, 243, 247)',
                color: 'black',
                maxWidth: 1200
            }}
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: "center",
                    width: '80%',
                    marginTop: props.edit ? 150 : 50,
                    position: props.edit ? 'relative' : ''
                }}
                >
                    <div style={{
                        fontSize: 16,
                        fontWeight: 300,
                        letterSpacing: props.letterSpacing + 'px',
                        lineHeight: props.lineHeight,
                        fontFamily: 'Oswald',
                        textTransform: 'uppercase',
                        width: 'inherit'
                    }}>
                        {props.edit ? (
                            <>{props.edit}</>
                        ):(
                            <p>
                                {parse(props.titel)}
                            </p>
                        )}
                    </div>
                    <div style={{
                        height: 5,
                        backgroundColor: 'var(--main-bg-color)',
                        width: '100%',
                        marginTop: 10,
                        marginBottom: 20
                    }}
                    />
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default PageWrapper;