import React, {useContext, useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import PaymentMobile from "./mobile/PaymentMobile";
import PaymentDesktop from './desktop/PaymentDesktop'
import {Context} from "../../common/context";

const Payment = () =>{

    const context = useContext(Context)
    const [info, setInfo] = useState([])
    const [contact, setContact] = useState({phone: '', mail: '', titel: ''})
    const [titel, setTitel] = useState({
        upper: {
            lineHeight: 1,
            letterSpacing: 1,
            html: ''
        },
        lower: {
            lineHeight: 1,
            letterSpacing: 1,
            html: ''
        }})

    useEffect(()=>{
        if(context.payment){
            setInfo(context.payment.info)
            setTitel(context.payment.titel)
            setContact(context.payment.contact)
        }
    }, [context])

    return(
        <React.Fragment>
            {isMobile ? (
                <PaymentMobile titel={titel} contact={contact} info={info}/>
            ):(
                <PaymentMobile titel={titel} contact={contact} info={info}/>
            )}
        </React.Fragment>
    )
}

export default Payment;