import finalLoga from '../../assets/images/logo/final/wbstrans.png'
import blocket from '../../assets/images/logo/blocket.svg'
import {useContext, useEffect, useRef, useState} from "react";
import {db} from "../../firebase";
import {Context} from "../context";

const FooterEdit = () =>{

    const [pageHeight, setPageHeight] = useState(0);
    const [footerPos, setFooterPos] = useState(0)
    const [pageWith, setPageWith] = useState('100%')
    const pageRef = useRef();
    const user = useContext(Context)
    const [contact, setContact] = useState({mail: '', phone: '', address: {post: '', road: ''}})
    const [openHours, setOpenHours] = useState({hours: [], titel: ''})
    const [slogan, setSlogan] = useState('')

    useEffect(()=>{
        if(user.footer) {
            setContact(user.footer.contact)
            setOpenHours(user.footer.openhours)
            setSlogan(user.footer.slogan)
        }
    },[user])

    const preview = ()=>{

    }

    const save = ()=>{
        let contactRoad = document.getElementById('contactRoad')
        let contactPost = document.getElementById('contactPoast')
        let contactMail = document.getElementById('contactMail')
        let contactPhone = document.getElementById('contactPhone')
        let sloganText = document.getElementById('sloganText')
        let openHours = document.getElementById('openHours')
        let openTitel = document.getElementById('openTitel')

        contactRoad = contactRoad.innerHTML;
        contactPost = contactPhone.innerHTML;
        contactPhone = contactPhone.innerHTML;
        contactMail = contactMail.innerHTML;
        sloganText = sloganText.innerHTML;
        openTitel = openTitel.innerHTML;
        openHours = openHours.innerText;

        let openHoursArr = openHours.split('\n\n\n').join(',,,').split('\n\n').join(',,,').split(',,,')

        db.collection('webpage').doc('footer').update({
            contact: {
                address: {
                    road: contactRoad,
                    post: contactPost
                },
                mail: contactMail,
                phone: contactPhone
            },
            openhours: {
                hours: openHoursArr,
                titel: openTitel
            },
            slogan: sloganText
        })
    }

    return(
        <>
            <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', height: 200, alignItems: 'center',
                fontSize: 18, fontWeight: 600
            }}>
                <button style={{height: 50, padding: 10, marginLeft: 30, backgroundColor: 'green', fontSize: 18, fontWeight: 600}}
                        onClick={()=> {
                            save()
                        }}
                >
                    SPARA
                </button>
            </div>
            <div style={{width: '100%', maxWidth: '100%', height: '400px',backgroundColor: '#262631'}}>
                <div style={{width:'100%', maxWidth: ((window.innerHeight/1)/75)*100, marginLeft: '17px'}}>
                    <div style={{position: 'relative', bottom: 0, left: 0, minHeight: 400, width: '100%', backgroundColor: '#262631',
                        display: 'flex', flexDirection: 'row', fontFamily: 'Oswald, sans-serif', fontWeight: 400, fontSize: 18,
                        justifyContent: 'space-around', color: 'white' }}
                         id={'footerId'}
                    >
                        <div style={{marginTop: 50, marginRight: 0}}>
                            <img src={finalLoga}
                                 style={{height: 80, marginLeft: 0}}
                            />
                            <div style={{fontSize: 20, fontWeight: 200, letterSpacing: 2, marginTop: 20,color: 'black',
                                backgroundColor: 'white', padding: 5, marginBottom: 5}}
                                 contentEditable={true}
                                 id={'sloganText'}
                            >
                                {slogan}
                            </div>
                            <div style={{marginTop: 25, display: 'flex', flexDirection: 'row'}}>
                                <a href="https://www.instagram.com/wetterstadensbilab/"
                                   style={{cursor: 'pointer', marginTop: 5}}
                                >
                                    <img style={{cursor: 'pointer'}}
                                         src="https://assets.website-files.com/5a7d94ae2dbf1b0001dc27df/5a7f232638edac000186dc94_font-awesome_4-7-0_instagram_100_0_ffffff_none.png"
                                         width="22" alt=""/>
                                </a>
                                <a href={"https://www.blocket.se/butik/wetterstadens-bil"}
                                   style={{cursor: 'pointer', marginLeft: 15, display: 'flex', flexDirection: 'row', textDecoration: 'none', filter: 'grayscale(50%)'}}
                                >
                                    <img style={{cursor: 'pointer'}}
                                         src={blocket}
                                         width="100" alt=""
                                    />
                                    <div style={{color: 'white', textDecoration: 'none', marginTop: 5}}>
                                        .se
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div style={{display:'flex', width: 300, marginTop: 80, flexDirection: 'column', letterSpacing: 2}}>
                            <div style={{display: 'flex', fontSize: 26, marginBottom: 10, borderBottom: '1px solid hsla(0, 0%, 100%, 0.05)', letterSpacing: 2, paddingBottom: 10, width: 200, textAlign: 'left'}}>
                                Kontakt
                            </div>
                            <div style={{fontWeight: 300, fontSize: 20}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <div>
                                        Address:
                                    </div>
                                    <div style={{marginLeft: 20}}>
                                        <div id={'contactRoad'}
                                             contentEditable={true}
                                             style={{color: 'black', backgroundColor: 'white', padding: 5, marginBottom: 5}}
                                        >
                                            {contact.address.road}
                                        </div>
                                        <div id={'contactPost'}
                                             contentEditable={true}
                                             style={{color: 'black', backgroundColor: 'white', padding: 5, marginBottom: 5}}
                                        >
                                            {contact.address.post}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                                        <div>
                                            Tel:
                                        </div>
                                        <a style={{marginLeft: 60, textDecoration:'none',color: 'black', backgroundColor: 'white',
                                            padding: 5, marginBottom: 5}}
                                           contentEditable={true}
                                           id={'contactPhone'}
                                        >
                                            {contact.phone}
                                        </a>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                                        <div>
                                            mail:
                                        </div>
                                        <a style={{marginLeft: 50, textDecoration: 'none', color: 'black', backgroundColor: 'white',
                                            padding: 5}}
                                           contentEditable={true}
                                           id={'contactMail'}
                                        >
                                            {contact.mail}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop: 80, marginLeft: 0, letterSpacing: 2, fontSize: 20, fontWeight: 300}}>
                            <div style={{display: 'flex', fontSize: 26, marginBottom: 10, borderBottom: '1px solid hsla(0, 0%, 100%, 0.05)',
                                letterSpacing: 2, paddingBottom: 10, width: 200, textAlign: 'left', fontWeight: 400,
                                color: 'black', backgroundColor: 'white', padding: 5}}
                                 contentEditable={true}
                                 id={'openTitel'}
                            >
                                {openHours.titel}
                            </div>
                            <div style={{width: '100%', display: 'flex', flexDirection: 'column',color: 'black', backgroundColor: 'white',
                                padding: 5, marginBottom: 5}}
                                 contentEditable={true}
                                 id={'openHours'}
                            >
                                {openHours.hours.map((hour, index)=>{
                                    if(index < hour.length-1) {
                                        return (<>{hour}<br/><br/></>)
                                    }
                                    return (<>{hour}</>)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FooterEdit;