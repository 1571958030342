import FirstPage from "../../routes/firstpage/FirstPage";
import About from "../../routes/about/About";
import Payment from "../../routes/payment/Payment";
import Transport from "../../routes/transport/Transport";
import Gallery from "../../routes/gallery/Gallery";
import Contact from "../../routes/contact/Contact";
import Cars from "../../routes/cars/Cars";
import React, {useEffect, useRef, useState} from "react";
import {gsap, Power2} from "gsap";
import logo4 from '../../assets/images/logo/Logos_new/logo_transparent_background.png'
import finalLogo from '../../assets/images/logo/final/wbstrans.png'
import bloket from '../../assets/images/logo/blocket.svg'
import {useNavigate} from "react-router-dom";

const HeaderDesktop = () =>{
    const routesList = () =>{
        return [
            {
                path: '/',
                component: <FirstPage/>,
                title: 'Hem'
            },
            {
                path: '/bilar',
                component: <Cars/>,
                title: 'Bilar'
            },
            {
                path: '/galleri',
                component: <Gallery/>,
                title: 'Bilder'
            },
            {
                path: '/transport',
                component: <Transport/>,
                title: 'Transport'
            },
            {
                path: '/betalning',
                component: <Payment/>,
                title: 'Betalning'
            },
            {
                path: '/kontakt',
                component: <Contact/>,
                title: 'Kontakt'
            },
            {
                path: '/om',
                component: <About/>,
                title: 'Om'
            }
        ]
    }
    const [pageWith, setPageWith] = useState(((window.innerHeight/1)/75)*100)
    const backgroundRef = useRef();
    const headerRef = useRef();
    const headLine = useRef([])
    const blocketRef = useRef();
    const history = useNavigate()

    useEffect(()=>{
        //window.addEventListener('scroll', headerAnim)
        /*gsap.fromTo(
            headerRef.current,
            {
                height: 0
            },
            {
                height: 100,
                duration: 1.5,
                ease: Power2.easeInOut
            }
        )*/


        return ()=>{
            //  window.removeEventListener('scroll', headerAnim)
        }
    },[])

   /* const headerAnim = () =>{
        if(window.scrollY > 0 && headerRef.current.clientHeight === 70 ) {
            gsap.to(
                headerRef.current,
                {
                    height: "100px",
                    duration: .5,
                    fontWeight: 400
                }
            );
            gsap.to(
                backgroundRef.current,
                {
                    height: "100px",
                    opacity: "1",
                    background: 'white',
                    duration: .5,
                }
            );
            gsap.to(
                headerRef.current,
                {
                    scale: 1,
                }
            )
        }
        if(window.scrollY === 0 && headerRef.current.clientHeight > 70) {
            if(headerRef.current.clientHeight !== 100) {
                setTimeout(() => {
                    gsap.to(
                        headerRef.current,
                        {
                            height: "70px",
                            scale: 1,
                            width: '100%',
                            marginLeft: 0 + 'px',
                            duration: 0.5,
                            fontWeight: 300
                        }
                    );
                    gsap.to(
                        backgroundRef.current,
                        {
                            height: "100px",
                            background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
                            duration: 0,
                        }
                    );
                    gsap.to(
                        backgroundRef.current,
                        {
                            opacity: 0.9,
                            duration: .5,
                        }
                    );
                }, 300)
            }else{
                gsap.to(
                    headerRef.current,
                    {
                        height: "70px",
                        scale: 1,
                        width: '100%',
                        marginLeft: 0 + 'px',
                        duration: 0.5,
                        fontWeight: 300
                    }
                );
                gsap.to(
                    backgroundRef.current,
                    {
                        height: "100px",
                        background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
                        duration: 0,
                    }
                );
                gsap.to(
                    backgroundRef.current,
                    {
                        opacity: 0.9,
                        duration: .5,
                    }
                );
            }
        }
    }

    */

    return(
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            height: 100,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            backgroundColor: 'white',
            alignItems: 'center',
            zIndex: 99999,
            fontFamily: 'Oswald, sans-serif',
            fontWeight: 400,
            fontSize: 18,
            filter: 'drop-shadow(0px -7px 10px black)'
        }}
             ref={headerRef}
        >

            <div style={{
                display: 'flex',
                justifyContent:'space-between',
                flexDirection: 'row',
                width:'100%',
                //maxWidth: pageWith,
                alignItems: 'center',
                height: '100%',
                maxWidth: 1400,
                //padding: 20,
                //gap: '4%'
            }}
            >

                <img src={finalLogo}
                     alt={'logo'}
                     style={{
                         maxHeight: '60%',
                         //maxWidth: '20%',
                         width: 'auto',
                         zIndex:9999999,
                         cursor: 'pointer',
                         //marginLeft: 40,
                        // position: 'absolute',
                         left: 40,
                     }}
                     onClick={()=>{
                         history('/')
                     }}
                />


                <div style={{
                    display:'flex',
                    flexDirection: 'row',
                    zIndex: 99,
                    textTransform: 'uppercase',
                   // maxWidth: '40%',
                    gap: '.5rem',
                    fontSize: window.innerWidth > 1500 ? 16 : 15,
                    fontWeight: 100,
                    letterSpacing: .3,
                    fontFamily: 'Goodtimes, Oswald, sans-serif',
                    width: window.innerWidth > 1500 ? 800 : 'auto',
                    justifyContent: 'space-between'
                }}
                >
                    {routesList().map((data, index)=>{
                        return(
                            <div key={index}
                                 style={{
                                     padding: 5,
                                     //marginRight: 30,
                                     marginTop: 10,
                                     cursor: 'pointer'
                            }}
                                 ref={e => headLine.current[index] = e}
                                 onClick={()=>{
                                     if(data.path === '/bilar'){
                                         window.location.href="https://www.blocket.se/butik/wetterstadens-bil"
                                     }else{
                                         history(data.path);
                                     }
                                 }}
                                 onMouseOver={()=>{
                                     gsap.to(
                                         headLine.current[index],
                                         {
                                             scale: 1.2,
                                             duration: .2
                                         }
                                     )
                                     gsap.to(
                                         headLine.current[index].children[1],
                                         {
                                             width: '100%'
                                         }
                                     )
                                 }}
                                 onMouseLeave={()=>{
                                     gsap.to(
                                         headLine.current[index],
                                         {
                                             scale: 1,
                                             duration: .2
                                         }
                                     )
                                     gsap.to(
                                         headLine.current[index].children[1],
                                         {
                                             width: '0%'
                                         }
                                     )
                                 }}
                            >
                                <div>{data.title}</div>
                                <div style={{height: 2, backgroundImage: 'linear-gradient(45deg, #4fafcc, #0056ff)', width: 0}}/>
                            </div>
                        )
                    })}
                </div>
                <a href={'https://www.blocket.se/butik/wetterstadens-bil'}
                   style={{
                       //marginRight: 0,
                       textTransform: 'uppercase',
                       fontSize: 20,
                       zIndex: 99,
                       display: 'flex',
                       flexDirection: 'row',
                       //marginTop: 0,
                       position:'relative',// 'absolute',
                       cursor: 'pointer',
                       //right: 40,
                   }}
                   ref={blocketRef}
                   onMouseOver={()=>{
                       gsap.to(
                           blocketRef.current,
                           {
                               scale: 1.1
                           }
                       )
                   }}
                   onMouseLeave={()=>{
                       gsap.to(
                           blocketRef.current,
                           {
                               scale: 1
                           }
                       )
                   }}
                >
                    <img src={bloket} style={{width: 120, right: 0, /*position: 'absolute'*/}}/>

                </a>
            </div>

        </div>
    )
}
export default HeaderDesktop;

/*
<div style={{position: 'absolute', transform: 'rotate(330deg)', right: -20, fontSize: 24}}>
                        {`shop`}
                    </div>
 */