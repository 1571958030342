import React from "react";
import PageWrapper from "../../../common/PageWrapper";
import parse from "html-react-parser";
const TransportMobile = (props) =>{

    return(
        <PageWrapper titel={props.titel.upper.html}
                     letterSpacing={props.titel.upper.letterSpacing}
                     lineHeight={props.titel.upper.lineHeight}
        >
            <div style={{
                fontSize: 18,
                fontWeight: 300,
                marginBottom: 50,
                marginTop: 0,
                lineHeight: props.titel.lower.lineHeight,
                letterSpacing: props.titel.lower.letterSpacing + 'px',
            }}
            >
                {parse(props.titel.lower.html)}
            </div>
        </PageWrapper>
    )
}
export default TransportMobile;

/*
<div style={{width: 50, marginTop: 50}}>
                        <img src={icon}
                             style={{width: '100%', filter: "invert(1)"}}
                        />
                    </div>
 */

/*
<div style={{position: 'fixed', top: scroll < 300 ? 70 : 300 - scroll, height: 300, width: '100%',
                backgroundPosition: '50% 100%', backgroundSize: 'contain', backgroundRepeat: 'no-repeat',
                backgroundColor: '#f3f3f7', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            >
                <div style={{background: 'white', width: '100%', maxWidth: 290, display: 'flex', flexDirection: 'column', minHeight: 170, alignItems: 'center',
                    paddingTop: 25, paddingBottom:0, paddingLeft: 25, paddingRight: 15, borderRadius: 20, backgroundImage: 'linear-gradient(90deg, rgba(255, 255, 255, 0.11) 37%, #fff 68%, #fff)',
                justifyContent: 'center', overflow:'hidden'}}
                >
                <img src={car2}
                     style={{height: 250,}}
                     ref={iconRef}
                />
                </div>
            </div>
 */