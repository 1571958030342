import {useEffect, useState} from "react";

export default function useOnScreen(ref, index) {

    const [isIntersecting, setIntersecting] = useState(false)
    const [observer, setObserver] = useState(new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
    ))

    useEffect(() => {
        if(!isNaN(index)) {
            observer.observe(ref.current[index])
        }else{
            observer.observe(ref.current)
        }
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [])

    useEffect(()=>{
        if(isIntersecting === true){
            observer.disconnect()
            setIntersecting(false)
        }
    },[isIntersecting])

    return isIntersecting
}

/* const [isIntersecting, setIntersecting] = useState(false)
    let options = {
        root: contactRef.current,
        rootMargin: '0px',
        threshold: 1.0
    }
    const [observer, setObserver] = useState(new IntersectionObserver((e)=>{
        console.log('hej',e)
        e.forEach(e=>{
            setIntersecting(e.isIntersecting)
        })
    }))//), options)

    useEffect(()=> {
        observer.observe(contactRef.current)
    },[])

    useEffect(()=> {
        if(isIntersecting) {
            observer.disconnect()

        }
    },[isIntersecting])

 */