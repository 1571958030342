import {useEffect, useState} from "react";

const LetterSpacing = (props) =>{

    const [letterSpacing, setLetterSpacing] = useState(1)

    useEffect(()=>{
        console.log(props)
        props.letterSpacing.setLetterSpacing(letterSpacing)
    }, [letterSpacing])

    useEffect(()=>{
        setLetterSpacing(props.letterSpacing.current)
    },[])

    return(
        <div style={{display: 'flex', flexDirection: 'column', width: '150px', alignItems: 'center', marginTop: 5, gap: 5,
            fontSize: 12
        }}>
            <div>Ord mellanrum: <span>{letterSpacing}</span></div>
            <input style={{background: 'black', height: 7}}
                   type="range"
                   min="-3"
                   max="4"
                   step={'.2'}
                   value={letterSpacing}
                   onInput={(e)=>{
                       setLetterSpacing(e.target.value)
                   }}
                   className="slider2"
            />
        </div>
    )
}
export default LetterSpacing
