import {isMobile} from "react-device-detect";
import ContactMobile from "./mobile/ContactMobile";
import ContactDesktop from "./desktop/ContactDesktop";
import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../common/context";

const Contact = () =>{

    const context = useContext(Context)
    const [info, setInfo] = useState({info: '', titel: ''})
    const [contact, setContact] = useState({address: {road: '', post: ''}, mail: '', phone: ''})

    useEffect(()=>{
        if(context.contact){
            setInfo(context.contact.info)
            setContact(context.contact.contact)
        }
    }, [context])

    return(
        <React.Fragment>
            {isMobile ? (
                <ContactMobile contact={contact} info={info}/>
            ):(
                <ContactDesktop contact={contact} info={info}/>
            )}
        </React.Fragment>
    )
}

export default Contact;