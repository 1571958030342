import React, {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import HeaderMobile from "./header/HeaderMobile";
import HeaderDesktop from "./header/HeaderDesktop";

const Header = (props) =>{

    const [pathname, setPathname] = useState('')

    useEffect(()=>{
        setPathname(window.location.pathname.toLowerCase())
    },[])

    if(pathname === '/login'){
        return null
    }

    return(
        <React.Fragment>
            {isMobile ? (
                <HeaderMobile/>
            ):(
                <HeaderDesktop/>
            )}
        </React.Fragment>
    )
}

export default Header;