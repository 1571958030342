import icon from '../../../assets/images/icons/menu/bank.png'
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import PageWrapper from "../../../common/PageWrapper";
import parse from "html-react-parser";

const PaymentMobile = (props) =>{

    return(
        <PageWrapper titel={props.titel.upper.html}
                     letterSpacing={props.titel.upper.letterSpacing}
                     lineHeight={props.titel.upper.lineHeight}
        >
            <div style={{
                fontSize: 18,
                fontWeight: 300,
                marginBottom: 50,
                marginTop: 0,
                lineHeight: props.titel.lower.lineHeight,
                letterSpacing: props.titel.lower.letterSpacing + 'px',
            }}
            >
                {parse(props.titel.lower.html)}
            </div>

        </PageWrapper>
    )
}

export default PaymentMobile;


/*
<div style={{fontFamily: 'Roboto Slab, serif', fontSize: 18, lineHeight: 1.3, fontWeight: 300, //color: 'rgb(25,25,25)',
                        whiteSpace: 'pre-wrap', display: 'flex', flexDirection: 'column', alignItems: 'start', textAlign: 'left',
                        marginBottom: 50
                    }}>
                        {props.info.map((text, index)=>{
                            return(
                                <div key={index} style={{marginTop: index === 0 ? 0 : 20}}>
                                    {text}
                                </div>
                            )
                        })}
                        <div style={{marginTop: 20}}>
                            {props.contact.titel}
                        </div>
                        <a href={'mailto:' + props.contact.email}
                           style={{textDecoration:'inherit', marginTop: 0}}
                        >
                            {props.contact.email}
                        </a>
                        <a href={'tel:+46' + props.contact.phone.replaceAll(/\s/g,'').replaceAll(/-/g,'').replace(/0/,'')}
                           style={{textDecoration:'inherit'}}
                        >
                            {props.contact.phone}
                        </a>
                    </div>
 */


/*
<div style={{width: 50, marginTop: 50}}>
                        <img src={icon}
                             style={{width: '100%', filter: "invert(1)"}}
                        />
                    </div>
 */

/*
<div style={{height: "auto", width: '100%',

    backgroundColor: '#f3f3f7', display: 'flex', alignItems: 'center', justifyContent: 'center'
}}>
<div style={{background: 'white', width: finance ? '100%':'80%', maxWidth: 290, display: 'flex', flexDirection: 'column',
    minHeight: 170, alignItems: 'start', paddingTop: 20, paddingBottom: 20, paddingLeft: 25, paddingRight: 15, borderRadius: 20,
    backgroundImage: 'linear-gradient(90deg, rgba(255, 255, 255, 0.11) 37%, #fff 68%, #fff)',marginTop: 20, marginBottom: 20}}
>
<div style={{color: 'rgb(0,147,201)' , fontWeight: 500, fontSize: 20, lineHeight: '30px'}}>
Finansiering
</div>
<div style={{color: '#919197', fontSize: 15, lineHeight: '26px', marginTop: 15, width: finance ? '100%' : '80%'}}>
    {finance ? (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div>Vill du veta vad bilen kostar i månaden?<br/> Använd nedan räknesnurra för att få fram ert månadsbelopp.</div>
            <div>För att göra en kreditansökan, kontakta:<br/></div>
            <a href={'mailto:info@wetterstadensbil.se'}
               style={{textDecoration: 'inherit', color: 'inherit'}}
            >
                info@wetterstadensbil.se
            </a>
        </div>
    ):(
        'Behöver du hjälp med finansiering, vi hjälper dig gärna.'
    )}
</div>
<div style={{color: 'rgb(0,147,201)', borderBottom: '1px solid #c7c7c7', padding: '0px 0px 3px', fontSize: 16, fontWeight: 500, marginTop: 20}}
     onClick={()=>{
         navigate(finance ? -1 : {pathname:'/betalning', search: finance ? '' : '?menu=finance'})

     }}
>
    {finance ? (
        'Tillbaka'
    ):(
        'Mer info'
    )}
</div>
</div>
</div>
 */

/*
 {finance ? (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: "center", width: '100%',//marginTop: 300,
                    fontFamily: 'Oswald', fontSize: 24, fontWeight: 300, position: 'relative',  background: 'blue', backgroundImage: 'linear-gradient(45deg, #4fafcc, #2b6ff5)',
                    color: 'hsla(0, 0%, 100%, 0.6)',minHeight: window.innerHeight - 70 - 300
                }}
                >
                    <iframe frameBorder="0"
                            src="https://kalkylator.wasakredit.se/webbkalkylator/kalkylator?amount=10-200000&amp;time=12-72&amp;interest=3.95&amp;amountmin=10000&amp;amountmax=200000&amp;timemin=12&amp;timemax=72&amp;interestmin=3.95&amp;interestmax=4.95&amp;downpayment=20-60&amp;downpaymentmin=20&amp;downpaymentmax=60&amp;type=fritid"
                            style={{width: '100%', border: '0px', height: '725px' }}
                    />
                </div>
            ) : (
 */