import {useEffect, useState} from "react";


const LineHeight = (props) =>{

    const [lineHeight, setLineHeight] = useState(1)

    useEffect(()=>{
        props.lineHeight.setLineHeight(lineHeight)
    },[lineHeight])

    useEffect(()=>{
        setLineHeight(props.lineHeight.current)
    },[])

    return(
        <div style={{display: 'flex', flexDirection: 'column', width: '150px', alignItems: 'center', marginTop: 5, gap: 5,
            fontSize: 12
        }}>
            <div>Linje Höjd: <span>{lineHeight}</span></div>
            <input style={{background: 'black', height: 7}}
                   type="range"
                   min="0"
                   max="3"
                   step={'.1'}
                   value={lineHeight}
                   onInput={(e)=>{
                       setLineHeight(e.target.value)
                   }}
                   className="slider2"
                //id="myRange"
            />
        </div>
    )
}

export default LineHeight