import React, {useContext, useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import FooterMobile from "./footer/FooterMobile";
import FooterDesktop from "./footer/FooterDesktop";
import {Context} from "./context";

const Footer = () =>{

   const [pathname, setPathname] = useState('')
    const context = useContext(Context)
    const [open, setOpen] = useState({hours:[], titel: ''})
    const [contact, setContact] = useState({phone: '', mail: '', address:{road: '', post: ''}})
    const [slogan, setSlogan] = useState('')

    useEffect(()=>{
        if(context.footer){
            setContact(context.footer.contact)
            setOpen(context.footer.openhours)
            setSlogan(context.footer.slogan)
        }
    },[context])

    useEffect(()=>{
        setPathname(window.location.pathname.toLowerCase())
    },[])

    if(pathname === '/login'){
        return null
    }

    return(
        <React.Fragment>
            {isMobile && window.innerWidth < 1100 ? (
                <FooterMobile contact={contact} open={open} slogan={slogan}/>
            ):(
                <FooterDesktop contact={contact} open={open} slogan={slogan}/>
            )}
        </React.Fragment>
    )
}

export default Footer;