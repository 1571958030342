import finalLoga from '../../assets/images/logo/final/wbstrans.png'
import blocket from '../../assets/images/logo/blocket.svg'
import {useEffect, useRef, useState} from "react";

const FooterDesktop = (props) =>{

    const [pageHeight, setPageHeight] = useState(0);
    const [footerPos, setFooterPos] = useState(0)
    const [pageWith, setPageWith] = useState('100%')
    const pageRef = useRef();

    pageRef.current = document.getElementById('root').scrollHeight

    useEffect(()=>{
        heightPage();
        window.addEventListener('scroll', scrollEvent);
        setTimeout(()=>{
            setPageWith(document.getElementById('pageWith').offsetWidth)
        },100)
        return ()=> window.removeEventListener('scroll', scrollEvent)
    },[])

    const heightPage = () =>{
        setPageHeight(pageRef.current)
    }

    const scrollEvent = () =>{
        let hh = document.getElementById('root').scrollHeight
        if(pageHeight === 0){
            heightPage()
        }
        if(window.scrollY + window.innerHeight > hh - 800){
            setFooterPos(window.scrollY + window.innerHeight - hh + 400)
        }
    }

    return(
        <div style={{
            width: '100%',
            maxWidth: '100%',
            height: '400px',
            backgroundColor: '#262631',
            display: 'flex',
            justifyContent: 'center'
        }}
        >
            <div style={{
                width:'100%',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
                padding: 20
            }}
            >
                <div style={{
                    position: 'relative',
                    bottom: 0,
                    left: 0,
                    minHeight: 400,
                    width: '100%',
                    backgroundColor: '#262631',
                    display: 'flex',
                    flexDirection: 'row',
                    fontFamily: 'Oswald, sans-serif',
                    fontWeight: 400,
                    fontSize: 18,
                    justifyContent: 'space-around',
                    color: 'white',
                    maxWidth: 1400,
                    //gap: '2rem'
                }}
                     id={'footerId'}
                >
                    <div style={{marginTop: 69, marginRight: 0, flex: 1, display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: '80%'}}>
                            <img src={finalLoga}
                                 style={{maxHeight: 80, marginLeft: 0, maxWidth: '100%'}}
                            />
                            <div style={{fontSize: 20, fontWeight: 200, letterSpacing: 2, marginTop: 20}}>
                                {props.slogan}
                            </div>
                            <div style={{marginTop: 25, display: 'flex', flexDirection: 'row'}}>
                                <a href="https://www.facebook.com/wetterstadensbilab/" className="social-icon w-inline-block">
                                    <img src="https://assets.website-files.com/5a7d94ae2dbf1b0001dc27df/5a7f230dee248c0001384b27_font-awesome_4-7-0_facebook_100_0_ffffff_none.png"
                                         width="22"
                                         alt=""
                                         style={{cursor:'pointer', marginTop: 5}}
                                    />
                                </a>
                                <a href="https://www.instagram.com/wetterstadensbilab/"
                                   style={{cursor: 'pointer', marginTop: 5, marginLeft: 15}}
                                >
                                    <img style={{cursor: 'pointer'}}
                                         src="https://assets.website-files.com/5a7d94ae2dbf1b0001dc27df/5a7f232638edac000186dc94_font-awesome_4-7-0_instagram_100_0_ffffff_none.png"
                                         width="22"
                                         alt=""
                                    />
                                </a>
                                <a href={"https://www.blocket.se/butik/wetterstadens-bil"}
                                   style={{cursor: 'pointer', marginLeft: 20, display: 'flex', flexDirection: 'row', textDecoration: 'none', filter: 'grayscale(50%)'}}
                                >
                                    <img style={{cursor: 'pointer'}}
                                         src={blocket}
                                         width="100" alt=""
                                    />
                                    <div style={{color: 'white', textDecoration: 'none', marginTop: 10}}>
                                        .se
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display:'flex',
                        marginTop: 80,
                        flexDirection: 'column',
                        letterSpacing: 2,
                        flex: 1,
                        alignItems: 'center'
                    }}
                    >
                        <div>
                        <div style={{display: 'flex', fontSize: 26, marginBottom: 10, borderBottom: '1px solid hsla(0, 0%, 100%, 0.05)', letterSpacing: 2, paddingBottom: 10, width: 200, textAlign: 'left'}}>
                            Kontakt
                        </div>
                        <div style={{fontWeight: 300, fontSize: 20}}>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <div>
                                    Address:
                                </div>
                                <div style={{marginLeft: 20}}>
                                    <div>
                                        {props.contact.address.road}
                                    </div>
                                    <div>
                                        {props.contact.address.post}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                                    <div>
                                        Tel:
                                    </div>
                                    <a style={{marginLeft: 60, color: 'white', textDecoration:'none'}}
                                       href={'tel:+46' + props.contact.phone.replaceAll(/\s/g,'').replaceAll(/-/g,'').replace(/0/,'')}
                                    >
                                        {props.contact.phone}
                                    </a>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                                    <div>
                                        mail:
                                    </div>
                                    <a style={{marginLeft: 50, textDecoration: 'none', color: 'white'}}
                                       href={'mailto:' + props.contact.mail}
                                    >
                                        {props.contact.mail}
                                    </a>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div style={{
                        marginTop: 80,
                        marginLeft: 0,
                        letterSpacing: 2,
                        fontSize: 20,
                        fontWeight: 300,
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                    >
                        <div>
                        <div style={{display: 'flex', fontSize: 26, marginBottom: 10, borderBottom: '1px solid hsla(0, 0%, 100%, 0.05)', letterSpacing: 2, paddingBottom: 10, width: 200, textAlign: 'left', fontWeight: 400}}>
                            {props.open.titel}
                        </div>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                            {props.open.hours.map((hour, index)=>{
                                return(
                                    <div key={index}>
                                        {hour}
                                    </div>
                                )
                            })}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterDesktop;