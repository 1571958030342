import React, {useContext, useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import FirstPageDesktop from "./desktop/FirstPageDesktop";
import FirstPageMobile from './mobile/FirstPageMobile'
import Testdesktop from "./mobile/Testdesktop";
import {Context} from "../../common/context";

const FirstPage = (props) =>{
    const context = useContext(Context)
    const [width, setWidth] = useState(0)
    const [titel, setTitel] = useState({
        upper: {
            lineHeight: 1,
            letterSpacing: 1,
            html: ''
        },
        lower: {
            lineHeight: 1,
            letterSpacing: 1,
            html: ''
        }})

    useEffect(()=>{
        window.addEventListener('orientationchange', size)
        window.addEventListener('resize', size)
        size()
        return ()=>{
            window.removeEventListener('orientationchange', size)
            window.removeEventListener('resize', size)
        }
    },[])

    const size = () =>{
        console.log('size', window.innerWidth, isMobile)
        setWidth(window.innerWidth)
    }

    useEffect(()=>{
        if(context.firstpage){
            setTitel(context.firstpage.titel)
        }
    }, [context])

    return(
        <React.Fragment>
            {isMobile || width < 1200 ? (
                <Testdesktop titel={titel} width={width}/>
            ):(
                <FirstPageDesktop titel={titel} width={width}/>
            )}
        </React.Fragment>
    )
}

export default FirstPage;
